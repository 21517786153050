import React, { useState, useEffect } from "react";
import CourseCard from "../Card-Component/CourseCard";
import { useDispatch, useSelector } from "react-redux";
import { setAllCoursesCache } from "../../configs/cache";
import { PAGE_LIMIT, ITEM_COURSE } from "../../constants/text_constants";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import Pagination from "../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";

const AllCoursesList = () => {
  const [allCoursesData, setAllCoursesData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const cache = useSelector((store) => store.cache.allCoursesCache);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAllCourses = async (retries = 6) => {
    try {
      let response;
      const userAuthenticated = localStorage.getItem("authenticated") === "1";
      if (userAuthenticated) {
        const authToken = localStorage.getItem("token");
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_COURSES_DATA + currentPage,
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_COURSES_DATA + currentPage
        );
      }

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchAllCourses(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setAllCoursesData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      dispatch(setAllCoursesCache([json?.data, currentPage]));
    } catch (err) {
      console.error("Error fetchAllCourses", err);
    }
  };

  useEffect(() => {
    if (cache[currentPage]) {
      setAllCoursesData(cache[currentPage]);
      setTotalPages(Math.ceil(cache[currentPage]?.total / PAGE_LIMIT));
    } else {
      fetchAllCourses();
    }
  }, [currentPage]);

  if (!allCoursesData || allCoursesData?.courses.length === 0) {
    return <LoadingLottie />;
  }

  return (
    <div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem]">
        {allCoursesData?.courses.map((ele, i) => (
          <CourseCard
            key={i}
            name={ele?.name}
            offer_price={ele?.offer_price}
            offer_discount={ele?.offer_discount}
            price={ele?.price}
            course_banner={ele?.course_banner}
            title_1={"Duration"}
            value_1={ele?.duration}
            title_2={"Start Date"}
            value_2={ele?.starts_on}
            title_3={"End Date"}
            value_3={ele?.ends_on}
            title_4={"Validity"}
            value_4={ele?.validity}
            rating={ele?.rating}
            faculty_name={ele?.faculty_name}
            thumbnail={ele?.faculty_thumbnail}
            description={ele?.description}
            totalRating={ele?.total_rating}
            id={ele?.id}
            exploreMoreLink={UNPROTECTED_ROUTES?.courseDetails}
            showPrice={true}
            showBuyNow={false}
            showExploreMore={true}
            showView={false}
          />
        ))}
      </div>
      <div className="mt-[5rem]">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default AllCoursesList;
