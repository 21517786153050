import React from "react";
import { COURSES_PAGE_COURSES_LIST_TAB_HEADINGS } from "../../constants/text_constants";
import { useSelector } from "react-redux";

const CoursesListHeader = ({ setShowAllCourses, showAllCourses }) => {
  const allCoursesCache = useSelector((store) => store.cache.allCoursesCache);
  const myCoursesCache = useSelector((store) => store.cache.myCoursesCache);
  const authenticated = localStorage.getItem("authenticated") === "1";

  return (
    <div
      className="flex justify-between border-b border-gray-200 pb-2 text-[#3F3D4B]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex lg:gap-5 gap-3 cursor-pointer lg:text-lg text-xs">
        <div
          className={
            showAllCourses
              ? "text-[#03B3C3] underline lg:underline-offset-[0.9rem] underline-offset-[0.75rem] font-semibold"
              : ""
          }
          onClick={() => setShowAllCourses(true)}
        >
          {COURSES_PAGE_COURSES_LIST_TAB_HEADINGS[0]}
        </div>
        <div
          className={
            !showAllCourses
              ? "text-[#03B3C3] underline lg:underline-offset-[0.9rem] underline-offset-[0.75rem] font-semibold"
              : ""
          }
          onClick={() => setShowAllCourses(false)}
        >
          {COURSES_PAGE_COURSES_LIST_TAB_HEADINGS[1]}
        </div>
      </div>
      {authenticated ? (
        showAllCourses ? (
          <div className="lg:text-lg text-xs">
            Total Courses : {allCoursesCache[0]?.total}
          </div>
        ) : (
          <div className="lg:text-lg text-xs">
            Purchased Courses : {myCoursesCache[0]?.total}
          </div>
        )
      ) : !showAllCourses ? (
        <div className="lg:text-lg text-xs">Purchased Courses : 0</div>
      ) : (
        <div className="lg:text-lg text-xs">
          Total Courses : {allCoursesCache[0]?.total}
        </div>
      )}
    </div>
  );
};

export default CoursesListHeader;
