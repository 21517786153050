import React from "react";

const FooterBottomCol1 = ({ data }) => {
  return (
    <div className = "flex flex-col items-center">
      <div className="flex gap-5 items-center justify-center bg-white p-3 rounded-full md:w-[15rem] w-[12rem]">
        <div>
          <img alt={"rankplus-logo"} src={data?.logo} loading="lazy" 
          className = "lg:w-[11rem] lg:h-[4rem] md:w-[8rem] md:h-[52px] h-[3rem] w-[8rem]"
          />
        </div>
      </div>
      <div className="text-[#9591AA] text-center text-sm mt-3 lg:w-[18rem] md:w-[35rem] w-[18rem]">
        {data?.desc}
      </div>
    </div>
  );
};

export default FooterBottomCol1;
