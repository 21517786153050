import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import PdfComponet from "./PdfComponent";
import { ScrollArea } from "../../components/ui/scroll-area";
import { saveAs } from "file-saver";

const PDFDialogButton = ({
  BtnStyle,
  BtnText,
  BtnTextStyle,
  pdfUrl,
  DownloadFileName,
  showIcon,
  DownloadIcon,
  DownloadIconStyle,
  ShowBtnText = true,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const centerX = window.innerWidth / 2 + window.scrollX / 2;
    const centerY = window.innerHeight / 2 + window.scrollY / 2;
    setPosition({ x: centerX, y: centerY });
  }, []);

  function handleDownload() {
    saveAs(pdfUrl, `${DownloadFileName}.pdf`);
  }

  const handleMouseDown = (e) => {
    setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    setPosition({
      x: e.clientX - dragStart.x,
      y: e.clientY - dragStart.y,
    });
  };

  const handleMouseUp = () => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          <Button variant="outline" className={BtnStyle}>
            {showIcon && (
              <div>
                <img
                  src={DownloadIcon}
                  alt="Download Icon"
                  className={DownloadIconStyle}
                  loading = "lazy"
                />
              </div>
            )}
            {ShowBtnText && <div className={BtnTextStyle}>{BtnText}</div>}
          </Button>
        </DialogTrigger>
        <DialogContent
          className="sm:max-w-[36rem]"
          onMouseDown={handleMouseDown}
          style={{
            position: "absolute",
            left: `${position.x}px`,
            top: `${position.y}px`,
            cursor: "grab",
            zIndex: 1000,
          }}
        >
          <DialogHeader>
            <DialogTitle>PDF Download</DialogTitle>
            <DialogDescription>
              {/* Insert Here */}
              <ScrollArea className="lg:w-[33rem] w-[18rem] lg:h-[36rem] h-[22rem] rounded-md">
                <PdfComponet pdf={pdfUrl} />
              </ScrollArea>
              <div className="flex justify-center mt-4">
                <Button
                  className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg"
                  onClick={handleDownload}
                >
                  Download PDF
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PDFDialogButton;
