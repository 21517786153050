import React from "react";
import UserProfileBanner from "../../assets/userProfilerbanner.jpeg";
import UserDetails from "./UserDetails";
import EnrolledPackages from "./EnrolledPackages";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import ReferralCode from "./ReferralCode";
import UserOffers from "./UserOffers";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);
  
  return (
    <div>
      <div
        style={{
          backgroundImage: `url('${UserProfileBanner}')`,
          backgroundSize: "cover",
          backgroundPosition: "20%",
          fontFamily: "DM Sans",
          fontStyle: "normal",
          height: "300px",
        }}
        className = ""
      ></div>
      <div>
        <UserDetails />
      </div>
      <div className = "lg:px-[20rem] md:px-[10rem] px-[1.9rem] mt-[4rem]">
        <ReferralCode/>
      </div>
      <div className="lg:px-[20rem] md:px-[10rem] px-[1.9rem] mt-[4rem]">
        <EnrolledPackages />
      </div>
      <div className="lg:px-[20rem] md:px-[10rem] px-[1.9rem] mt-[4rem] -mb-[3rem]">
        <UserOffers/>
      </div>
    </div>
  );
};

export default UserProfile;
