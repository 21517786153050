import React, { useEffect, useState } from "react";
import { USER_DETAILS_PAGE_ENROLLED_PACKAGES_HEADING } from "../../constants/text_constants";
import useEnrolledPackages from "../../utils/use-EnrolledPackages";
import EnrolledPackagesCard from "../Card-Component/EnrolledPackagesCard";
import Pagination from "../Pagination/Pagination";
import Lottie from "lottie-react";
import AnimationData from "../../assets/PeopleLottie.json";

const EnrolledPackages = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const enrolledData = useEnrolledPackages(currentPage, setTotalPages);
  
  return (
    <div>
      <div className="text-2xl font-semibold">
        {USER_DETAILS_PAGE_ENROLLED_PACKAGES_HEADING}
      </div>
      {enrolledData?.orders?.length > 0 && (
        <div className="mt-5 grid grid-cols-1 gap-3">
          {enrolledData?.orders?.map((ele, i) => (
            <EnrolledPackagesCard key={i} {...ele} />
          ))}
        </div>
      )}
      {enrolledData?.orders?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          position={"justify-end"}
        />
      )}
      {(!enrolledData || enrolledData?.orders?.length === 0) && (
        <div className="flex justify-center items-center mt-5">
          <div className="w-[20rem]">
            <Lottie animationData={AnimationData} />
            <div className="text-center text-[#5A277C] font-semibold text-xl mt-2">
              You have not enrolled in any courses
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnrolledPackages;
