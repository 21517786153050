import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";

const useCourseSubjects = (courseId) => {
  const [couseSubjects, setCourseSubjects] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchCourseSubjects = async (retries = 6) => {
    try {
      let response;
      const authenticated = localStorage.getItem("authenticated") === "1";
      if (authenticated) {
        const authToken = localStorage.getItem("token");
        const requestOptions = {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_SUBJECTS +
            `course_id=${courseId}&limit=20&page=0`,
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_SUBJECTS +
            `course_id=${courseId}&limit=20&page=0`
        );
      }

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchCourseSubjects(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setCourseSubjects(json?.data);
    } catch (err) {
      console.log("Error useCourseSubjects ", err);
    }
  };

  useEffect(() => {
    fetchCourseSubjects();
  }, [courseId]);

  return couseSubjects;
};

export default useCourseSubjects;
