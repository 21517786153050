import React, { useState, useEffect, useRef } from "react";
import { NAVBAR_PLACEHOLDER } from "../../constants/text_constants";
import { Search } from "lucide-react";
import { stripText } from "../../utils/util-functions/stripText";
import { Separator } from "../../components/ui/separator";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const searchResultRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchSearchResult = async (retries = 6) => {
    try {
      const requestOptions = {
        method: "POST",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_NAVBAR_SEARCH_QUERY + `=${searchQuery}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchSearchResult(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setSearchResult(json?.data?.results);
    } catch (err) {
      console.error("Error fetchSearchResult : ", err);
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchSearchResult();
    }, 300);

    return () => clearTimeout(timerId);
  }, [searchQuery]);

  const handleClick = (ele) => {
    if (ele?.type === "course") {
      navigate("course_details/" + ele?.id);
    }
    if (ele?.type === "test_package") {
      navigate("test_details/" + ele?.id);
    }
    if (ele?.type === "book") {
      navigate("book_details/" + ele?.id);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchResultRef.current &&
        !searchResultRef.current.contains(event.target)
      ) {
        setShowResult(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative">
      <div className="flex border border-gray-200 rounded-3xl items-center justify-between lg:ml-10 ml-3 md:p-1 p-0.5">
        <input
          type="text"
          className="lg:w-[30rem] md:w-[15rem] w-[15rem] mx-2 lg:px-2 lg:py-1 px-1 py-0.5 rounded-lg focus:outline-none border border-white md:text-[1rem] text-sm"
          placeholder={NAVBAR_PLACEHOLDER}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setShowResult(true)}
        />

        <div className="bg-[#5A277C] text-white w-9 h-9 rounded-full flex justify-center items-center mr-0.5">
          <Search />
        </div>
      </div>

      {showResult && searchResult?.length > 0 && (
        <div
          ref={searchResultRef}
          className="absolute top-[3.5rem] lg:left-[3rem] left-[1rem] bg-white lg:w-[32rem] w-[17rem] px-1 rounded-lg shadow-xl z-50 h-[20rem] overflow-y-scroll"
        >
          <ul>
            {searchResult.map((ele, i) => (
              <li
                key={i}
                className="my-3 p-2 hover:bg-gray-100 rounded-lg cursor-pointer"
                onClick={() => handleClick(ele)}
              >
                <div className="flex gap-2 mb-3">
                  <div className="mt-1">
                    <Search className="text-gray-500 w-5 h-5" />
                  </div>
                  <div className = "lg:text-[1rem] text-sm">
                    <div className="font-semibold">{ele?.name}</div>
                    <div className="lg:text-sm text-xs">
                      {stripText(ele?.description, 100)}
                      <button className="text-cyan-400 ml-2">see more</button>
                    </div>
                  </div>
                </div>
                {i !== searchResult.length - 1 && <Separator />}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
