import React from "react";
import CALENDAR_ICON from "../../../assets/Calendar.png";
import CALENDAR_BLANK_ICON from "../../../assets/CalendarBlank.png";
import CLOCK_ICON from "../../../assets/Clock.png";
import CHECKLIST_ICON from "../../../assets/ListChecks.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ITEM_TEST_PACKAGE } from "../../../constants/text_constants";
import handleBuy from "../../Buy/handleBuy";

const DescriptionTop = ({
  banner_url,
  starts_on,
  ends_on,
  validity,
  test_count,
  offer_discount,
  offer_price,
  price,
  id,
  name,
  principal_amount,
  course_icon,
  is_purchased,
  dpp_test_count,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div>
      {/* Banner Image */}
      <img src={banner_url} alt="course" className="w-full rounded-2xl" />
      {/* KPI Cards */}
      <div className="flex justify-center my-5">
        <div className="grid grid-cols-2 gap-y-[0.7rem] gap-x-[2rem]">
          {/* start date */}
          <div className="flex gap-2 items-start border border-[#5A277C] rounded-2xl px-2 py-1">
            {/* icon */}
            <div>
              <img
                src={CALENDAR_BLANK_ICON}
                alt={starts_on}
                className="mt-1"
                loading="lazy"
              />
            </div>
            {/* text and value */}
            <div>
              <div className="text-[#82808D]">Start Date:</div>
              <div>{starts_on}</div>
            </div>
          </div>
          {/* end date */}
          <div className="flex gap-2 items-start border border-[#5A277C] rounded-2xl px-2 py-1">
            {/* icon */}
            <div>
              <img
                src={CALENDAR_BLANK_ICON}
                alt={ends_on}
                className="mt-1"
                loading="lazy"
              />
            </div>
            {/* text and value */}
            <div>
              <div className="text-[#82808D]">End Date:</div>
              <div>{ends_on}</div>
            </div>
          </div>
          {/* validity */}
          <div className="flex gap-2 items-start border border-[#5A277C] rounded-2xl px-2 py-1">
            {/* icon */}
            <div>
              <img
                src={CALENDAR_ICON}
                alt={validity}
                className="mt-1"
                loading="lazy"
              />
            </div>
            {/* text and value */}
            <div>
              <div className="text-[#82808D]">Validity:</div>
              <div>{validity}</div>
            </div>
          </div>
          {/* Live Tests */}
          <div className="flex gap-2 items-start border border-[#5A277C] rounded-2xl px-2 py-1">
            {/* icon */}
            <div>
              <img
                src={CLOCK_ICON}
                alt={test_count}
                className="mt-1"
                loading="lazy"
              />
            </div>
            {/* text and value */}
            <div>
              <div className="text-[#82808D]">Live Tests:</div>
              <div>{test_count}</div>
            </div>
          </div>
          {/* DPP */}
          <div className="flex gap-2 items-start border border-[#5A277C] rounded-2xl px-2 py-1">
            {/* icon */}
            <div>
              <img
                src={CHECKLIST_ICON}
                alt={dpp_test_count}
                className="mt-1"
                loading="lazy"
              />
            </div>
            {/* text and value */}
            <div>
              <div className="text-[#82808D]">DPP:</div>
              <div>{dpp_test_count}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Price and Buy Button */}
      <div className="flex justify-between items-center my-[1rem] mx-[1rem]">
        {/* Price */}
        <div className="flex items-center gap-1">
          {/* Discount percentage */}
          <div>
            {offer_discount !== "0" && offer_discount !== null ? (
              <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                {offer_discount}% off
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-col">
            {/* original price */}
            {offer_discount !== "0" && offer_discount !== null && (
              <div className="line-through text-gray-500">₹{price}</div>
            )}
            {/* offer_price */}
            <div className="text-[#5A277C] font-bold">
              ₹
              {offer_discount !== "0" && offer_discount !== null
                ? offer_price
                : price}
            </div>
          </div>
        </div>

        {/* buttons */}
        <div className="flex gap-3">
          {/* Buy Now Button */}
          {is_purchased === "0" && (
            <div>
              <button
                className="text-[#5A277C] px-2 py-1 rounded-xl cursor-pointer"
                style={{
                  backgroundColor: "rgba(90, 39, 124, 0.10)",
                  fontWeight: 700,
                }}
                onClick={() =>
                  handleBuy(
                    dispatch,
                    navigate,
                    id,
                    ITEM_TEST_PACKAGE,
                    name,
                    offer_price,
                    offer_discount,
                    price,
                    principal_amount,
                    banner_url,
                    course_icon
                  )
                }
              >
                Buy Now
              </button>
            </div>
          )}
          {/* Purchased Text */}
          {is_purchased === "1" && (
            <div>
              <div className="bg-gradient-to-r from-cyan-400 to-purple-600 text-white w-[10rem] py-1 rounded-xl font-semibold text-center mt-[1rem]">
                {" "}
                Course Purchased
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DescriptionTop;
