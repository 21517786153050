import React from "react";
import { Link } from "react-router-dom";

const FooterBottomCol2 = ({ data }) => {
  return (
    <div>
      <div className="font-bold text-[#F9F9F9] flex lg:justify-center justify-start lg:mt-0 mt-8">{data?.title}</div>
      <div className = "lg:flex lg:flex-col grid grid-cols-2 gap-3 mt-4 items-center">
        {data?.links.map((ele, i) => (
          <div key={i} className = "text-[#9591AA]">
            <Link to={ele?.link}>{ele?.title}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FooterBottomCol2;
