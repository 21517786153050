import React from "react";
import PreperationSectionTest from "./PreperationSectionTest";
import PreperationSectionBooks from "./PreperationSectionBooks";

// bg-[#F8FCFD]
const PreperationSection = ({tests, books}) => {

  if(!tests || !books) return;
  if(tests.length === 0 || books.length === 0) return;
    
  return (
    <div className="flex md:flex-row flex-col gap-1">
      <div className="bg-[#F8FCFD] lg:w-1/2 md:w-[25rem] w-[20rem]">
        <PreperationSectionTest tests = {tests}/>
      </div>
      <div className="bg-[#F8FCFD] lg:w-1/2 md:w-[25rem] w-[20rem]">
        <PreperationSectionBooks books = {books}/>
      </div>
    </div>
  );
};

export default PreperationSection;
