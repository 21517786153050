import React, { useState } from "react";
import StarIcon from "../../assets/Star.png";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";

const Rating = ({ data, id, subjectid }) => {
  const my_rating = data?.my_rating === "" ? 0 : +data?.my_rating;
  const [rating, setRating] = useState(my_rating);
  const [hover, setHover] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitRating = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_SUBMIT_RATING +
          `${id}&rating=${rating}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => submitRating(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      if (json?.message === "success") {
        toast("Thank you for your rating");
        setRating(0);
        setHover(0);
        window.location.reload();
      }
      localStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error submitRating", err);
    }
  };

  function handleClick() {
    submitRating();
  }

  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          <Button
            variant="outline"
            className="flex gap-1 bg-[#F8FCFD] border border-[#DFE3EC] items-center py-1 px-2 rounded-3xl shadow-sm cursor-pointer hover:-translate-y-1 hover:shadow-2xl transition-all"
          >
            <div>
              <img src={StarIcon} alt="star" className="w-8 h-8" loading = "lazy"/>
            </div>
            <div className="font-semibold text-[#595763]">Rating</div>
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[22.5rem] max-w-[20rem]">
          <DialogHeader>
            <DialogTitle>Rate Lecture</DialogTitle>
            <DialogDescription>
              {/* Insert Here */}
              <div className="my-6">
                <img
                  src={data?.thumbnail}
                  alt={data?.id}
                  className="rounded-2xl md:w-[19rem] w-[17rem]"
                  loading = "lazy"
                />
              </div>
              <div className="md:text-5xl text-3xl flex gap-4 justify-center text-gray-200">
                {data?.my_rating === ""
                  ? [1, 2, 3, 4, 5].map((num, i) => (
                      <div
                        key={num}
                        className={
                          num <= ((rating && hover) || hover)
                            ? "text-yellow-400 cursor-pointer"
                            : "cursor-pointer"
                        }
                        onClick={() => setRating(num)}
                        onMouseOver={() => setHover(num)}
                        onMouseLeave={() => setHover(rating)}
                      >
                        &#9733;
                      </div>
                    ))
                  : [1, 2, 3, 4, 5].map((num, i) => (
                      <div
                        key={num}
                        className={
                          num <= my_rating
                            ? "text-yellow-400 cursor-pointer"
                            : "cursor-pointer"
                        }
                      >
                        &#9733;
                      </div>
                    ))}
              </div>
              {data?.my_rating === "" && (
                <div className="flex justify-center mt-6">
                  <Button
                    className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg"
                    onClick={handleClick}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Rating;
