import React, { useState, useEffect } from "react";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_PROMO } from "../../constants/text_constants";
import PromoCarousel from "./PromoCarousel";
import AnimationData from "../../assets/youtube-subscribe.json"
import CustomLottie from "../Lottie-Components/CustomLottie";
import { Link } from "react-router-dom";

const PromoSection = () => {
  const [videos, setVideos] = useState([]);

  async function fetchYTPromo() {
    try {
      const response = await fetch(
        process.env.REACT_APP_YOUTUBE_API +
          `?key=${process.env.REACT_APP_YOUTUBE_API_KEY}&channelId=${
            process.env.REACT_APP_YOUTUBE_CHANNEL_ID
          }&part=snippet,id&order=date&maxResults=${5}`
      );
      const data = await response.json();
      setVideos(data.items);
    } catch (err) {
      console.warn("Error in PromoSection", err);
    }
  }

  useEffect(() => {
    // fetchYTPromo();
  }, []);

  console.log(videos);

  return (
    <div className="flex flex-col items-center my-[3rem]">
      <div className="">
        <SectionHeading
          heading={LANDING_PAGE_PROMO?.heading}
          headingColor={"#130C40"}
          subHeading={LANDING_PAGE_PROMO?.subHeading}
        />
      </div>
      <div className = "-mt-[6rem] -mb-[2rem]">
        <Link to = {"https://youtube.com/@rankplus.official?si=P8l-e6gNrOEyElrm"} target = "_blank">
        <CustomLottie animationData={AnimationData} width = {"w-[20rem]"}/>
        </Link>
      </div>
      <div className="flex justify-center">
        <PromoCarousel data = {LANDING_PAGE_PROMO?.promoVideos}/>
      </div>
    </div>
  );
};

export default PromoSection;
