import React from "react";
import { formatDate } from "../../utils/util-functions/formatDate";
import CountdownTimer from "../Countdown/Countdown";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import {ITEM_COURSE, ITEM_TEST_PACKAGE, ITEM_BOOK} from "../../constants/text_constants";

const EnrolledPackagesCard = ({
  thumbnail,
  type,
  name,
  duration,
  validity,
  purchase_at,
  end_date,
  invoice_path,
  course_id,
  test_package_id,
  book_id,
}) => {
  const navigate = useNavigate();

  function handleDownload() {
    saveAs(invoice_path, `${name}-invoice.pdf`);
  }

  function handleClick(){
    console.log("Clicked", type);
    if(type === ITEM_COURSE){
      navigate(UNPROTECTED_ROUTES?.courseDetails + `/${course_id}`);
    }
    if(type === ITEM_TEST_PACKAGE){
      navigate(UNPROTECTED_ROUTES?.testDetails + `/${test_package_id}`);
    }
    else if(type === ITEM_BOOK){
      navigate(UNPROTECTED_ROUTES?.bookDetails + `/${book_id}`);
    }
  }

  return (
    <div className="bg-[#F8FCFD] border border-[#DFE3EC] py-2 px-4 rounded-2xl lg:w-[50rem] md:w-[30rem] w-[21rem] cursor-pointer"
    onClick = {() => handleClick()}
    >
      <div className="flex lg:gap-10 gap-4 items-center">
        <div>
          <img
            src={thumbnail}
            alt={type + " thumbnail"}
            className="lg:w-[10rem] lg:h-[10rem] w-[7rem] rounded-lg"
            loading="lazy"
          />
        </div>
        <div>
          <div className="font-semibold lg:text-xl text-[1rem]">{name}</div>
          <div className="flex flex-wrap my-3 lg:gap-10 md:gap-5 gap-2 lg:text-[1rem] text-xs">
            {duration && (
              <div>
                <div className="text-[#82808D]">Hours</div>
                <div>{duration}</div>
              </div>
            )}
            <div>
              <div className="text-[#82808D]">Validity</div>
              <div>{validity}</div>
            </div>
            <div>
              <div className="text-[#82808D]">Purchased Date</div>
              <div>{formatDate(purchase_at)}</div>
            </div>
            <div>
              <div className="text-[#82808D]">Expires Timer</div>
              <div>
                <CountdownTimer targetDate={end_date} />
              </div>
            </div>
          </div>
          <div className="flex gap-3 lg:text-[1rem] md:text-sm text-xs">
            <button className="text-white bg-[#1ECBDB] rounded-2xl lg:px-3 lg:py-1 px-2 py-0.5 font-semibold">
              Paid
            </button>
            <button
              className="flex gap-2 items-center text-white rounded-2xl lg:px-3 lg:py-1 px-2 py-0.5 bg-[#5A277C]"
              onClick={handleDownload}
            >
              <img src={DOWNLOAD_ICON} alt="download-icon" loading="lazy" />
              <div className="font-semibold">Download Invoice</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrolledPackagesCard;
