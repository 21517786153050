import React from "react";
import { Link } from "react-router-dom";
import CoursesDropdown from "./CoursesDropdown";
import FreeLibraryDropdown from "./FreeLibraryDropdown";
import LoginRegisterButton from "./LoginRegisterButton";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { useSelector } from "react-redux";
import UserDropdown from "./UserDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import SearchBar from "./SearchBar";

const NavbarRight = () => {
  const user = useSelector((store) => store.user);
  const userName = JSON.parse(sessionStorage.getItem("userDetail"))?.userName;

  return (
    <div className="flex lg:flex-row md:flex-col flex-col items-center gap-4 lg:text-[1rem] text-sm">
      <div className="lg:hidden md:hidden block">
        <SearchBar />
      </div>
      <div className="hover:text-[#1ECBDB] lg:hidden md:block block">
        <Link to={UNPROTECTED_ROUTES?.home}>Home</Link>
      </div>
      {/* <CoursesDropdown /> */}
      <div className="hover:text-[#1ECBDB] lg:hidden md:block block">
        <Link to={UNPROTECTED_ROUTES?.coursesAll}>Courses</Link>
      </div>
      <div className="hover:text-[#1ECBDB] lg:hidden md:block block">
        <Link to={UNPROTECTED_ROUTES?.testSeriesAll}>Test Series</Link>
      </div>
      <div className="hover:text-[#1ECBDB] lg:hidden md:block block">
        <Link to={UNPROTECTED_ROUTES?.booksAll}>Books</Link>
      </div>
      <div className = "lg:hidden md:block block">
        <FreeLibraryDropdown />
      </div>
      <div className="flex items-center gap-5">
        <div>
          {user?.authenticated ||
          sessionStorage.getItem("authenticated") === "1" ? (
            <NotificationsDropdown />
          ) : (
            ""
          )}
        </div>
        <div>
          {user?.authenticated ||
          sessionStorage.getItem("authenticated") === "1" ? (
            <UserDropdown userName={userName} />
          ) : (
            <LoginRegisterButton />
          )}
        </div>
      </div>
      
    </div>
  );
};

export default NavbarRight;
