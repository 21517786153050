import React from "react";
import DOWNLOAD_ICON from "../../../assets/DownLoadIcon.png";
import { Link } from "react-router-dom";

const DescriptionSchedule = ({ schedule }) => {
    
  return (
    <div className="mx-[0.5rem] pb-[0.7rem]">
      <h1 className="text-lg font-semibold">Schedule</h1>
      <div>
        <div className="flex gap-2">
          {schedule?.map((ele, i) => (
            <div className="flex gap-1 items-center" key={i}>
              <div>{ele?.title}</div>
              <div>
                <Link to={ele?.attachment} target="_blank">
                  <img
                    src={DOWNLOAD_ICON}
                    alt={ele?.title}
                    className="bg-[#5A277C] p-1 rounded-full mx-1"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DescriptionSchedule;
