import React, { useState, useEffect } from "react";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import PageBanner from "../PageBanner/PageBanner";
import useMyOffers from "../../utils/use-myOffers";
import OfferCard from "../Card-Component/OfferCard";
import Lottie from "lottie-react";
import AnimationData from "../../assets/LookingLottie.json";
import Pagination from "../Pagination/Pagination";

const MyOffersPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const myOffers = useMyOffers(currentPage, setTotalPages, 10);
  return (
    <div>
      <PageBanner
        title={"My Offers"}
        subtitle={
          "Grab our exclusive offers and enjoy amazing discounts on top products! Limited-time deals available now. Don’t miss out on incredible savings – shop today and take advantage of these special offers before they’re gone!"
        }
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className="lg:mx-[20rem] mx-[3rem] -mb-[2rem] mt-10">
        <div className="grid grid-cols-1 gap-5">
          {myOffers?.offers?.length > 0 &&
            myOffers?.offers?.map((ele, i) => <OfferCard {...ele} key = {i}/>)}
          {(!myOffers || myOffers?.total === 0) && (
            <div className="flex justify-center items-center">
              <div className="w-[25rem]">
                <Lottie animationData={AnimationData} />
                <div className="text-center text-[#5A277C] font-semibold text-xl">
                  No offers as of now
                </div>
              </div>
            </div>
          )}
        </div>
        {myOffers?.offers?.length > 0 && (
          <div className="mt-[5rem]">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
        )}
      </div>
    </div>
  );
};

export default MyOffersPage;
