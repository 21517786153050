import React from "react";
import DescriptionTop from "./DescriptionTop";
import DescriptionSyllabus from "../test/DescriptionSyllabus";
import BookDescriptionTab from "../../BookDetails/BookDescription";
import useBookHeaders from "../../../utils/use-BookHeaders";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


const BookDescription = ({ headerDetails, bookId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const bookHeaders = useBookHeaders(bookId, dispatch, navigate);

  return (
    <div className="mx-[1rem] -mb-[5rem] -mt-[1.5rem]">
      <div className="bg-white border border-gray-200 shadow-xl p-3 rounded-xl">
        <DescriptionTop {...headerDetails?.details?.[0]} bookBanners = {bookHeaders?.bookBanners}/>
        <DescriptionSyllabus {...headerDetails?.details?.[0]} />
      </div>
      <div className="mx-[1rem] mt-[2rem]">
        <BookDescriptionTab
          bookId={bookId}
          description={bookHeaders?.bookHeadersData?.details[0].description}
        />
      </div>
    </div>
  );
};

export default BookDescription;
