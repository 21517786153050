import React, { useState } from "react";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import PageBanner from "../PageBanner/PageBanner";
import useNotifications from "../../utils/use-Notifications";
import NotificationCard from "../Card-Component/NotificationCard";
import IntegratedPagination from "../Pagination/IntegratedPagination";
import { useSelector } from "react-redux";

const NotificationsPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const notificationsData = useNotifications(currentPage, setTotalPages);
  const isSideBarOpen = useSelector((store) => store.sidebar.isSideBarOpen);

  return (
    <div>
      <PageBanner
        title={"Notifications"}
        subtitle={
          "Stay updated with the latest news and alerts! Stay in the loop and get notified instantly about what matters most to you!"
        }
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className={(isSideBarOpen ? "lg:mx-[15rem] " : "lg:mx-[20rem] ") + "md:mx-[4rem] mx-[2rem] -mb-[3rem] mt-10"}>
        <div className="grid grid-cols-1 gap-6">
          {notificationsData?.notifications?.length > 0 &&
            notificationsData?.notifications?.map((ele, i) => (
              <NotificationCard {...ele} key={i} />
            ))}
        </div>
        <div className = "mt-[3rem]">
          <IntegratedPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationsPage;
