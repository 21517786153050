import React from "react";
import TestSeriesTab from "../../CourseDetail/TestSeriesTab";

const TestLiveTestTab = ({ testId, isPurchased }) => {
  return (
    <div className="mx-[1rem]">
      <TestSeriesTab
        testPackageId={testId}
        isPurchased={isPurchased}
        showMetaDescription={false}
      />
    </div>
  );
};

export default TestLiveTestTab;
