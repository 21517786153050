import React from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import StudentDetailsForm from "../StudentDetails/StudentDetailsForm";

const EditUserDetails = () => {

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="bg-[#5A277C] text-white">
          Edit Profile
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[45rem] pr-20">
        <DialogHeader>
          <DialogTitle>Edit profile</DialogTitle>
          <DialogDescription>
            Make changes to your profile here. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <StudentDetailsForm showTitle = {false} btnText = {"Save"}/>
      </DialogContent>
    </Dialog>
  );
};

export default EditUserDetails;
