import React from "react";

const FormHeader = ({title, subtitle}) => {
  return (
    <div className="text-center lg:mt-0 -mt-[2.2rem]">
      <div
        className="text-[#130C40] lg:text-[45px] text-[1.5rem] mr-3"
        style={{
          fontWeight: "800"
        }}
      >
        {title}
      </div>
      <div className="text-[#3F3D4B] lg:mt-0 mt-0.5 md:text-[1rem] text-sm" style={{ fontWeight: "400" }}>
        {subtitle}
      </div>
    </div>
  );
};

export default FormHeader;
