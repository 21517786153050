import React from "react";

const FinalAmount = ({
  price,
  promoAmount,
  cartData,
  includeWallet,
  includePromo,
}) => {

  return (
    <div className="bg-[#5A277C] text-white font-semibold flex justify-between items-center px-4 py-1 text-2xl rounded-lg">
      <div>Final Price</div>
      <div>
        <span className="mr-1">₹</span>
        {(price +
          (includeWallet ? -parseFloat(cartData?.wallet_balance) : 0) +
          (includePromo ? -parseFloat(promoAmount) : 0)).toFixed(2)}
      </div>
    </div>
  );
};

export default FinalAmount;
