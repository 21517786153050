import React from "react";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const FooterLegend = () => {
  return (
    <div className="text-[#9591AA] flex md:flex-row flex-col justify-center items-center border-t border-gray-800 mt-4 pt-5">
      <div>
        {" "}
        &#169; 2024 <span className="font-bold text-[#04E9FD]">
          RANK PLUS
        </span>{" "}
        All Right Reserved
      </div>
      <div className="font-bold mx-2 hidden md:block"> | </div>
      <div className="font-bold mx-2 md:hidden"> - </div>
      <div>
        <Link to={UNPROTECTED_ROUTES?.terms}>Terms & Conditions</Link>
      </div>
      <div className="font-bold mx-2 hidden md:block"> | </div>
      <div className="font-bold mx-2 md:hidden"> - </div>
      <div>
        <Link to={UNPROTECTED_ROUTES?.privacy}>Privacy Policy</Link>
      </div>
      <div className="font-bold mx-2 hidden md:block"> | </div>
      <div className="font-bold mx-2 md:hidden"> - </div>
      <div>
        <Link to={UNPROTECTED_ROUTES?.return}>Return & Refund</Link>
      </div>
    </div>
  );
};

export default FooterLegend;
