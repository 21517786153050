export function convertQuality(quality) {
    // Convert string to integer for specific resolutions
    switch (quality) {
      case "1080":
        return 1080;
      case "720":
        return 720;
      case "360":
        return 360;
      case "144":
        return 144;
      case "Auto":
        return 144; // Default for "Auto"
      default:
        return null; // Return null or handle invalid input as desired
    }
}

export function extractYouTubeVideoID(url) {
  const regex = /youtu\.be\/([^\?&]+)/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return null; // Handle the case where the video ID is not found
  }
}