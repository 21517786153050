import React, { useState } from "react";
import TestsListHeader from "./TestsListHeader";
import AllTestsList from "./AllTestsList";
import MyTestsList from "./MyTestsList";

const TestsList = () => {

  const [showAllTests, setShowAllTests] = useState(true);
  return (
    <div>
      <TestsListHeader
        setShowAllTests={setShowAllTests}
        showAllTests={showAllTests}
      />
      <div className="mt-[2rem]">
        {showAllTests && <AllTestsList />}
        {!showAllTests && <MyTestsList />}
      </div>
    </div>
  );
};

export default TestsList;
