import React from "react";
import { LANDING_PAGE_HERO_CARDS } from "../../constants/text_constants";
import { useSelector } from "react-redux";

const Herocards = () => {
  const isSideBarOpen = useSelector((store) => store.sidebar.isSideBarOpen);

  return (
    <div className={"mt-[1rem] mb-[4rem] " + (isSideBarOpen ? "" : "lg:flex lg:flex-wrap lg:justify-around lg:gap-0 ") + "grid grid-cols-2 gap-5"}>
      {LANDING_PAGE_HERO_CARDS.map((d, i) => (
        <div
          className="text-center flex flex-col items-center bg-[#F8FCFD] lg:p-10 p-5 rounded-lg hover:bg-gradient-to-r from-cyan-100 to-purple-100 cursor-pointer"
          key={i}
        >
          <img
            alt={"hero-card" + d?.id}
            src={d?.img}
            className="lg:w-13 bg-white p-2 rounded-full"
            loading="lazy"
          />
          <div
            style={{
              fontFamily: "DM Sans",
              textAlign: "center",
              fontStyle: "normal",
              lineHeight: "normal",
            }}
          >
            <div className="text-[#130C40] lg:text-lg text-[0.8rem]">
              {d?.title}
            </div>
            <div className="text-[#3F3D4B] lg:text-[0.9rem] text-xs">
              {d?.subtitle}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Herocards;
