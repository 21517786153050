import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import PageBanner from "../PageBanner/PageBanner";
import CartItem from "./CartItem";
import SuggestedBooks from "./SuggestedBooks";
import PricingSection from "./PricingSection";
import { ShoppingCart } from "lucide-react";
import EmptyCartLottie from "../Lottie-Components/EmptyCartLottie";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import useAutoLogout from "../../utils/useAutoLogout";

const BuyPage = () => {
  const cart = useSelector((store) => store.cart.cart);
  const [apiResponse, setApiResponse] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [suggestedBooks, setSuggestedBooks] = useState(null);
  const [gst, setGst] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);

  const fetchDetails = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_CART + `${cart?.item}/${cart?.id}`,
        requestOptions
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchDetails(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setSuggestedBooks(json?.data?.suggested_books);
      setGst(parseInt(json?.data?.gst) / 100);
      setApiResponse(json?.data);
      setCartItems([
        {
          id: cart?.id,
          name: cart?.name,
          price: cart?.price,
          thumbnail: cart?.course_banner,
          // sellingPrice:
          //   cart?.sellingPrice === 0
          //     ? Math.ceil(cart.price / (1 + parseInt(json?.data?.gst) / 100))
          //     : cart.sellingPrice,
          sellingPrice: Math.ceil(cart.price / (1 + parseInt(json?.data?.gst) / 100)),
          show: false,
        },
      ]);
      localStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchDetails", err);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  function removeFromCart(id) {
    const excludedItem = cartItems.filter((ele) => ele?.id === id);
    if (cart?.id !== excludedItem[0]?.id) {
      setSuggestedBooks((prevState) => [...prevState, ...excludedItem]);
    }
    const remainingItems = cartItems.filter((ele) => ele?.id !== id);
    setCartItems(remainingItems);
  }

  return (
    <div className="-mb-[3rem]" style={{ fontFamily: "DM Sans" }}>
      <PageBanner
        title={"Cart Checkout"}
        subtitle={""}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      {cartItems?.length === 0 ? (
        <div className="lg:mx-[15rem] mx-[1rem] mt-6">
          <EmptyCartLottie />
        </div>
      ) : (
        <div
          className={
            "lg:mx-[15rem] mx-[1rem] mt-6 flex md:flex-row flex-col-reverse justify-center " +
            (suggestedBooks?.length > 0
              ? "justify-between md:gap-[5rem] gap-[1rem]"
              : "justify-center")
          }
        >
          {/* left Section */}
          <div className="w-3/4">
            <div className="text-2xl font-semibold flex items-center gap-3 mb-[2rem]">
              <div>
                <ShoppingCart />
              </div>
              <div>My Cart</div>
            </div>
            <div>
              {cartItems?.map((ele, i) => (
                <CartItem
                  {...ele}
                  key={i}
                  removeFromCart={removeFromCart}
                  showTrash={ele?.show}
                />
              ))}
            </div>
            <div>
              <PricingSection
                cartData={apiResponse}
                cartItems={cartItems}
                gst={gst}
                suggestedBooks={suggestedBooks}
              />
            </div>
          </div>
          {/* Right Section */}

          {suggestedBooks?.length > 0 && (
            <div className="w-1/4">
              <SuggestedBooks
                suggestedBooks={suggestedBooks}
                setCartItems={setCartItems}
                setSuggestedBooks={setSuggestedBooks}
                gst={gst}
                cart={cartItems}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BuyPage;
