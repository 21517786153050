import React from "react";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import StarRating from "../Star-Component/StarRating";

const BookCard = ({
  thumbnail,
  name,
  price,
  offer_price,
  offer_discount,
  rating,
  id,
}) => {
  return (
    <Link to={UNPROTECTED_ROUTES?.bookDetails + `/${id}`}>
      <div className="p-2 border border-gray-200 rounded-lg flex flex-col lg:gap-2 gap-3 h-[17rem] bg-white shadow-lg lg:text-[1rem] text-sm">
        {/* Course Image */}
        <div>
          <img
            src={thumbnail}
            alt={name}
            className="w-[20rem] h-[8rem] rounded-lg"
            loading="lazy"
          />
        </div>
        {/* Course Rating */}
        <div>
          <StarRating rating={Math.floor(rating)} />
        </div>
        <div className="font-bold">{name}</div>
        {/* Price and Button */}
        <div className="flex justify-between items-center gap-0">
          <div className="flex items-center gap-1">
            {/* Discount percentage */}
            <div>
              {offer_discount !== "0" && offer_discount !== null ? (
                <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                  {offer_discount}% off
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="flex flex-col">
              {/* original price */}
              {offer_discount !== "0" && offer_discount !== null && (
                <div className="line-through text-gray-500">₹{price}</div>
              )}
              {/* offer_price */}
              <div className="text-[#5A277C] font-bold">
                ₹
                {offer_discount !== "0" && offer_discount !== null
                  ? offer_price
                  : price}
              </div>
            </div>
          </div>
          <div>
            <Link to={UNPROTECTED_ROUTES?.bookDetails + `/${id}`}>
              <button
                className="text-[#03B3C3] rounded-xl cursor-pointer px-1 py-1"
                style={{
                  backgroundColor: "rgba(30, 203, 219, 0.15)",
                  fontWeight: 700,
                }}
              >
                Explore
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BookCard;
