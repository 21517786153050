import React, { useState, useEffect } from "react";
import PageBanner from "../PageBanner/PageBanner";
import { BLOGS_PAGE_BANNER, PAGE_LIMIT } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import BlogsCard from "./BlogsCard";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import IntegratedPagination from "../Pagination/IntegratedPagination";
import { useSelector } from "react-redux";

const BlogsPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [blogsData, setBlogsData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);
  const isSideBarOpen = useSelector((store) => store.sidebar.isSideBarOpen);

  const fetchBlogsData = async (retries = 6) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_BLOGS_DATA + `10/${currentPage}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchBlogsData(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setBlogsData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("Error fetchBlogsData", err);
    }
  };

  useEffect(() => {
    fetchBlogsData();
  }, [currentPage]);

  return (
    <div>
      <PageBanner
        title={BLOGS_PAGE_BANNER?.title1}
        subtitle={BLOGS_PAGE_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className={(isSideBarOpen ? "lg:mx-[12rem]" : "lg:mx-[6rem]") + " mx-[5rem] my-[3rem] grid " + (isSideBarOpen ? "lg:grid-cols-2" : "lg:grid-cols-3") + " md:grid-cols-2 grid-cols-1 lg:gap-[2rem] md:gap-[1rem] gap-[1rem]"}>
        {blogsData?.blogs.map((ele, i) => (
          <BlogsCard key={i} {...ele} />
        ))}
      </div>
      <IntegratedPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  );
};

export default BlogsPage;
