import React from "react";
import { MoveRight } from "lucide-react";
import PageBanner from "../PageBanner/PageBanner";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";

const LegalPageBody = ({ heading, body }) => {
  return (
    <div>
      <PageBanner
        title={heading}
        rightImgSrc={BANNER_IMG}
        backgroundGradient = {"linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"}
        backgroundImage = {BACKGROUND_IMAGE}
        fontColor = {"#130C40"}
        backgroundPosition = {"top"}
      />
      <div
        className="px-[2.3rem] text-xs lg:px-[10rem] lg:mt-10 lg:-mb-40 lg:text-lg"
        style={{ fontFamily: "DM Sans" }}
      >
        {body.map((ele, i) => (
          <div className="my-8" key = {i}>
            {ele?.title && (
              <div className="lg:text-xl text-[0.9rem] font-bold underline underline-offset-4 mb-2">
                {ele?.title.toUpperCase()}
              </div>
            )}
            {ele?.subtitle && (
              <div className="text-medium">{ele?.subtitle}</div>
            )}
            {ele?.points.length > 0 &&
              ele?.points.map((d, i) => (
                <div className="my-2 lg:ml-2 ml-1" key = {i}>
                  {d?.title && (
                    <div className="font-bold">{d?.title.toUpperCase()}</div>
                  )}
                  {d?.point && (
                    <div>
                      <span>
                        <MoveRight className="inline lg:ml-4 ml-0" /> {d?.point}
                      </span>
                    </div>
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LegalPageBody;
