import React from "react";
import DescriptionTop from "./DescriptionTop";
import DescriptionSchedule from "./DescriptionSchedule";
import CourseDescriptionTab from "../../CourseDetail/CourseDescriptionTab";

const CourseDescription = ({ headerDetails, courseId }) => {
  return (
    <div className="mx-[1rem] -mb-[5rem]">
      <div className="bg-white border border-gray-200 shadow-xl p-3 rounded-xl">
        <DescriptionTop {...headerDetails?.details?.[0]} />
        <DescriptionSchedule schedule={headerDetails?.schedules} />
      </div>
      <div className = "mx-[1rem] mt-[2rem]">
        <CourseDescriptionTab courseId={courseId} />
      </div>
    </div>
  );
};

export default CourseDescription;
