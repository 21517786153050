import React from "react";
import { COURSE_DETAILS_TAB_HEADINGS } from "../../constants/text_constants";

const TabHeadings = ({ index, setIndex }) => {
  return (
    <div
      className="flex justify-between border-b border-gray-200 pb-2 text-[#3F3D4B] mt-8 mx-[5rem] w-[52rem]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex gap-5 cursor-pointer">
        {COURSE_DETAILS_TAB_HEADINGS.map((ele, i) => (
          <div
            key={i}
            className={
              index === i
                ? "text-[#03B3C3] underline underline-offset-[0.9rem] font-semibold"
                : ""
            }
            onClick={() => setIndex(i)}
          >
            {ele}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabHeadings;
