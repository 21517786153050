import React, { useState } from "react";
import NavbarLeft from "./NavbarLeft";
import NavbarRight from "./NavbarRight";
import { Menu, X } from "lucide-react";
import SearchBar from "./SearchBar";

const NavbarComponent = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="px-[2rem] md:px-8">
      <div className="flex items-center justify-between h-[5rem]">
        <div>
          <NavbarLeft />
        </div>
        <div className="lg:block md:hidden hidden">
          <NavbarRight />
        </div>
        <div className = "lg:hidden md:block hidden">
          <SearchBar/>
        </div>
        <div className="lg:hidden md:block block">
          {show ? (
            <X onClick={() => setShow(false)} />
          ) : (
            <Menu onClick={() => setShow(true)} />
          )}
        </div>
      </div>

      {show && (
        <div className = "lg:hidden md:block block lg:mb-0 md:mb-[2rem] mb-[2rem]">
          <NavbarRight />
        </div>
      )}
    </div>
  );
};

export default NavbarComponent;
