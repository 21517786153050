import React from "react";
import { Link } from "react-router-dom";

const FooterBottomCol4 = ({ data }) => {
  return (
    <div>
      <div className="font-bold text-[#F9F9F9] flex lg:justify-center justify-start lg:mt-0 mt-8">
        {data?.title}
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-3 place-items-center mt-3">
        {data?.links.map((ele, i) => (
          <div key={i}>
            <Link to={ele?.link} target = "_blank">
              {ele?.id === "footer-twitter" || ele?.id === "footer-linkedin" ? (
                <div className="bg-[#261F4F] rounded-full p-4 my-3">
                  <img
                    src={ele?.src}
                    alt={ele?.id}
                    className="w-[1.5rem] h-[1.5rem]"
                    loading = "lazy"
                  />
                </div>
              ) : (
                <div className="bg-[#261F4F] rounded-full p-1 my-3">
                  <img
                    src={ele?.src}
                    alt={ele?.id}
                    className="w-[3rem] h-[3rem]"
                    loading = "lazy"
                  />
                </div>
              )}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FooterBottomCol4;
