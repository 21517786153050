import React from "react";

const SuggestedBooks = ({
  suggestedBooks,
  setCartItems,
  setSuggestedBooks,
  gst,
  cart,
}) => {
  function handleAdd(id, name, price, thumbnail) {
    const newCart = [
      ...cart,
      {
        id,
        name,
        price: parseInt(price),
        thumbnail,
        sellingPrice: Math.ceil(price / (1 + gst)),
        show : true
      },
    ];
    setCartItems(newCart);
    const remainingBooks = suggestedBooks.filter((ele) => ele?.id !== id);
    setSuggestedBooks(remainingBooks);
  }

  return (
    <div>
      <div className="text-2xl font-semibold mb-5">Suggested Books</div>
      <div className="my-5 grid grid-cols-3 gap-5">
        {suggestedBooks.map((ele, i) => (
          <div
            key={i}
            className="flex gap-5 p-2 bg-[#F8FCFD] border border-[#DFE3EC] rounded-xl w-[16rem] items-center shadow-xl"
          >
            <div>
              <img
                src={ele?.thumbnail}
                alt={ele?.name}
                className="max-w-[5rem] rounded-lg"
                loading = "lazy"
              />
            </div>
            <div className="mr-5 flex flex-col gap-2">
              <div className="font-bold">{ele?.name}</div>
              <div>₹ {ele?.price}</div>
              <div>
                <button
                  className="text-[#03B3C3] px-2 py-1 rounded-xl cursor-pointer"
                  style={{
                    backgroundColor: "rgba(30, 203, 219, 0.15)",
                    fontWeight: 700,
                  }}
                  onClick={() =>
                    handleAdd(ele?.id, ele?.name, ele?.price, ele?.thumbnail)
                  }
                >
                  Add +
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuggestedBooks;
