import React from "react";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_STUDENT_FEEDBACK } from "../../constants/text_constants";
import StudentFeedbackCard from "./StudentFeedbackCard";
import { useNavigate } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const StudentFeedback = ({ feedback }) => {
  const navigate = useNavigate();

  if (!feedback || feedback.length === 0) return;
  const displayCards = feedback.slice(0, 3);

  return (
    <div className="-mx-[10rem] bg-[#F8FCFD] my-[3rem] p-10">
      <SectionHeading
        heading={LANDING_PAGE_STUDENT_FEEDBACK?.heading}
        subHeading={LANDING_PAGE_STUDENT_FEEDBACK?.subHeading}
        headingColor={"#130C40"}
      />
      <div className="flex justify-center items-center -mt-[2rem] mx-[8rem]">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[1.3rem]">
          {displayCards.map((ele, i) => (
            <StudentFeedbackCard {...ele} key={i} />
          ))}
        </div>
      </div>
      <div className="flex items-center justify-center mt-20">
        <button
          className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all"
          onClick={() => {
            navigate(UNPROTECTED_ROUTES?.feedback);
          }}
        >
          View All
        </button>
      </div>
    </div>
  );
};

export default StudentFeedback;
