import LP_BOOKMARK from "../assets/Bookmark.png";
import LP_VIDEO from "../assets/Video.png";
import LP_STUDENT from "../assets/Student.png";
import LP_HEADSET from "../assets/Headset.png";
import LP_EXAM from "../assets/Exam.png";
import LP_SCROLL from "../assets/Scroll.png";
import LP_NOTES from "../assets/Note.png";
import LP_LAPTOP from "../assets/Laptop.png";
import LP_NOTEBOOK from "../assets/Notebook.png";
import LP_NOTEPAD from "../assets/Notepad.png";
import LP_DOWNLOAD from "../assets/DownloadSimple.png";
import LP_BRIEFCASE from "../assets/Briefcase.png";
import LP_PLUS from "../assets/PlusCircle.png";
import RANK_PLUS_LOGO from "../assets/RANK_PLUS_LOGO.webp";
import LP_PHONE from "../assets/Phone.png";
import LP_ENVELOP from "../assets/Envelope.png";
import LP_FB from "../assets/Fb.png";
import LP_TWITTER from "../assets/Twitter.png";
import LP_YOUTUBE from "../assets/Youtube_black.png";
import LP_INSTAGRAM from "../assets/Instagram_black.png";
import LP_LINKEDIN from "../assets/LinkedIN.png";
import LP_TELEGRAM from "../assets/Telegram_black.png";
import USER_CIRCLE from "../assets/UserCircle.png";
import WALLET from "../assets/Wallet.png";
import OFFER from "../assets/SealPercent.png";
import { UNPROTECTED_ROUTES, PROTECTED_ROUTES } from "../routes/routes";

// Contents

// SIGN_UP FORM
// LOGIN FORM
// NAVBAR
// LANDING PAGE
// FOOTER
// MD5 Salt
// COURSES PAGE
// BLOGS PAGE
// TEST SERIES PAGE
// BOOKS PAGE
// USER DETAILS PAGE
// FREE LIBRARY
// COURSE DETAILS PAGE
// TEST DETAILS PAGE
// 404 Page
// PAYMENT PAGE
// MISC

// SIGN-UP FORM
export const STUDENT_DETAILS_FORM_HEADER = "Student Details";

// LOGIN FORM
export const LOGIN_FORM_TITLE = "Welcome to Rank Plus";
export const LOGIN_FORM_SUB_TITLE =
  "Please Enter your Mobile number to Login/Register";
export const LOGIN_FORM_RESEND_OTP_LIMIT = 3;
export const LOGIN_FORM_RESEND_OTP_TIMER = 60;

// NAVBAR
export const NAVBAR_PLACEHOLDER = "Search Courses, Books, etc";
export const NAVBAR_COURSES_DROPDOWN_DROPDOWN = [
  "Lab Foundation 3.0",
  "Microbiology Batch",
  "Anatomy Batch",
  "Lakshya Batch",
  "Ummid Batch",
  "Lab Foundation 1.0",
];
export const NAVBAR_FREE_LIBRARY_DROPDOWN = [
  { id: "#21", title: "Videos", link: UNPROTECTED_ROUTES?.freeLibraryVideos },
  { id: "#22", title: "Tests", link: UNPROTECTED_ROUTES?.freeLibraryTests },
  { id: "#23", title: "Notes", link: UNPROTECTED_ROUTES?.freeLibraryNotes },
  { id: "#24", title: "Blogs", link: UNPROTECTED_ROUTES?.blogs },
];
export const NAVBAR_USER_AUTHENTICATED_DROPDOWN = [
  {
    id: "#101",
    title: "My Profile",
    imgSrc: USER_CIRCLE,
    link: PROTECTED_ROUTES?.profile,
  },
  // {
  //   id: "#102",
  //   title: "My Courses",
  //   imgSrc: LP_VIDEO,
  //   link: UNPROTECTED_ROUTES?.courses,
  // },
  // {
  //   id: "#103",
  //   title: "My Tests",
  //   imgSrc: LP_NOTES,
  //   link: UNPROTECTED_ROUTES?.testSeries,
  // },
  // {
  //   id: "#104",
  //   title: "My Books",
  //   imgSrc: LP_BOOKMARK,
  //   link: UNPROTECTED_ROUTES?.books,
  // },
  {
    id: "#105",
    title: "My Wallet",
    imgSrc: WALLET,
    link: PROTECTED_ROUTES?.profile,
  },
  {
    id: "#106",
    title: "My Offers",
    imgSrc: OFFER,
    link: PROTECTED_ROUTES?.offers,
  },
];

// LANDING PAGE
export const LANDING_PAGE_BANNER_1 = {
  title1: "Get Flat 100% OFF",
  title2: "on all courses purchase",
  subtitle: "RANK 10",
};
export const LANDING_PAGE_BANNER_2 = {
  title1: "Student’s most Trusted Paramedical Platform",
  title2: null,
  subtitle:
    "Transforming Mind, One Click at a Time – Welcome to RanKplus, Your Ultimate Knowledge Hub.",
};
export const LANDING_PAGE_HERO_CARDS = [
  {
    id: "#1",
    img: LP_VIDEO,
    title: "Daily Live/Recorded Lecture",
    subtitle: "Aggregated Classes",
  },
  {
    id: "#2",
    img: LP_BOOKMARK,
    title: "Unlimited Access to Free Library",
    subtitle: "Videos, DPP, and Notes",
  },
  {
    id: "#3",
    img: LP_STUDENT,
    title: "Top Paramedical Contents",
    subtitle: "Entrusted with Lakh’s of Student’s",
  },
  {
    id: "#4",
    img: LP_HEADSET,
    title: "24x7 Support",
    subtitle: "Doubt Solving Sessions",
  },
];
export const LANDING_PAGE_PREPERATION_SECTION_TESTS = {
  heading: "Enhance your Preparation",
  subHeading: "Nurture your Preparation with Practice and Live Test Series",
};
export const LANDING_PAGE_PREPERATION_SECTION_BOOKS = {
  heading: "Prepare with the Best",
  subHeading: "Hand Written Notes for Better Preparation",
};
export const LANDING_PAGE_REVOLUTION = {
  heading: "Revolutionize Learning With RanKplus",
  desc1:
    "We provide premium education to all by offering a variety of Learning options Like theory video lectures, their pdf notes, practical video lectures, Live MCQ’s classes, MCQ’s pdf, MCQ’s practice set, Online test series. and many more.",
  desc2:
    "We provide enthusiastic educational content for BMLT, DMLT, Bvoc.MLT,  and all formats of Lab competition exams Like AIIMS, SGPGI, PGI  Chandigarh, Group -5, DSSSB, BSF, RSSMB, DHA,UP NHM, MP NHM as well as All another state of India Lab exams.",
  buttonText: "Download Now",
};
export const LANDING_PAGE_METRICS = {
  heading: "Trusted by Students Worldwide",
  subHeading:
    "Don't Just Take Our Word for It. Delve into the Numbers and Witness the Excellence for Yourself!",
  metrics: [
    { id: "#5", stats: "22M+", img: LP_STUDENT, desc: "Happy Students" },
    { id: "#6", stats: "54K+", img: LP_EXAM, desc: "Mock Tests" },
    { id: "#7", stats: "32K+", img: LP_VIDEO, desc: "Video Lectures" },
    { id: "#8", stats: "80K+", img: LP_SCROLL, desc: "Practice Papers" },
  ],
};

export const LANDING_PAGE_PROMO = {
  heading: "Explore more with us on YouTube",
  subHeading:
    "Discover engaging content and updates Related to all the Paramedical and Lab Competitive Exams with expert insights, and exciting updates. Subscribe to our YouTube channel and explore more with us!",
  promoVideos: [
    {
      videoid: "https://www.youtube.com/embed/lUiCJhlY0Tg?si=SB1QIC9yNVAi3YPC",
      title:
        "icmr recruitment 2024 | ICMR vacancy 2024 | ICMR Lab technician vacancy 2024 | Chandan Mlt |RanKplus",
    },
    {
      videoid: "https://www.youtube.com/embed/zIOtHE5_CCw?si=-zxKvMQcdFd8mRtl",
      title:
        "group 5 notification 2024 | group 5 vacancy 2024 mp | group 5 syllabus 2024 | Lab Technician vacancy",
    },
    {
      videoid: "https://www.youtube.com/embed/XvinUit2Axs?si=2p0cjXoQhlkESkdV",
      title:
        "KGMU vacancy 2024 | KGMU Recruitment 2024 l All Paramedical Posts l Age l Qualification l Syllabus",
    },
    {
      videoid: "https://www.youtube.com/embed/PCAATfXhWSE?si=5FwJIHaCu6C72e4D",
      title:
        "LAB TECHNICIAN MCQ'S #30 | RRB paramedical classes | BMLT and DMLT classes | Chandan mlt MCQ's",
    },
    {
      videoid: "https://www.youtube.com/embed/nAq2doI243E?si=2skbPX-5-Ba_I8X7",
      title:
        "Marathon classes #04 | महामैराथन MCQ's #04 For Lab technician | BMLT classes | mlt online class",
    },
  ],
};

export const LANDING_PAGE_FEATURES = {
  heading: "Features of Rankplus",
  subHeading:
    "Welcome to RanKplus, where learning meets innovation. Our app is designed to empower you with the tools and resources needed to excel in your educational and professional pursuits. Experience a tailored learning journey with RanKplus. Our app understands your unique needs and adapts to your learning style, ensuring a personalized and effective educational experience. With RanKplus, your learning journey is not confined to a specific time or place. Access your courses and materials anytime, anywhere, and on any device.",
  features: [
    { id: "#9", desc: "Online Lecture", img: LP_VIDEO },
    { id: "#10", desc: "DPP Test", img: LP_NOTES },
    { id: "#11", desc: "Live Test", img: LP_LAPTOP },
    { id: "#12", desc: "Live Support", img: LP_HEADSET },
    { id: "#13", desc: "Online Notes", img: LP_NOTEBOOK },
    { id: "#14", desc: "Offline Notes", img: LP_NOTEPAD },
    { id: "#15", desc: "Download Lecture", img: LP_DOWNLOAD },
    { id: "#16", desc: "Free Library", img: LP_BOOKMARK },
    { id: "#17", desc: "Vacancy Update", img: LP_BRIEFCASE },
    { id: "#18", desc: "Other Features", img: LP_PLUS },
  ],
};
export const LANDING_PAGE_COURSES_AVAILABLE = {
  heading: "Courses Available",
  subHeading:
    "RanKplus is Nurturing Student’s for 20+ Competitive Exams. Explore your Way of Learning.",
};
export const LANDING_PAGE_STUDENT_TESTIMONIALS = {
  heading: "Student Testimonials",
  subHeading: null,
};
export const LANDING_PAGE_STUDENT_FEEDBACK = {
  heading: "What do Our Selected Student Say’s",
  subHeading: "Our Journey Continues with the Trust of Lakh’s of Students.",
};

// FOOTER
export const TOP_FOOTER = {
  heading: "Download Our App",
  subHeading:
    "Download the lessons and learn anytime, anywhere from the free courses available on our app",
  playStoreLink:
    "https://play.google.com/store/apps/details?id=com.madical.RanKplus",
};
export const BOTTOM_FOOTER = {
  title: {
    logo: RANK_PLUS_LOGO,
    desc: "Empower your paramedical journey with Rankplus - the Learning App. Unlock knowledge, elevate skills, and shape a brighter future in healthcare.",
  },
  navigation: {
    title: "NAVIGATION",
    links: [
      { id: "footer-home", title: "Home", link: UNPROTECTED_ROUTES?.home },
      {
        id: "footer-courses",
        title: "Courses",
        link: UNPROTECTED_ROUTES?.courses,
      },
      {
        id: "footer-test-series",
        title: "Test Series",
        link: UNPROTECTED_ROUTES?.testSeries,
      },
      { id: "footer-books", title: "Books", link: UNPROTECTED_ROUTES?.books },
      {
        id: "footer-freelibrary",
        title: "Free Library",
        link: UNPROTECTED_ROUTES?.freeLibrary,
      },
    ],
  },
  contact: {
    title: "CONTACT",
    links: [
      {
        id: "footer-phone",
        title: "Phone",
        info: "+91-82350-67766",
        src: LP_PHONE,
        href: "tel:+918235067766",
      },
      {
        id: "footer-email",
        title: "Email",
        info: "rankplusofficial@gmail.com",
        src: LP_ENVELOP,
        href: "mailto:rankplusofficial@gmail.com",
      },
      {
        id: "footer-support",
        title: "Support",
        info: "support@rankplusofficial.com",
        src: LP_ENVELOP,
        href: "mailto:support@rankplusofficial.com",
      },
    ],
  },
  socialMedia: {
    title: "SOCIAL MEDIA",
    links: [
      {
        id: "footer-fb",
        src: LP_FB,
        link: "https://www.facebook.com/rankplusofficial",
      },
      { id: "footer-twitter", src: LP_TWITTER, link: "https://x.com/RanKplus" },
      {
        id: "footer-youtube",
        src: LP_YOUTUBE,
        link: "https://www.youtube.com/@rankplus.official",
      },
      {
        id: "footer-instagram",
        src: LP_INSTAGRAM,
        link: "https://www.instagram.com/rankplusofficial/",
      },
      {
        id: "footer-linkedin",
        src: LP_LINKEDIN,
        link: "https://www.linkedin.com/company/rankplus-official/",
      },
      {
        id: "footer-telegram",
        src: LP_TELEGRAM,
        link: "https://t.me/rankplusofficial",
      },
    ],
  },
};

// MD5 Salt
export const MD5_SUFFIX_SALT = "ClickByDigital::RankPlus";

// COURSES PAGE
export const COURSES_PAGE_BANNER = {
  title1: "Courses to get you started",
  subtitle:
    "A Platform where you can Explore the opportunity to learn with Experienced Teachers for your Dream Learning.",
};
export const COURSES_PAGE_COURSES_LIST_TAB_HEADINGS = [
  "All Courses",
  "My Courses",
];
export const PAGE_LIMIT = 10;
export const COURSES_PAGE_SHOW_COURSES_FILTER = false;

// BLOGS PAGE
export const BLOGS_PAGE_BANNER = {
  title1: "Blogs",
  subtitle:
    "Explore in-depth guides, expert insights, and the latest updates in the world of paramedical sciences to enhance your learning and career in healthcare",
};

// TEST SERIES PAGE
export const TEST_SERIES_PAGE_BANNER = {
  title1: "Comprehensive Test Packages for Paramedical Mastery",
  subtitle:
    "Access a wide range of expertly designed test packages to evaluate your knowledge and skills, helping you excel in your paramedical journey and beyond.",
};
export const TESTS_PAGE_TESTS_LIST_TAB_HEADINGS = ["All Tests", "My Tests"];
export const COURSES_PAGE_SHOW_TESTS_FILTER = false;

// BOOKS PAGE
export const BOOKS_PAGE_BANNER = {
  title1: "Essential Books for Paramedical Excellence",
  subtitle:
    "Discover a curated collection of paramedical books, providing the foundational knowledge and advanced insights you need to succeed in the healthcare field.",
};
export const BOOKS_PAGE_BOOKS_LIST_TAB_HEADINGS = ["All Books", "My Books"];
export const BOOKS_PAGE_SHOW_BOOKS_FILTER = false;

// USER DETAILS PAGE
export const USER_DETAILS_PAGE_ENROLLED_PACKAGES_HEADING = "Enrolled Packages";
export const USER_DETAILS_MY_OFFERS_HEADING = "My Offers";

// FREE LIBRARY
export const FREE_LIBRARY_VIDOES_BANNER = {
  title1: "Library Videos",
  subtitle:
    "Explore a vast collection of educational videos designed to enhance your paramedical skills and deepen your understanding of key healthcare concepts.",
};

export const FREE_LIBRARY_TESTS_BANNER = {
  title1: "Library Tests",
  subtitle:
    "Challenge your knowledge with a variety of practice tests designed to help you assess and strengthen your paramedical expertise",
};

export const FREE_LIBRARY_NOTES_BANNER = {
  title1: "Library Notes",
  subtitle:
    "Access comprehensive and expertly crafted notes to support your learning and mastery of essential paramedical topics and techniques.",
};

export const FREE_LIBRARY_TAB_HEADINGS = ["Videos", "Notes", "Practice Sets"];

// COURSE DETAILS PAGE
export const COURSE_DETAILS_TAB_HEADINGS = [
  "Description",
  "Live Class",
  "Classroom",
  "Live Tests",
  "DPP",
  "Announcement",
];
export const COURSE_DETAILS_DESCRIPTION_HEADING = "Description";
export const COURSE_DETAILS_DESCRIPTION_FAQ_HEADING =
  "Frequently Asked Questions";
export const COURSE_DETAILS_LIVE_CLASSES_HEADING = "Live Class";
export const COURSE_DETAILS_CLASSROOM_HEADING = "Classroom";
export const COURSE_DETAILS_DPP_HEADING = "DPP";
export const COURSE_DETAILS_TEST_SERIES_HEADING = "Live Tests";
export const COURSE_DETAILS_ANNOUNCEMENT_HEADING = "Announcement";

// TEST DETAILS PAGE
export const TEST_DETAILS_TAB_HEADINGS = [
  "Description",
  "Live Test",
  "DPP",
  "Solution",
  "Announcement",
];
export const TEST_DETAILS_DESCRIPTION_HEADING = "Description";
export const TEST_DETAILS_DESCRIPTION_FAQ_HEADING =
  "Frequently Asked Questions";

// BOOK DETAILS PAGE
export const BOOK_DETAILS_DESCRIPTION_HEADING = "Description";
export const BOOK_DETAILS_DESCRIPTION_FAQ_HEADING =
  "Frequently Asked Questions";
export const BOOK_DETAILS_ANNOUNCEMENT_HEADING = "Announcement";
export const BOOK_DETAILS_LIVE_TEST_HEADING = "Live Test";
export const BOOK_DETAILS_DPP_HEADING = "DPP";
export const BOOK_DETAILS_SOLUTION_HEADING = "Solution";

// 404 Page
export const PAGE_NOT_FOUND_TEXT = [
  "Oops! Page not found.",
  "It seems the page you’re looking for doesn’t exist.",
];

// PAYMENT PAGE
export const PAYMENT_PAGE_PAYMENT_PENDING = "PAYMENT_PENDING";
export const PAYMENT_PAGE_PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_PAGE_TRANSACTION_NOT_FOUND = "TRANSACTION_NOT_FOUND";
export const PAYMENT_PAGE_PAYMENT_SUCCESS = "PAYMENT_SUCCESS";

// MISC
export const LOCKED_TOAST_TEXT =
  "To access this Lecture. Please purchase the course.";
export const LIVE_CLASS_NOT_STARTED_TOAST_TEXT =
  "This lecture has not been started yet";
export const VIDEO_TYPE_YOUTUBE_LIVE = "YouTube_Live";
export const VIDEO_TYPE_YOUTUBE = "YouTube";
export const USER_LOGIN_RESPONSE = "Login Successful";
export const USER_REGISTERED_RESPONSE = "User Registered Successful";
export const USER_PROFILE_UPDATED = "Profile updated successfully";
export const USER_PROFILE_EMAIL_EXISTS_ERROR = "Your email already exists.";
export const AUTO_LOGOUT_TIMER = 30 * 60 * 1000;
export const ITEM_COURSE = "course";
export const ITEM_TEST_PACKAGE = "test_package";
export const ITEM_BOOK = "book";
// export const AUTO_LOGOUT_TIMER = 3000;
