import { Skeleton } from "../../components/ui/skeleton";

export const ShimmerUtil = ({
    width = '100%',
    height = '100%',
    borderRadius = '0px',
    className = '',
    style = {},
  }) => {
    return (
      <Skeleton
        className={className}
        style={{
          width,
          height,
          borderRadius,
          ...style,
        }}
      />
    );
  };
  