import React, { useState, useEffect } from "react";
import { LOGIN_FORM_RESEND_OTP_TIMER } from "../../constants/text_constants";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";

const ResendOTPButton = ({
  resendOtpBtnClick,
  setResendOtpBtnClick,
  setHashedOTP,
  mobileNo,
}) => {
  const [seconds, setSeconds] = useState(LOGIN_FORM_RESEND_OTP_TIMER);
  const [activeBtn, setActiveBtn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendOTP = async (mobileNo, retries=6) => {
    try {
      const requestOptions = {
        method: "POST",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_SEND_OTP + `=${mobileNo}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => sendOTP(mobileNo, retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setHashedOTP(json?.data?.otp);
    } catch (err) {
      console.log("Error sendOTP", err);
    }
  };

  function handleClick() {
    if (!activeBtn) return;
    if (activeBtn) {
      setResendOtpBtnClick(resendOtpBtnClick + 1);
      setActiveBtn(false);
      setSeconds(LOGIN_FORM_RESEND_OTP_TIMER);
      sendOTP(mobileNo);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(intervalId);
        setActiveBtn(true);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds, resendOtpBtnClick]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div
      style={{
        backgroundImage: activeBtn
          ? "linear-gradient(to right, rgba(30, 203, 219, 1) , rgba(111, 49, 153, 1))"
          : "linear-gradient(to right, rgba(111, 49, 153, 0.5), rgba(30, 203, 219, 0.5))",
        cursor: activeBtn ? "pointer" : "wait",
      }}
      className="py-1 px-2 rounded-lg text-white font-bold shadow-lg lg:w-full w-[7rem] lg:ml-0 ml-[1rem] lg:mt-0 -mt-1"
      onClick={handleClick}
    >
      <div>Resend OTP</div>
      <div className="flex flex-col items-center">
        {minutes}:
        {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
      </div>
    </div>
  );
};

export default ResendOTPButton;
