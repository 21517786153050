import React, { useState, useEffect } from "react";
import GoogleIcon from "../../assets/Google.png";
import { useGoogleLogin } from "@react-oauth/google";
import userLogin from "../../utils/userLogin";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const SignUpGoogleBtn = () => {
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userEmail !== "") {
      const api = `${process.env.REACT_APP_RANK_PLUS_USER_LOGIN}email=${userEmail}`;
      userLogin(api, navigate, dispatch);
    }
  }, [userEmail]);

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.access_token}`,
          },
        };
        const res = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          requestOptions
        );
        const json = await res.json();
        localStorage.setItem("userProfilePic", json?.picture);
        setUserEmail(json?.email);
      } catch (err) {
        console.log(err);
      }
    },
    ux_mode: "redirect",
  });

  return (
    <div className = "lg:block flex justify-center">
      <button
        className="mt-4 lg:w-full flex items-center justify-center bg-white border border-gray-300 py-2 rounded-full hover:-translate-y-1 hover:shadow-2xl transition-all w-[15rem]"
        onClick={() => login()}
      >
        <img src={GoogleIcon} alt="Google" className="w-5 h-5 mr-2" loading = "lazy"/>
        <span>Sign up with Google</span>
      </button>
    </div>
  );
};

export default SignUpGoogleBtn;
