import { useState, useEffect } from "react";
import userLogout from "./userLogout";
import { modExp } from "./util-functions/cryptoUtils";

const useBookHeaders = (bookId, dispatch, navigate) => {
  const [bookHeaders, setBookHeaders] = useState(null);
  const [encryptionData, setEncryptionData] = useState(null);

  const fetchBookHeaders = async (retries = 6) => {
    try {
      let response;
      const authenticated = localStorage.getItem("authenticated") === "1";
      if (authenticated) {
        const authToken = localStorage.getItem("token");
        const requestOptions = {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_BOOK_DETAILS_HEADER.replace(
            /BOOK_ID/,
            bookId
          ) + `?publicKey=${encryptionData.clientPublicKey}`,
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_BOOK_DETAILS_HEADER.replace(
            /BOOK_ID/,
            bookId
          )
        );
      }

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchBookHeaders(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      const finalRes = {
        bookHeadersData: json?.data,
        cryptoData: encryptionData,
        bookBanners: json?.data?.details?.[0]?.banner_url_2.split(",")
      };
      setBookHeaders(finalRes);
      localStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchBookHeaders", err);
    }
  };

  const setPubPvtKey = () => {
    const prime = 23;
    const base = 5;

    // Client's private key and public key
    const clientPrivateKey = Math.floor(Math.random() * 100);
    const clientPublicKey = modExp(base, clientPrivateKey, prime);

    const cryptoData = {
      clientPrivateKey: clientPrivateKey,
      clientPublicKey: clientPublicKey,
      prime: prime,
    };

    setEncryptionData(cryptoData);
  };

  useEffect(() => {
    setPubPvtKey();
  }, [bookId]);

  useEffect(() => {
    fetchBookHeaders();
  }, [encryptionData]);

  return bookHeaders;
};

export default useBookHeaders;
