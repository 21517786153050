import React from "react";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const SignUpField = () => {
  return (
    <div>
      <p className="mt-4 text-center text-gray-600">
        Don’t have an account?{" "}
        <Link to={UNPROTECTED_ROUTES?.signup}>
          <span className="text-[#03B3C3]">Sign Up</span>
        </Link>
      </p>
    </div>
  );
};

export default SignUpField;
