import React, { useState, useEffect } from "react";
import useTestDescription from "../../utils/use-TestDescription";
import {
  TEST_DETAILS_DESCRIPTION_HEADING,
  TEST_DETAILS_DESCRIPTION_FAQ_HEADING,
} from "../../constants/text_constants";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";

const DescriptionTab = ({ testId }) => {
  const testDescription = useTestDescription(testId);
  const [testFAQ, setTestFAQ] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchTestFAQs = async (retries = 6) => {
    try {
      const requestOptions = {
        method: "POST",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_FAQ +
          `limit=20&page=0&type=test_package&id=${testId}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchTestFAQs(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setTestFAQ(json?.data?.faqs);
    } catch (err) {
      console.log("Error fetchTestFAQs", err);
    }
  };

  useEffect(() => {
    fetchTestFAQs();
  }, [testId]);

  return (
    <div>
      <div className="text-2xl font-semibold my-3">
        {TEST_DETAILS_DESCRIPTION_HEADING}
      </div>
      {testDescription?.details.map((ele, i) => (
        <div
          key={i}
          className="space-y-2 md:text-[1rem] text-sm"
          dangerouslySetInnerHTML={{ __html: ele?.description }}
        />
      ))}
      <div className="text-2xl font-bold mt-10 mb-5">
        {TEST_DETAILS_DESCRIPTION_FAQ_HEADING}
      </div>
      <div>
        {testFAQ?.map((ele, i) => (
          <div key={i}>
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger className="md:text-[1rem] text-sm text-left">
                  {ele?.question}
                </AccordionTrigger>
                <AccordionContent className="md:text-[1rem] text-sm text-left">
                  {ele?.answer}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DescriptionTab;
