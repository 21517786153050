import React, { useState, useCallback } from "react";
import BG_IMAGE from "../../assets/BgOverlayImage.jpeg";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import { toast } from "sonner";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../components/ui/hover-card";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BadgeInfo, Copy } from "lucide-react";

const OfferCard = ({
  code,
  description,
  flat_discount_amount,
  title,
  offer_discount,
  width = "w-full",
  description_limit = 150
}) => {
  const [promoCode, setPromoCode] = useState(code);
  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, rgba(34, 211, 238, 0.8), rgba(147, 51, 234, 0.8)), url('${BG_IMAGE}')`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        fontFamily: "DM Sans",
      }}
      className={"flex justify-between items-center lg:h-[12rem] h-[8rem] rounded-3xl gap-3 " + `${width}`}
    >
      <div className="bg-white rounded-full text-cyan-400 lg:h-[8rem] lg:w-[8rem] md:h-[6rem] md:w-[6rem] h-[5rem] w-[5rem] flex justify-center items-center ml-4">
        {offer_discount === "0" ? (
          <div className="flex flex-col items-center mt-4">
            <div className="lg:text-2xl md:text-xl text-lg font-bold">₹ {flat_discount_amount}</div>
            <div className="lg:text-xl md:text-lg text-[1rem] font-semibold">off</div>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <div className="lg:text-2xl md:text-xl text-lg font-bold">{offer_discount} %</div>
            <div className="lg:text-xl md:text-lg text-[1rem] font-semibold">off</div>
          </div>
        )}
      </div>
      <div className="w-3/4 ml-3">
        <div className="flex items-center text-white">
          <HoverCard>
            <HoverCardTrigger>
              <span>
                <BadgeInfo className="cursor-pointer mr-4" />
              </span>
            </HoverCardTrigger>
            <HoverCardContent className="w-[30rem]">
              {stripHtml(description)}
            </HoverCardContent>
          </HoverCard>
          <div className="font-semibold lg:text-2xl text-lg">{title}</div>
        </div>

        <div className="text-white my-2 lg:text-[1rem] text-sm">
          {stripText(stripHtml(description), description_limit)}
        </div>
        <div className="flex text-white items-center">
          <span className="font-bold border-2 border-white border-dashed  lg:rounded-xl rounded-lg lg:px-2 lg:py-1 px-1.5 py-0.5 lg:text-xl text-sm">
            {code}
          </span>
          <CopyToClipboard onCopy={onCopy} text={promoCode}>
            <button
              className="ml-2 cursor-pointer"
              onClick={() =>
                toast(`Promo ${promoCode} copied to Clipboard ✅`)
              }
            >
              <Copy />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
