import React, { useState } from "react";
import PromoCode from "./PromoCode";
import ReferralCode from "./ReferralCode";
import FinalAmount from "./FinalAmount";
import PayNow from "./PayNow";
import { useSelector } from "react-redux";

const PricingSection = ({ cartData, cartItems, gst, suggestedBooks }) => {
  const [promoAmount, setPromoAmount] = useState(0);
  const [includeWallet, setIncludeWallet] = useState(false);
  const [includePromo, setIncludePromo] = useState(false);
  const [promoInput, setPromoInput] = useState("");
  const [referralInput, setReferralInput] = useState("");

  return (
    <div>
      <div className="mt-10 text-xl font-semibold underline underline-offset-4">
        Fare Breakdown
      </div>
      <div className="mt-4">
        {cartItems?.length > 0 &&
          cartItems?.map((ele, i) => (
            <div className="flex justify-between items-center p-2" key = {i}>
              <div>{ele?.name}</div>
              <div>₹ {ele?.sellingPrice}</div>
            </div>
          ))}
        <div className="flex justify-between items-center p-2">
          <div>GST - {gst * 100}%</div>
          <div className="text-green-400">
            + ₹{" "}
            {cartItems.reduce((acc, ele) => acc + ele?.price, 0) -
              cartItems.reduce((acc, ele) => acc + ele?.sellingPrice, 0)}
          </div>
        </div>

        {includePromo && (
          <div className="flex justify-between items-center p-2">
            <div>PROMO DISCOUNT</div>
            <div className="text-red-400">- ₹{promoAmount}</div>
          </div>
        )}
        <div className="flex justify-between items-center border-b-2 border-[#5A277C] p-2 text-[#5A277C] font-semibold">
          <div>
            <input
              type="checkbox"
              id="wallet"
              onChange={(e) => setIncludeWallet(e.target.checked)}
            />
            <label htmlFor="wallet" className="ml-3">
              Wallet Balance
            </label>
          </div>
          {!includeWallet ? (
            <div>₹ {cartData?.wallet_balance}</div>
          ) : (
            <div className="text-red-400">- ₹ {cartData?.wallet_balance}</div>
          )}
        </div>
        <div className="my-2">
          <PromoCode
            setPromoAmount={setPromoAmount}
            setIncludePromo={setIncludePromo}
            includePromo={includePromo}
            price={cartItems.reduce((acc, ele) => acc + ele?.price, 0)}
            promoInput={promoInput}
            setPromoInput={setPromoInput}
          />
        </div>
        <div className="my-2">
          <ReferralCode
            referralInput={referralInput}
            setReferralInput={setReferralInput}
          />
        </div>
        <div className="my-2">
          <FinalAmount
            price={cartItems.reduce((acc, ele) => acc + ele?.price, 0)}
            promoAmount={promoAmount}
            cartData={cartData}
            includeWallet={includeWallet}
            includePromo={includePromo}
          />
        </div>
        <div className="mt-4">
          <PayNow
            price={cartItems.reduce((acc, ele) => acc + ele?.price, 0)}
            promoAmount={promoAmount}
            cartData={cartData}
            includeWallet={includeWallet}
            includePromo={includePromo}
            suggestedBooks={suggestedBooks}
            promoInput={promoInput}
            referralInput={referralInput}
            gst = {gst}
          />
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
