import React from "react";
import ScrollCarousel from "scroll-carousel-react";

const FeaturesScrollCards = ({ data }) => {
  return (
    <div className = "mt-5">
      <ScrollCarousel autoplay autoplaySpeed={8} speed={7}>
        {data.map((ele, i) => (
          <div
            key={i}
            className="text-center flex flex-col items-center bg-[#F8FCFD] lg:p-5 rounded-lg hover:bg-gradient-to-r from-cyan-500 to-purple-500 cursor-pointer lg:h-[10rem] lg:w-[10rem] md:h-[7.5rem] md:w-[7.5rem] h-[5rem] w-[5rem] mx-1"
          >
            <img
              alt={"hero-card" + ele?.id}
              src={ele?.img}
              className="lg:w-[5rem] lg:h-[5rem] md:w-[3.5rem] md:h-[3.5rem] w-[2.5rem] h-[2.5rem] bg-white p-2 rounded-full"
              loading = "lazy"
            />
            <div
              style={{
                fontFamily: "DM Sans",
                textAlign: "center",
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              <div className="text-[#130C40] lg:text-sm text-xs mt-1">{ele?.desc}</div>
            </div>
          </div>
        ))}
      </ScrollCarousel>
    </div>
  );
};

export default FeaturesScrollCards;
