import { configureStore } from "@reduxjs/toolkit";
import cacheSlice from "./cache";
import userSlice from "./userSlice";
import cartSlice from "./cartSlice";

const appStore = configureStore({
  reducer: {
    cache: cacheSlice,
    user: userSlice,
    cart : cartSlice
  },
});

export default appStore;
