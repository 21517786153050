import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    authenticated: false,
    purchasedCourses: 0,
    purchasedBooks: 0,
    purchasedTests: 0,
    purchasesTests: 0,
    purchasedBooks: 0,
    authToken : null,
    userData : null,
    walletPoints: 0,
    // delete this all 
    userName: "",
    phoneNo: "",
    email: "",
    userIcon: null,
    userCountry: null,
    userState: null,
    userCity: null,
    userPinCode: null,
    userAddress: null,
  },
  reducers: {
    setAuthentication: (state, action) => {
      state.authenticated = true;
    },
    removeAuthentication: (state, action) => {
      state.authenticated = false;
    },
    setAuthToken : (state, action) => {
      state.authToken = action?.payload;
    },
    removeAuthToken : (state, action) => {
      state.authToken = null;
    },
    setUserData : (state, action) => {
      state.userData = action?.payload;
    },
    removeUserData : (state, action) => {
      state.userData = null;
    },
    // delete this all
    setName: (state, action) => {
      state.userName = action?.payload;
    },
    setEmail: (state, action) => {
      state.email = action?.payload;
    },
    setUserCountry: (state, action) => {
      state.userCountry = action?.payload;
    },
    setUserState: (state, action) => {
      state.userState = action?.payload;
    },
    setUserCity: (state, action) => {
      state.userCity = action?.payload;
    },
    setUserPincode: (state, action) => {
      state.userPinCode = action?.payload;
    },
    setUserAddress: (state, action) => {
      state.userAddress = action?.payload;
    },
    
  },
});

export const {
  setAuthentication,
  removeAuthentication,
  setAuthToken,
  removeAuthToken,
  setUserData,
  removeUserData,
  // delete this all
  setName,
  setEmail,
  setUserCountry,
  setUserState,
  setUserCity,
  setUserPincode,
  setUserAddress,
  
} = userSlice.actions;
export default userSlice.reducer;
