import React from "react";
import VideoPlayer from "../Classroom/VideoPlayer";
import SuggestedVideos from "../Classroom/SuggestedVideos";
import { useParams } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const LibraryVideosPlay = () => {
  const { id } = useParams();
  return (
    <div
      className="flex m-12 gap-2 -mb-[10rem]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="w-3/4">
        <VideoPlayer id={id} subjectid={""} showPdfBtn = {false}/>
      </div>
      <div>
        <SuggestedVideos
          id={id}
          subjectid={""}
          clickUrl={UNPROTECTED_ROUTES?.freeLibraryVideosPlay}
        />
      </div>
    </div>
  );
};

export default LibraryVideosPlay;
