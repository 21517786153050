import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isSideBarOpen: false,
  },
  reducers: {
    toggleSideBar: (state, action) => {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    closeSideBar: (state, action) => {
      state.isSideBarOpen = false;
    },
  },
});

export const { toggleSideBar, closeSideBar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
