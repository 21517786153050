import React from "react";
import RankPlusLogo from "../../assets/RankPlus_logo.png";
import RankPlusLogoText from "../../assets/RankPlus_logo_text.png";
import SearchBar from "./SearchBar";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const NavbarLeft = () => {
  return (
    <div className="flex items-center">
      <Link to={UNPROTECTED_ROUTES?.home}>
        <div className = "flex items-center">
        <img
          alt="rank-plus-logo"
          src={RankPlusLogo}
          className="lg:w-[60px] lg:h-[64px] md:w-[50px] md:h-[52px] h-[40px] w-[42px]"
          loading = "lazy"
        />
        <img
          alt="rank-plus-logo-text"
          src={RankPlusLogoText}
          className="lg:w-[127px] lg:h-[30px] md:w-[100px] md:h-[25px] w-[85px] h-[24px] ml-4"
          loading = "lazy"
        />
        </div>
      </Link>
      <div className = "lg:block md:hidden hidden">
        <SearchBar />
      </div>
      
    </div>
  );
};

export default NavbarLeft;
