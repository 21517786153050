import React from 'react'
import LoginPageRight from './LoginPageRight'
import LoginPageLeft from './LoginPageLeft'

const LoginPage = () => {
  return (
    <div className = "p-4 flex lg:gap-10 gap-0 relative">
      <LoginPageLeft/>
      <LoginPageRight/>
    </div>
  )
}

export default LoginPage
