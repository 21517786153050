import React from "react";
import RankPlusLogo from "../../assets/RANK_PLUS_LOGO.webp";
import RankPlusLogoText from "../../assets/RankPlus_logo_text.png";

const LoginPageRightHeader = () => {
  return (
    <div className="flex flex-col items-center mt-[1rem] sm:flex-row sm:items-center sm:mt-4">
      <img
        alt="rank-plus-logo"
        src={RankPlusLogo}
        className="lg:w-[11rem] lg:h-[4rem] md:w-[8rem] md:h-[52px] h-[3rem] w-[8rem]"
        loading="lazy"
      />
    </div>
  );
};

export default LoginPageRightHeader;