import React, { useState, useEffect } from "react";
import { ScrollArea } from "../../components/ui/scroll-area";
import PDF_ICON from "../../assets/PDF_ICON_2.png";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { Lock } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import {
  modExp,
  CryptoJSAesJson,
} from "../../utils/util-functions/cryptoUtils";
import CryptoJS from "crypto-js";
import DecodePdfUrl from "../../utils/util-functions/DecryptionUtil";
import PDFDialogButton from "../PDF-View-Component/PDFDialogButton";

const ClassroomTabNotes = ({ subjectId, defaultSubjectId, isPurchased }) => {
  const [notesData, setNotesData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [encryptionData, setEncryptionData] = useState(null);

  const setPubPvtKey = () => {
    const prime = 23;
    const base = 5;

    const clientPrivateKey = Math.floor(Math.random() * 100);
    const clientPublicKey = modExp(base, clientPrivateKey, prime);

    const cryptoData = {
      clientPrivateKey: clientPrivateKey,
      clientPublicKey: clientPublicKey,
      prime: prime,
    };

    setEncryptionData(cryptoData);
  };

  const fetchSubjectNotes = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env
          .REACT_APP_RANK_PLUS_COURSE_DETAILS_LIVE_CLASS_SUBJECT_NOTES +
          (subjectId ? subjectId : defaultSubjectId) +
          "&limit=150&page=" +
          currentPage +
          `&publicKey=${encryptionData.clientPublicKey}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchSubjectNotes(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setNotesData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("Error fetchSubjectNotes", err);
    }
  };

  useEffect(() => {
    fetchSubjectNotes();
  }, [encryptionData]);

  useEffect(() => setPubPvtKey(), [currentPage, subjectId, defaultSubjectId]);


  return (
    <div>
      <ScrollArea className="lg:h-[600px] lg:w-[535px] w-full h-full rounded-md border px-4">
        {notesData?.notes.map((ele, i) => (
          <div
            key={i}
            className="bg-[#F8FCFD] border border-[#DFE3EC] rounded-lg lg:p-4 p-2 my-3 flex items-center justify-between shadow-md "
          >
            {/* left section */}
            <div className="flex items-center gap-2">
              <div className="bg-white p-2 rounded-full w-[4rem] opacity-80">
                <img src={PDF_ICON} alt="pdf icon" loading="lazy" />
              </div>
              <div className="md:w-[17rem] w-[12rem] font-semibold">
                {ele?.title}
              </div>
            </div>
            {/* Right Section */}
            <div className="-ml-[5rem]">
              {!notesData?.is_locked && (
                <div>
                  <PDFDialogButton
                    BtnStyle={
                      "border-2 border-[#5A277C] lg:px-2 lg:py-1 px-1 py-0.3 rounded-xl hover:bg-[#5A277C]"
                    }
                    BtnText={"View"}
                    BtnTextStyle={"text-[#5A277C] hover:text-white"}
                    ShowBtnText={true}
                    pdfUrl={DecodePdfUrl(
                      notesData,
                      ele?.note_pdf,
                      encryptionData
                    )}
                    DownloadFileName={ele?.title}
                    showIcon={false}
                    DownloadIcon={""}
                    DownloadIconStyle=""
                  />
                </div>
              )}

              {notesData?.is_locked && (
                <div
                  className="bg-[#5A277C] p-2 rounded-full cursor-pointer"
                  onClick={() =>
                    toast("To access this, you need to purchase the course")
                  }
                >
                  <Lock className="text-white" />
                </div>
              )}
            </div>
          </div>
        ))}
      </ScrollArea>
    </div>
  );
};

export default ClassroomTabNotes;
