import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import {
    Play,
    Pause,
    Maximize,
    Minimize,
    Rewind,
    FastForward,
    Sliders,
    Settings,
} from "lucide-react";
import { extractYouTubeVideoID } from "../../utils/util-functions/playerUtils";
import { ReactComponent as PlayerPlay } from "../../assets/playerPlay.svg"
import { ReactComponent as PlayerPlayBg } from "../../assets/playerPlayBg.svg"
import { ReactComponent as PlayerPause } from "../../assets/playerPause.svg"

const OnlyPlayer = ({ videoUrl, width, height, staticType }) => {
    const playerRef = useRef(null);
    // To handle full screen
    const containerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(!staticType ?? true);
    // Duration
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    // Playback speed
    const [playbackRate, setPlaybackRate] = useState(1);
    // Set playback Quality
    const [quality, setQuality] = useState("hd720");
    // To know as soon as player is ready
    const [playerReady, setPlayerReady] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    // New state and refs for controlling center controls visibility
    const [showCenterControls, setShowCenterControls] = useState(false);
    const hideControlsTimeout = useRef(null);
    const videoId = extractYouTubeVideoID(videoUrl);

    // Playback rate menu
    const [showPlaybackRateMenu, setShowPlaybackRateMenu] = useState(false);
    const [showQualityMenu, setShowQualityMenu] = useState(false);

    // Toggle play/pause
    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
    };

    // Load saved time on component mount
    useEffect(() => {
        if (videoId !== null) {
            const savedTime = localStorage.getItem(`video-${videoId}-current-time`);
            if (savedTime) {
                setCurrentTime(parseFloat(savedTime));
            }

            // Save current time periodically
            const interval = setInterval(() => {
                localStorage.setItem(
                    `video-${videoId}-current-time`,
                    playerRef?.current?.getCurrentTime()
                );
            }, 5000); // Save every 5 seconds

            return () => {
                clearInterval(interval);
                if (playerRef?.current?.getCurrentTime() !== undefined) {
                    localStorage.setItem(
                        `video-${videoId}-current-time`,
                        playerRef?.current?.getCurrentTime()
                    );
                }
            };
        }
    }, [videoId]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if the key pressed is the Spacebar
            if (event.code === "Space") {
                event.preventDefault(); // Prevent default spacebar behavior (e.g., page scroll)
                togglePlayPause();
            }

            // Check if the key pressed is the Right Arrow (Fast Forward)
            if (event.code === "ArrowRight") {
                event.preventDefault(); // Prevent default arrow key behavior (e.g., scrolling)
                handleFastForward();
            }

            // Check if the key pressed is the Left Arrow (Rewind)
            if (event.code === "ArrowLeft") {
                event.preventDefault(); // Prevent default arrow key behavior (e.g., scrolling)
                handleRewind();
            }

            // Check if the key pressed is the F
            if (event.code === 'KeyF') {
                event.preventDefault(); // Prevent default arrow key behavior (e.g., scrolling)
                toggleFullscreen();
            }
        };

        // Add event listener for keydown
        document.addEventListener("keydown", handleKeyDown);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [currentTime, duration]);

    // Handle progress update
    const handleProgress = (progress) => {
        setCurrentTime(progress.playedSeconds);
    };

    // Format time in minutes and seconds
    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    };

    // Handle seeking
    const handleSeekChange = (e) => {
        const newTime = parseFloat(e.target.value) * duration;
        playerRef.current.seekTo(newTime, "seconds");
        setCurrentTime(newTime);
    };

    // Set playback speed
    const handlePlaybackRateChange = (rate) => {
        const internalPlayer = playerRef.current.getInternalPlayer();
        if (internalPlayer && internalPlayer.setPlaybackRate) {
            internalPlayer.setPlaybackRate(Number(rate));
            // To disable captions
            internalPlayer.setOption("captions", "track", {});
        }
        setPlaybackRate(rate);
    };

    // Handle quality change
    const handleQualityChange = (value) => {
        const selectedQuality = value;
        setQuality(selectedQuality);

        const internalPlayer = playerRef.current.getInternalPlayer();
        if (internalPlayer) {
            const videoData = internalPlayer.getVideoData();
            const videoId = videoData.video_id;
            const currentTime = internalPlayer.getCurrentTime();

            // Reload the video with the desired quality
            internalPlayer.loadVideoById({
                videoId: videoId,
                startSeconds: currentTime,
                suggestedQuality:
                    selectedQuality === "auto" ? "default" : selectedQuality,
            });
        }
    };

    const handlePlayerReady = () => {
        setPlayerReady(true);
        const internalPlayer = playerRef.current.getInternalPlayer();
        if (internalPlayer && staticType === true) {
            internalPlayer.pauseVideo();
            // console.log('asasasassa', height, width, videoUrl, internalPlayer);
        }
        // Seek to saved time when player is ready
        if (currentTime > 0) {
            playerRef.current.seekTo(currentTime, "seconds");
        }
        // Set initial playback rate
        handlePlaybackRateChange(playbackRate);
    };

    // Updated toggleFullscreen function
    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            if (containerRef.current.requestFullscreen) {
                containerRef.current.requestFullscreen();
            } else if (containerRef.current.webkitRequestFullscreen) {
                containerRef.current.webkitRequestFullscreen();
            } else if (containerRef.current.msRequestFullscreen) {
                containerRef.current.msRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
        document.addEventListener("msfullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener(
                "webkitfullscreenchange",
                handleFullscreenChange
            );
            document.removeEventListener(
                "msfullscreenchange",
                handleFullscreenChange
            );
        };
    }, []);

    // Rewind handler
    const handleRewind = () => {
        const newTime = Math.max(currentTime - 5, 0);
        playerRef.current.seekTo(newTime, "seconds");
        setCurrentTime(newTime);
    };

    // Fast Forward handler
    const handleFastForward = () => {
        const newTime = Math.min(currentTime + 5, duration);
        playerRef.current.seekTo(newTime, "seconds");
        setCurrentTime(newTime);
    };

    // Handle mouse move and inactivity
    const handleMouseMove = () => {
        setShowCenterControls(true);
        if (hideControlsTimeout.current) {
            clearTimeout(hideControlsTimeout.current);
        }
        hideControlsTimeout.current = setTimeout(() => {
            setShowQualityMenu(false);
            setShowPlaybackRateMenu(false);
            setShowCenterControls(false);
        }, 1000);
    };

    const handleMouseLeave = () => {
        setShowCenterControls(false);
        if (hideControlsTimeout.current) {
            clearTimeout(hideControlsTimeout.current);
        }
    };

    return (
        <div className="max-w-[54rem] max-h-[30rem]">
            <div className="flex items-center gap-4 mt-3 player-wrapper">
                {videoUrl && (
                    <div
                        ref={containerRef}
                        style={{
                            width: width || "100%",
                            height: height || "auto",
                        }}
                        className="relative w-full pt-[56.25%] bg-black rounded-3xl player"
                        onMouseMove={handleMouseMove}
                    // onMouseLeave={handleMouseLeave}
                    >
                        <ReactPlayer
                            ref={playerRef}
                            url={videoUrl}
                            playing={isPlaying}
                            playbackRate={playbackRate}
                            width="100%"
                            height="100%"
                            onProgress={handleProgress}
                            onReady={handlePlayerReady}
                            onEnded={
                                () => {
                                    if (videoId !== null) {
                                        localStorage.removeItem(`video-${videoId}-current-time`);
                                        setCurrentTime(0);
                                    }
                                }
                            }
                            onPause={
                                () => {
                                    if (videoId !== null) {
                                        localStorage.setItem(
                                            `video-${videoId}-current-time`,
                                            playerRef?.current?.getCurrentTime()
                                        );
                                    }
                                }
                            }
                            onDuration={(d) => setDuration(d)}
                            controls={false}
                            config={{
                                youtube: {
                                    playerVars: {
                                        autoplay: 1,
                                        playsinline: 1,
                                        cc_load_policy: 0,
                                        rel: 0,
                                    },
                                },
                            }}
                            className="absolute top-0 left-0"
                        />

                        {/* Custom Overlay */}
                        <div
                            className="absolute top-0 left-0 w-full h-full z-10 cursor-pointer rounded-3xl"
                            onClick={togglePlayPause}
                        >
                            {/* Center Controls */}
                            {showCenterControls && (
                                <div className="absolute inset-0 flex items-center z-20 w-full">
                                    {/* Rewind Section */}
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent the click from bubbling to the overlay
                                            handleRewind();
                                        }}
                                        className="flex-1 flex justify-center items-center"
                                        aria-label="Rewind 5 seconds"
                                    >
                                        <div className="inline-flex items-center justify-center p-1 md:p-2 lg:p-3 bg-black bg-opacity-50 rounded-full">
                                            <Rewind className="h-4 w-4 md:h-6 md:w-6 lg:h-8 lg:w-8 text-white" />
                                        </div>
                                    </button>
                              
                                    {/* Play/Pause Section */}
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent the click from bubbling to the overlay
                                            togglePlayPause();
                                        }}
                                        className="flex-1 flex justify-center items-center"
                                        aria-label={isPlaying ? 'Pause' : 'Play'}
                                    >
                                        <div className="inline-flex items-center justify-center p-1 md:p-2 lg:p-3 bg-black bg-opacity-50 rounded-full">
                                            {isPlaying ? (
                                                <div className="relative h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12">
                                                    <PlayerPlayBg className="h-full w-full" />
                                                    <PlayerPause className="absolute inset-0 h-3 w-3 md:h-4 md:w-4 lg:h-5 lg:w-5 m-auto text-white" />
                                                </div>
                                                ) : (
                                                <div className="relative h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12">
                                                    <PlayerPlayBg className="h-full w-full" />
                                                    <PlayerPlay className="absolute inset-0 h-3 w-3 md:h-4 md:w-4 lg:h-5 lg:w-5 m-auto text-white" />
                                                </div>
                                            )}
                                        </div>
                                    </button>
                              
                                    {/* Fast Forward Section */}
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent the click from bubbling to the overlay
                                            handleFastForward();
                                        }}
                                        className="flex-1 flex justify-center items-center"
                                        aria-label="Fast Forward 5 seconds"
                                    >
                                        <div className="inline-flex items-center justify-center p-1 md:p-2 lg:p-3 bg-black bg-opacity-50 rounded-full">
                                            <FastForward className="h-4 w-4 md:h-6 md:w-6 lg:h-8 lg:w-8 text-white" />
                                        </div>
                                    </button>
                              </div>
                              
                            )}
                        </div>

                        {/* Bottom Controls */}
                        {showCenterControls && (
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 px-2 py-1 md:px-4 md:py-2 lg:px-6 lg:py- flex items-center text-white space-x-2 rounded-b-3xl z-20">
                                {/* Seek Bar */}
                                <input
                                    type="range"
                                    min={0}
                                    max={1}
                                    step="any"
                                    value={currentTime / duration || 0}
                                    onChange={handleSeekChange}
                                    className="w-full h-1 bg-gray-400 rounded"
                                />

                                {/* Time Display */}
                                <div className="text-sm w-[12rem] flex justify-center">
                                    <div>
                                        {formatTime(currentTime)} / {formatTime(duration)}
                                    </div>
                                </div>

                                {/* Playback Speed */}
                                <div className="relative">
                                    <button
                                        onClick={() => {
                                            setShowPlaybackRateMenu((prev) => !prev);
                                            setShowQualityMenu(false); // Close quality menu if open
                                        }}
                                        className="inline-flex items-center justify-center px-2"
                                        aria-label="Playback Speed"
                                    >
                                        <Sliders className="h-4 w-4 md:h-6 md:w-6" />
                                    </button>
                                    {showPlaybackRateMenu && (
                                        // className="absolute bottom-full mb-1 md:mb-2 right-0 bg-black bg-opacity-80 text-white rounded px-2 py-1 md:px-3 md:py-2"

                                        <div className="absolute bottom-full mb-1 md:mb-2 right-0 bg-black bg-opacity-80 text-white rounded px-2 py-1 md:px-3 md:py-2">
                                            {[0.25, 0.5, 1, 1.5, 2].map((rate) => (
                                                <div
                                                    key={rate}
                                                    className={`px-2 py-1 cursor-pointer hover:bg-gray-700 ${playbackRate === rate ? "text-yellow-400" : ""
                                                        }`}
                                                    onClick={() => handlePlaybackRateChange(rate)}
                                                >
                                                    {rate}x
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {/* Quality Selector */}
                                {/* Quality Icon */}
                                <div className="relative">
                                    <button
                                        onClick={() => {
                                            setShowQualityMenu((prev) => !prev);
                                            setShowPlaybackRateMenu(false); // Close playback rate menu if open
                                        }}
                                        className="inline-flex items-center justify-center px-2"
                                        aria-label="Quality"
                                    >
                                        <Settings className="h-4 w-4 md:h-6 md:w-6" />
                                    </button>
                                    {showQualityMenu && (
                                        <div className="absolute bottom-full mb-2 right-0 bg-black bg-opacity-80 text-white rounded p-2">
                                            {[
                                                { value: "auto", label: "Auto" },
                                                { value: "highres", label: "Highres" },
                                                { value: "hd1080", label: "1080p" },
                                                { value: "hd720", label: "720p" },
                                                { value: "large", label: "480p" },
                                                { value: "medium", label: "360p" },
                                                { value: "small", label: "240p" },
                                                { value: "tiny", label: "144p" },
                                            ].map((option) => (
                                                <div
                                                    key={option.value}
                                                    className={`px-2 py-1 cursor-pointer hover:bg-gray-700 ${quality === option.value ? "text-yellow-400" : ""
                                                        }`}
                                                    onClick={() => handleQualityChange(option.value)}
                                                >
                                                    {option.label}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {/* Fullscreen Toggle */}
                                <button
                                    onClick={toggleFullscreen}
                                    className="inline-flex items-center justify-center px-2"
                                    aria-label={
                                        isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"
                                    }
                                >
                                    {isFullscreen ? (
                                        <Minimize className="h-4 w-4 md:h-6 md:w-6" />
                                    ) : (
                                        <Maximize className="h-4 w-4 md:h-6 md:w-6" />
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default OnlyPlayer;
