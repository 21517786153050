import React, { useState } from "react";
import useClassroomVideoData from "../../utils/use-classroomVideoData";
import Rating from "./Rating";
import AskDoubt from "./AskDoubt";
import VideoDescription from "./VideoDescription";
import CustomVideoPlayer from "../VideoPlayer/CustomVideoPlayer";
import PDFDialogButton from "../PDF-View-Component/PDFDialogButton";
import DOWNLOAD_ICON from "../../assets/pdf_download.png";

const VideoPlayer = ({ id, subjectid, showPdfBtn }) => {
  const videoData = useClassroomVideoData(id);
  const data = videoData?.details?.lecture?.[0];
  const [noteUrl, setNoteUrl] = useState();

  return (
    <div>
      {data && <CustomVideoPlayer data={data} setNoteUrl={setNoteUrl} />}
      {/* <div>
        <img
          src={videoData?.details?.lecture?.[0]?.thumbnail}
          alt="video-thumbnail"
          className="w-[53rem] rounded-xl"
        />
      </div> */}
      <VideoDescription data={data} />
      <div className="flex items-center gap-4 mt-3">
        <div>
          <Rating data={data} id={id} />
        </div>
        <div>
          {showPdfBtn && (
            <PDFDialogButton
              BtnStyle={
                "flex gap-1 bg-[#F8FCFD] border border-[#DFE3EC] items-center py-1 px-2 rounded-3xl shadow-sm cursor-pointer hover:-translate-y-1 hover:shadow-2xl transition-all"
              }
              BtnText={"View Notes"}
              BtnTextStyle={"font-semibold text-[#595763]"}
              pdfUrl={noteUrl}
              DownloadFileName={data?.title}
              showIcon={true}
              ShowBtnText={true}
              DownloadIcon={DOWNLOAD_ICON}
              DownloadIconStyle={"w-8 h-8"}
            />
          )}
        </div>
        <div>
          <AskDoubt id={id} />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
