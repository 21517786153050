import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import SuggestedVideos from "./SuggestedVideos";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import { PROTECTED_ROUTES } from "../../routes/routes";

const ClassroomPage = () => {
  const { id, subjectid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <div className="flex md:flex-row flex-col lg:m-[1.5rem] m-[1.3rem] md:gap-[2rem] gap-4 -mb-[10rem] overflow-hidden" style = {{fontFamily : "DM Sans"}}>
      {innerWidth <= 770 && (
          <div className="w-full">
            <VideoPlayer id={id} subjectid={subjectid} showPdfBtn = {true} />
          </div>
      )}
      {innerWidth > 770 && (
          <div className="w-screen">
            <VideoPlayer id={id} subjectid={subjectid} showPdfBtn = {true} />
          </div>
      )}
      <div>
        <SuggestedVideos id={id} subjectid={subjectid} clickUrl = {PROTECTED_ROUTES?.classroom}/>
      </div>
    </div>
  );
};

export default ClassroomPage;
