import React from "react";
import SolutionTab from "../../TestDetails/SolutionTab";

const TestSolutionTab = ({ testId, isPurchased }) => {
  return (
    <div className="mx-[1rem]">
      <SolutionTab testPackageId={testId} isPurchased={isPurchased} />
    </div>
  );
};

export default TestSolutionTab;
