import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";

const useCourseFAQ = (courseId) => {
  const [courseFaq, setCourseFaq] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchCourseFAQs = async (retries = 6) => {
    try {
      const requestOptions = {
        method: "POST",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_FAQ +
          `limit=20&page=0&type=course&id=${courseId}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchCourseFAQs(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setCourseFaq(json?.data?.faqs);
    } catch (err) {
      console.log("Error fetchCourseFAQs", err);
    }
  };

  useEffect(() => {
    fetchCourseFAQs();
  }, [courseId]);

  return courseFaq;
};

export default useCourseFAQ;
