import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "sonner";
import { Gem } from "lucide-react";
import ViewMoreOfferBtn from "./ViewMoreOfferBtn";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";

const PromoCode = ({
  setPromoAmount,
  setIncludePromo,
  includePromo,
  price,
  promoInput,
  setPromoInput,
}) => {
  const cart = useSelector((store) => store.cart.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyPromoCode = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_VERIFY_PROMO_CODE +
          `${promoInput}&item_type=${cart?.item}&item_id=${cart?.id}`,
        requestOptions
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => verifyPromoCode(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      console.log(json);
      if (json?.data?.valid) {
        toast(`Promo code ${promoInput} applied successfully ✅`);
        setIncludePromo(true);
        if (json?.data?.offer_details?.offer_discount !== "0") {
          if (json?.data?.offer_details?.max_limit_amount === "0.00") {
            setPromoAmount(
              ((parseFloat(json?.data?.offer_details?.offer_discount) / 100) *
                price).toFixed(2)
            );
          } else {
            setPromoAmount(
              Math.min(
                json?.data?.offer_details?.max_limit_amount,
                (parseFloat(json?.data?.offer_details?.offer_discount) / 100) *
                  price
              ).toFixed(2)
            );
          }
        } else {
          setPromoAmount(json?.data?.offer_details?.flat_discount_amount);
        }
      } else {
        toast(json?.message + ` 🥲`);
        setPromoAmount(0);
        setPromoInput("");
        setIncludePromo(false);
      }
    } catch (err) {
      console.error("Error verifyPromoCode", err);
    }
  };

  function handleAddPromo() {
    verifyPromoCode();
  }

  function handleRemovePromo() {
    setIncludePromo(false);
    setPromoAmount(0);
    setPromoInput("");
  }

  return (
    <div className="flex justify-between items-center border-b-2 border-[#5A277C] pb-2">
      <div className="flex gap-5 items-center">
        <div className="font-semibold text-[#5A277C] flex items-center gap-2">
          <div>
            <Gem />
          </div>
          <div>Have a Promo Code (optional)</div>
        </div>
        <div>
          <input
            type="text"
            placeholder="Enter Promo Code"
            value={promoInput}
            onChange={(e) => setPromoInput(e.target.value)}
            className="outline-none pb-1 max-w-[10rem] text-[#5A277C]"
          />
        </div>
      </div>
      <div className="flex items-center">
        <div>
          <ViewMoreOfferBtn />
        </div>
        <div>
          {!includePromo ? (
            <button
              className="border-2 border-[#5A277C] px-2 py-1 rounded-lg text-[#5A277C] hover:bg-[#5A277C] hover:text-white transition-all"
              onClick={handleAddPromo}
            >
              Apply
            </button>
          ) : (
            <button
              className="border-2 border-[#5A277C] px-2 py-1 rounded-lg text-[#5A277C] hover:bg-[#5A277C] hover:text-white transition-all"
              onClick={handleRemovePromo}
            >
              Remove
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoCode;
