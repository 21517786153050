import React from "react";
import PageBanner2 from "../PageBanner/PageBanner2";
import BANNER_IMG from "../../assets/Doctor.png";
import {
  LANDING_PAGE_BANNER_1,
  LANDING_PAGE_BANNER_2,
} from "../../constants/text_constants";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import BANNER_IMG_1 from "../../assets/RankPlusTeacher.png";
import HeroBannerCarousel from "./HeroBannerCarousel";

const PromoCode = ({ webBanners }) => {
  return (
    <span
      className="text-2xl p-2"
      style={{ borderRadius: "10px", border: "1px dashed #FFF" }}
    >
      {LANDING_PAGE_BANNER_1?.subtitle}
    </span>
  );
};

const HeroBanner = ({ webBanners }) => {
  return (
    <div>
      {/* <PageBanner
        title={LANDING_PAGE_BANNER_1?.title1}
        title2={LANDING_PAGE_BANNER_1?.title2}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#FFF"}
        component={<PromoCode />}
        backgroundPosition={"top"}
      /> */}
      <HeroBannerCarousel webBanners={webBanners} />
      <PageBanner2
        title={LANDING_PAGE_BANNER_2?.title1}
        title2={LANDING_PAGE_BANNER_2?.title2}
        subtitle={LANDING_PAGE_BANNER_2?.subtitle}
        rightImgSrc={BANNER_IMG_1}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
        backgroundPosition={"center"}
      />
    </div>
  );
};

export default HeroBanner;
