import React from "react";
import LoginPageRightHeader from "./LoginPageRightHeader";
import LoginPageRightForm from "./LoginPageRightForm";

const LoginPageRight = () => {
  return (
    <div className = "w-[35rem]">
      <LoginPageRightHeader/>
      <LoginPageRightForm/>
    </div>
  );
};

export default LoginPageRight;
