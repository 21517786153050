import React from "react";
import useCourseHeaders from "../../../utils/use-CourseHeaders";
import DPPTab from "../../CourseDetail/DPPTab";

const CourseDPPTab = ({ courseId, isPurchased, innerWidth }) => {
  const courseHeaders = useCourseHeaders(courseId);

  return (
    <div className = "mx-[1rem]">
      <DPPTab
        testPackageId={courseHeaders?.details[0]?.test_package_id}
        isPurchased={isPurchased}
        showMetaDescription = {false}
      />
    </div>
  );
};

export default CourseDPPTab;
