import React from "react";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { Link } from "react-router-dom";

const SignUpTermsCondition = ({ setTnc, tnc }) => {
  function handleClick(e) {
    setTnc(e.target.checked);
  }

  return (
    <div className="flex items-center lg:mt-0 mt-[1.5rem]">
      <input type="checkbox" id="sign-up-tnc" onClick={handleClick} checked = {tnc ? true : false} />
      <label htmlFor="sign-up-tnc" className="ml-2 cursor-pointer">
        Terms and Conditions -{" "}
        <Link to={UNPROTECTED_ROUTES?.terms}>
          <span className="text-[#03B3C3]">read more</span>
        </Link>
      </label>
    </div>
  );
};

export default SignUpTermsCondition;
