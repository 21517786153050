export function stripText(plainText, limit=250, requiredExtraData={returnTextsLengths : false}) {
  const{returnTextsLengths} = requiredExtraData;
  const plainTextLength = plainText?.length;
  let stripedTextLength = plainTextLength;
  if (plainTextLength > limit) {
    const stripedText = plainText.substring(0, limit);
    stripedTextLength = stripedText?.length;
    plainText = stripedText + "...";
  }

  return returnTextsLengths ? {plainText, plainTextLength, stripedTextLength} : plainText;
}
