import React, { useState, useEffect } from "react";
import { Card, CardContent } from "../../components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { cn } from "../../lib/utils";
import Autoplay from "embla-carousel-autoplay";

const PromoCarousel = ({ data }) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <Carousel
        className={cn("lg:w-[70rem] md:w-[40rem] w-[18rem]")}
        plugins={[
          Autoplay({
            delay: 3000,
          }),
        ]}
      >
        <CarouselPrevious />
        <CarouselContent>
          {data?.map((ele, index) => (
            <CarouselItem
              key={index}
              className="pl-4 md:basis-1/2 lg:basis-1/3"
            >
              <div className="p-1">
                <Card className="shadow-lg rounded-2xl">
                  <CardContent
                    className="flex flex-col items-center justify-around p-2"
                    style={{
                      fontFamily: "DM Sans",
                    }}
                  >
                    {/* code goes here */}

                    <Dialog>
                      <DialogTrigger>
                        {innerWidth <= 450 && (
                          <div className="flex flex-col items-center">
                            <iframe
                              width="260"
                              height="180"
                              src={ele?.videoid}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title={ele.title}
                              className = "rounded-3xl"
                            ></iframe>
                            <div className="text-center font-semibold lg:text-[1rem] text-[0.8rem] md:w-full w-[16rem] my-[1rem]">
                              {ele.title}
                            </div>
                          </div>
                        )}
                        {innerWidth >= 800 && (
                          <div className="flex flex-col items-center">
                            <iframe
                              width="330"
                              height="200"
                              src={ele?.videoid}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title={ele?.title}
                              className = "rounded-3xl"
                            ></iframe>
                            <p className="text-center font-semibold lg:text-[1rem] text-[0.8rem] md:w-full w-[16rem] my-[1rem]">
                              {ele?.title}
                            </p>
                          </div>
                        )}
                      </DialogTrigger>
                      <DialogContent className="lg:max-w-[600px] md:max-w-[400px] max-w-[20rem] lg:w-[90vw] w-[90vw] lg:h-[60vh] md:h-[60vh] h-[90vw]">
                        <DialogHeader>
                          <DialogTitle className="lg:w-[30rem] md:w-[20rem] w-[17rem] text-2xl">
                            <div className="bg-gradient-to-r from-cyan-400 to-purple-600 bg-clip-text text-transparent lg:text-2xl text-lg font-bold">
                              {ele?.title}
                            </div>
                          </DialogTitle>
                          <DialogDescription>
                            {innerWidth <= 450 && (
                              <iframe
                                width="260"
                                height="180"
                                src={ele?.videoid}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title={ele?.title}
                              ></iframe>
                            )}
                            {innerWidth >= 800 && (
                              <iframe
                                width="550"
                                height="300"
                                src={ele?.videoid}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title={ele?.title}
                              ></iframe>
                            )}
                          </DialogDescription>
                        </DialogHeader>
                      </DialogContent>
                    </Dialog>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselNext />
      </Carousel>
    </div>
  );
};

export default PromoCarousel;
