export function convertQuality(quality) {
    // Convert string to integer for specific resolutions
    switch (quality) {
      case "1080":
        return 1080;
      case "720":
        return 720;
      case "360":
        return 360;
      case "144":
        return 144;
      case "Auto":
        return 144; // Default for "Auto"
      default:
        return null; // Return null or handle invalid input as desired
    }
}