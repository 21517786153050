import { useState, useEffect } from "react";
import { modExp } from "./util-functions/cryptoUtils";

const useClassroomVideoDataPubKey = (id) => {
  const [videosData, setVideosData] = useState(null);
  const [encryptionData, setEncryptionData] = useState(null);

  const fetchVideoData = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_VIDEO_DATA + id + `/?publicKey=${encryptionData.clientPublicKey}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchVideoData(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      const finalRes = {
        videoData: json?.data,
        cryptoData: encryptionData,
      }
      setVideosData(finalRes);
      localStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchVideoData", err);
    }
  };

  useEffect(() => {
    setPubPvtKey();
  }, [id]);
  
  useEffect(() => {
    fetchVideoData();
  }, [encryptionData]);

  const setPubPvtKey = () => {
    const prime = 23;
    const base = 5;

    // Client's private key and public key
    const clientPrivateKey = Math.floor(Math.random() * 100);
    const clientPublicKey = modExp(base, clientPrivateKey, prime);

    const cryptoData = {
        clientPrivateKey: clientPrivateKey,
        clientPublicKey: clientPublicKey,
        prime: prime,
    };

    setEncryptionData(cryptoData);
  }

  return videosData;
};

export default useClassroomVideoDataPubKey;
