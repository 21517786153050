import React from "react";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const LoginRegisterButton = () => {
  return (
    <div className="flex items-center justify-center">
      <button className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all">
        <Link to={UNPROTECTED_ROUTES.login}>
          <span className="drop-shadow-md">Login/Register</span>
        </Link>
      </button>
    </div>
  );
};

export default LoginRegisterButton;
