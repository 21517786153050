import { toast } from "sonner";
import { addToCart } from "../../configs/cartSlice";
import { PROTECTED_ROUTES } from "../../routes/routes";

export default function handleBuy(
  dispatch,
  navigate,
  id,
  item,
  name,
  offer_price,
  offer_discount,
  price,
  principal_amount,
  course_banner,
  thumbnail
) {
  const userAuthenticated = localStorage.getItem("authenticated") === "1";
  if (userAuthenticated) {
    let finalPrice =
      offer_price === "0.00" ? parseInt(price) : parseInt(offer_price);
    // offer_price === "0.00" -> price leke gst karna hai
    // else offer_price ko leke gst karna hai
    // let abc
    let sellingPrice = !principal_amount ? 0 : parseInt(principal_amount);
    // let sellingPrice = parseInt(principal_amount);

    const cartData = {
      id,
      item,
      name,
      price: finalPrice,
      course_banner,
      thumbnail,
      sellingPrice,
      offer_discount,
    };

    // add to cart
    dispatch(addToCart(cartData));
    // navigate to /buy page
    toast("⚠️ Do not refresh the page");
    navigate(PROTECTED_ROUTES?.buy);
  } else {
    toast(`You need to log in to purchase this ${item.replace("_", "")}`);
  }
}
