import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: {},
  },
  reducers: {
    addToCart: (state, action) => {
      state.cart = action?.payload;
    },
    clearCard: (state, action) => {
      state.cart = {};
    },
  },
});

export const { addToCart, clearCard } = cartSlice.actions;
export default cartSlice.reducer;
