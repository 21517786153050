import React, { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import {
  CryptoJSAesJson,
  modExp,
} from "../../utils/util-functions/encryptionUtils";
import useClassroomVideoDataPubKey from "../../utils/use-classroomVideoDataPubKey";
import ReactPlayer from "react-player";
import { convertQuality } from "../../utils/util-functions/playerUtils";
import {
  Play,
  Pause,
  Maximize,
  Minimize,
  Rewind,
  FastForward,
} from "lucide-react";

const CustomVideoPlayer = ({ data, setNoteUrl }) => {
  const videoData = useClassroomVideoDataPubKey(data.id);

  const [videoUrl, setVideoUrl] = useState();
  const playerRef = useRef(null);
  const containerRef = useRef(null); // New reference for the container
  // To handle play and pause
  const [isPlaying, setIsPlaying] = useState(true);
  // Duration
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  // Playback speed
  const [playbackRate, setPlaybackRate] = useState(1);
  // Set playback Quality
  const [quality, setQuality] = useState("hd720");
  // Internal player from iframe api
  const [player, setPlayer] = useState(null);
  // Unique key to force reload
  const [key, setKey] = useState(Date.now());
  // To know as soon as player is ready
  const [playerReady, setPlayerReady] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (videoData !== undefined && videoData !== null) {
      const serverPublicKey = videoData.videoData.details.publicKey;

      const clientSharedSecret = modExp(
        serverPublicKey,
        videoData.cryptoData.clientPrivateKey,
        videoData.cryptoData.prime
      );

      // Convert the shared secret to a 256-bit key for AES encryption
      const lectureUrl = JSON.parse(
        CryptoJS.AES.decrypt(
          videoData.videoData.details.lecture[0].url,
          clientSharedSecret.toString(),
          { format: CryptoJSAesJson }
        ).toString(CryptoJS.enc.Utf8)
      );
      setVideoUrl(lectureUrl);

      if (videoData?.videoData?.details?.lecture?.[0]?.note_url) {
        const noteUrl = JSON.parse(
          CryptoJS.AES.decrypt(
            videoData.videoData.details.lecture[0].note_url,
            clientSharedSecret.toString(),
            { format: CryptoJSAesJson }
          ).toString(CryptoJS.enc.Utf8)
        );
        setNoteUrl(noteUrl);
      }
    }
  }, [videoData]);

  // Toggle play/pause
  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  // Handle progress update
  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds);
  };

  // Format time in minutes and seconds
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  // Handle seeking
  const handleSeekChange = (e) => {
    const newTime = parseFloat(e.target.value) * duration;
    playerRef.current.seekTo(newTime, "seconds");
    setCurrentTime(newTime);
  };

  // Set playback speed
  const handlePlaybackRateChange = (rate) => {
    if (
      playerRef?.current?.getInternalPlayer()?.playerInfo?.playbackRate !==
      undefined
    ) {
      playerRef.current.getInternalPlayer().setPlaybackRate(Number(rate));
    }
    setPlaybackRate(rate);
  };

  // Handle quality change
  const handleQualityChange = (e) => {
    const selectedQuality = e.target.value;
    const now = Date.now();
    if (selectedQuality === "Auto") {
      localStorage.removeItem("yt-player-quality");
    } else {
      localStorage.setItem(
        "yt-player-quality",
        JSON.stringify({
          data: JSON.stringify({
            quality: convertQuality(selectedQuality),
            previousQuality: convertQuality(quality),
          }),
          creation: now,
          expiration: now + 2592000000,
        })
      );

      localStorage.setItem(
        "yt-player-performance-cap",
        JSON.stringify({
          data: JSON.stringify({}),
          creation: now + 1,
          expiration: now + 2592000000,
        })
      );
    }
    setQuality(selectedQuality);
    setPlaybackQuality(selectedQuality);

    const internalPlayer = playerRef.current.getInternalPlayer();
    if (internalPlayer) {
      const videoId = internalPlayer.getVideoData().video_id;
      const currentTime = internalPlayer.getCurrentTime();
      internalPlayer.loadVideoById({
        videoId,
        startSeconds: currentTime,
        suggestedQuality: selectedQuality,
      });
    }
  };

  const setPlaybackQuality = (playbackQuality, isPlaying) => {
    if (playerRef.current && (playerReady || (isPlaying ?? false))) {
      const internalPlayer = playerRef.current.getInternalPlayer();
      if (
        internalPlayer &&
        typeof internalPlayer.setPlaybackQuality === "function"
      ) {
        internalPlayer.setPlaybackQuality(playbackQuality);
        setIsPlaying(true);
      }
    }
  };

  // Function to reload the video
  const reloadVideo = () => {
    setTimeout(() => {
      setKey(Date.now()); // Change key to current timestamp
    }, 1000);
  };

  const handlePlayerReady = (event) => {
    setPlayer(playerRef.current.getInternalPlayer());
    setPlayerReady(true);
    if (quality !== "auto") {
      setPlaybackQuality(quality, event.props.playing);
    }
  };

  // Updated toggleFullscreen function
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.webkitRequestFullscreen) { /* Safari */
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) { /* IE11 */
        containerRef.current.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange); // Safari
    document.addEventListener("msfullscreenchange", handleFullscreenChange); // IE11

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
      document.removeEventListener("msfullscreenchange", handleFullscreenChange);
    };
  }, []);

  // Add rewind and fast-forward handlers
  const handleRewind = () => {
    const newTime = Math.max(currentTime - 5, 0);
    playerRef.current.seekTo(newTime, "seconds");
    setCurrentTime(newTime);
  };

  const handleFastForward = () => {
    const newTime = Math.min(currentTime + 5, duration);
    playerRef.current.seekTo(newTime, "seconds");
    setCurrentTime(newTime);
  };

  return (
    <div className="max-w-[54rem] max-h-[30rem]">
      <div className="flex items-center gap-4 mt-3 player-wrapper">
        {videoUrl && (
          <div
            ref={containerRef}
            className="relative w-full pt-[56.25%] bg-black rounded-3xl player"
          >
            <ReactPlayer
              key={key} // Changing this key will reload the player
              ref={playerRef}
              url={videoUrl}
              playing={isPlaying}
              playbackRate={playbackRate}
              width="100%"
              height="100%"
              onProgress={handleProgress}
              onReady={handlePlayerReady}
              onDuration={(d) => setDuration(d)}
              controls={false}
              config={{
                youtube: {
                  playerVars: {
                    autoplay: 1,
                  },
                },
              }}
              className="absolute top-0 left-0"
            />

            {/* Custom Overlay */}
            <div
              className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20 z-10 flex items-center justify-center cursor-pointer rounded-3xl"
              onClick={togglePlayPause}
            ></div>

            {/* Custom Controls */}
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 p-2 flex items-center text-white space-x-2 rounded-b-3xl z-20">
              {/* Rewind Button */}
              <button
                onClick={handleRewind}
                className="inline-flex items-center justify-center px-2"
                aria-label="Rewind 5 seconds"
              >
                <Rewind />
              </button>

              {/* Play/Pause Button */}
              <button
                onClick={togglePlayPause}
                className="inline-flex items-center justify-center px-2"
                aria-label={isPlaying ? "Pause" : "Play"}
              >
                {isPlaying ? <Pause /> : <Play />}
              </button>
              {/* Fast Forward Button */}
              <button
                onClick={handleFastForward}
                className="inline-flex items-center justify-center px-2"
                aria-label="Fast Forward 5 seconds"
              >
                <FastForward />
              </button>
              {/* Seek Bar */}
              <input
                type="range"
                min={0}
                max={1}
                step="any"
                value={currentTime / duration || 0}
                onChange={handleSeekChange}
                className="w-full h-1 bg-gray-400 rounded"
              />
              {/* Time Display */}
              <div className="text-sm w-[12rem] flex justify-center">
                <div>
                  {formatTime(currentTime)} / {formatTime(duration)}
                </div>
              </div>
              {/* Playback Speed */}
              <select
                value={playbackRate}
                onChange={(e) => handlePlaybackRateChange(e.target.value)}
                className="bg-gray-700 text-white rounded px-2"
              >
                <option value={0.25}>0.25x</option>
                <option value={0.5}>0.5x</option>
                <option value={1}>1x</option>
                <option value={1.5}>1.5x</option>
                <option value={2}>2x</option>
              </select>
              {/* Quality Selector */}
              <select
                value={quality}
                onChange={handleQualityChange}
                className="bg-gray-700 text-white rounded px-2"
              >
                <option value="auto">Auto</option>
                <option value="1080">1080p (HD)</option>
                <option value="720">720p (HD)</option>
                <option value="360">360p</option>
                <option value="144">144p</option>
              </select>

              {/* Fullscreen Toggle */}
              <button
                onClick={toggleFullscreen}
                className="inline-flex items-center justify-center px-2"
                aria-label={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
              >
                {isFullscreen ? <Minimize /> : <Maximize />}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomVideoPlayer;
