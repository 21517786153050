import React from "react";
import RankPlusLogo from "../../assets/RankPlus_logo.png";
import RankPlusLogoText from "../../assets/RankPlus_logo_text.png";

const LoginPageRightHeader = () => {
  return (
    <div className="flex flex-col items-center mt-[1rem] sm:flex-row sm:items-center sm:mt-4">
      <img
        alt="rank-plus-logo"
        src={RankPlusLogo}
        className="w-[60px] h-[64px]"
        loading="lazy"
      />

      <img
        alt="rank-plus-logo-text"
        src={RankPlusLogoText}
        className="w-[127px] h-[30px] mt-2 sm:mt-0 sm:ml-4"
        loading="lazy"
      />
    </div>
  );
};

export default LoginPageRightHeader;