import React from "react";
import { Card, CardContent } from "../../components/ui/card";
import { UserRoundPen } from "lucide-react";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { Link } from "react-router-dom";
import StarRating from "../Star-Component/StarRating";

const CourseCard = ({
  name,
  course_icon,
  subject_count,
  offer_price,
  offer_discount,
  price,
  course_banner,
  lecture_count,
  note_count,
  dpp_count,
  test_count,
  rating,
  faculty_name,
  thumbnail,
  description,
  id,
}) => {
  return (
    <Link to={UNPROTECTED_ROUTES?.courseDetails + "/" + id}>
      <div className="rounded-2xl">
        <Card className="rounded-2xl">
          <CardContent
            style={{ fontFamily: "DM Sans" }}
            className="aspect-square p-0 shadow-2xl rounded-2xl"
          >
            {/* section 1 - course banner*/}
            <div className="lg:text-xl md:text-lg ml-5 my-2" style={{ fontWeight: "600" }}>
              {name}
            </div>
            <div className="flex justify-center">
              <img
                src={course_banner}
                alt={name}
                className="rounded-2xl lg:w-[25rem] lg:h-[13rem] md:w-[20rem] w-[16rem] h-[10rem] mx-2"
                loading="lazy"
              />
            </div>
            <div className="px-3">
              {/* Section 2 - faculty img + name & star*/}
              <div className="flex justify-between items-center my-2">
                {/* faculty img + name */}
                <div className="flex justify-between items-center">
                  <div>
                    {thumbnail ? (
                      <img
                        src={thumbnail}
                        alt={faculty_name}
                        className="w-10 h-10 rounded-full"
                        loading="lazy"
                      />
                    ) : (
                      <UserRoundPen />
                    )}
                  </div>
                  <div className="ml-2 lg:text-[1rem] text-xs">{faculty_name ? faculty_name : ""}</div>
                </div>
                {/* rating */}
                <div className="">
                  <StarRating rating = {Math.floor(rating)}/>
                </div>
              </div>
              {/* Section 3 - course name */}

              {/* Section 4 - Course Description */}
              <div className="md:text-sm text-xs my-3">
                {stripText(stripHtml(description), 80)}
              </div>
              {/* Section 5 - Metrics */}
              <div className="flex justify-around border-b border-gray-200 pb-2 lg:text-[1rem] text-sm">
                {/* Lecture */}
                <div className="flex flex-col items-center">
                  <div className="font-semibold">{lecture_count}+</div>
                  <div className="text-[#82808D]">Lectures</div>
                </div>
                {/* Hand Notes */}
                <div className="flex flex-col items-center">
                  <div className="font-semibold">{note_count}+</div>
                  <div className="text-[#82808D]">Hand Notes</div>
                </div>
                {/* DPP */}
                <div className="flex flex-col items-center">
                  <div className="font-semibold">{dpp_count}+</div>
                  <div className="text-[#82808D]">DPP</div>
                </div>
                {/* Live tests */}
                <div className="flex flex-col items-center">
                  <div className="font-semibold">{test_count}+</div>
                  <div className="text-[#82808D]">Live Tests</div>
                </div>
              </div>
              {/* Section 6 - Button and Price */}
              <div className="flex justify-between items-center my-[1rem] lg:text-[1rem] text-sm">
                {/* Price */}
                <div className="flex items-center gap-1">
                  {/* Discount percentage */}
                  <div>
                    {offer_discount !== "0" && offer_discount !== null ? (
                      <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                        {offer_discount}% off
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="flex flex-col">
                    {/* original price */}
                    {offer_discount !== "0" && offer_discount !== null && (
                      <div className="line-through text-gray-500">₹{price}</div>
                    )}
                    {/* offer_price */}
                    <div className="text-[#5A277C] font-bold">
                      ₹
                      {offer_discount !== "0" && offer_discount !== null
                        ? offer_price
                        : price}
                    </div>
                  </div>
                </div>
                {/* buttons */}
                <div className="flex gap-3">
                  {/* Explore More Button */}

                  <div>
                    <Link to={UNPROTECTED_ROUTES?.courseDetails + "/" + id}>
                      <button
                        className="text-[#03B3C3] px-2 py-1 rounded-xl cursor-pointer"
                        style={{
                          backgroundColor: "rgba(30, 203, 219, 0.15)",
                          fontWeight: 700,
                        }}
                      >
                        Explore More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="text-white text-[1px]">h</div>
            </div>
          </CardContent>
        </Card>
      </div>
    </Link>
  );
};

export default CourseCard;
