import React, {useState, useEffect} from "react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import { UserRoundPen } from "lucide-react";

const UserIconAvatar = ({userName, size = ""}) => {
  const [userIcon, setUserIcon] = useState();

  useEffect(() => {
    const storedIcon = localStorage.getItem("userProfilePic");
    if (storedIcon) {
      setUserIcon(storedIcon);
    }
  }, []);

  return (
    <div>
      <Avatar className = {size}>
        {userIcon ? (
          <AvatarImage
            src={userIcon}
            alt={userName}
            onError={() => setUserIcon(null)}
          />
        ) : null}
        <AvatarFallback>
          <UserRoundPen />
        </AvatarFallback>
      </Avatar>
    </div>
  );
};

export default UserIconAvatar;
