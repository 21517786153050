import React from "react";
import { useParams } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import SuggestedVideos from "./SuggestedVideos";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import { PROTECTED_ROUTES } from "../../routes/routes";

const ClassroomPage = () => {
  const { id, subjectid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);
  
  return (
    <div className="flex m-12 gap-2 -mb-[10rem]" style = {{fontFamily : "DM Sans"}}>
      <div className="w-3/4">
        <VideoPlayer id={id} subjectid={subjectid} showPdfBtn = {true}/>
      </div>
      <div>
        <SuggestedVideos id={id} subjectid={subjectid} clickUrl = {PROTECTED_ROUTES?.classroom}/>
      </div>
    </div>
  );
};

export default ClassroomPage;
