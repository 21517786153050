import React from "react";

const PageBanner = ({
  title,
  rightImgSrc,
  backgroundGradient,
  backgroundImage,
  fontColor,
  title2,
  component,
  subtitle,
  backgroundPosition
}) => {
  return (
    <div
      style={{
        backgroundImage: `${backgroundGradient}, url('${backgroundImage}')`,
        backgroundSize: "cover",
        backgroundPosition: backgroundPosition,
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "800",
      }}
      className="lg:h-[20rem] md:h-[12rem] h-[10rem] flex justify-between items-center lg:px-[12rem] px-[1rem]"
    >
      <div style={{ color: fontColor }} className = "lg:w-[32rem] md:w-[25rem] w-[20rem]">
        <div className="lg:text-5xl md:text-2xl text-lg md:ml-5 ml-3">{title}</div>
        {title2 && <div className="lg:text-4xl md:text-2xl text-lg mb-[2rem]">{title2}</div>}
        {subtitle && <div className = "lg:text-sm md:text-[0.7rem] text-[0.5rem] lg:my-[1rem] lg:ml-[1.5rem] md:ml-[1.2rem] ml-[0.8rem]">{subtitle}</div>}
        {component}
      </div>
      <div>
        <img alt="banner-img" src={rightImgSrc} className="lg:h-[20rem] lg:w-[30rem] md:h-[12rem] md:w-[18rem] h-[10rem] w-[25rem] lg:mt-1 mt-0 lg:ml-4 ml-4 md:mb-0.5" loading = "lazy"/>
      </div>
    </div>
  );
};

export default PageBanner;
