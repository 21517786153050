import React from "react";
import { Trash2 } from "lucide-react";

const CartItem = ({ name, price, thumbnail, id, removeFromCart, show }) => {
  return (
    <div className="my-5 flex justify-between md:w-full w-[24rem]">
      <div className="flex gap-5">
        <div>
          <img
            src={thumbnail}
            alt={name}
            className="md:max-w-[12rem] md:max-h-[10rem] max-w-[8rem] rounded-lg shadow-lg"
            loading = "lazy"
          />
        </div>
        <div>
          <div className="mt-3 font-semibold md:text-xl text-[1rem] text-gray-600">{name}</div>

          {show && <div className="mt-5 cursor-pointer" onClick = {() => removeFromCart(id)}>
            <Trash2 />
          </div>}
        </div>
      </div>
      <div className="mt-5 font-semibold md:text-xl text-[1rem]">
       ₹{price}
      </div>
    </div>
  );
};

export default CartItem;
