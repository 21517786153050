import React, { useState, useEffect } from "react";

const TestingPage = () => {

   return (
    <div>
      This is the Testing Page
    </div>
  );
};

export default TestingPage;
