import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import NavbarComponent from "./app_components/Navbar/NavbarComponent";
import { Toaster } from "./components/ui/sonner";
import Footer from "./app_components/Footer/Footer";
import { UNPROTECTED_ROUTES } from "./routes/routes";
import { useEffect } from "react";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Disable right-click on the whole document
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Disable specific key combinations
    const handleKeyDown = (event) => {
      if (
        event.keyCode === 123 || // F12
        (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I (Windows)
        (event.metaKey && event.altKey && event.keyCode === 73) || // Cmd+Option+I (Mac)
        (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J (Windows)
        (event.metaKey && event.altKey && event.keyCode === 74) || // Cmd+Option+J (Mac)
        (event.ctrlKey && event.keyCode === 85) || // Ctrl+U (Windows)
        (event.metaKey && event.keyCode === 85) // Cmd+U (Mac)
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      {location.pathname !== UNPROTECTED_ROUTES?.login &&
        location.pathname !== UNPROTECTED_ROUTES?.studentDetails && (
          <NavbarComponent />
        )}
      <Outlet />
      <Toaster />
      {location.pathname !== UNPROTECTED_ROUTES?.login &&
        location.pathname !== UNPROTECTED_ROUTES?.studentDetails && <Footer />}
    </div>
  );
}

export default App;
