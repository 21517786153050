import CryptoJS from 'crypto-js';

// Function to perform modular exponentiation
export const modExp = (base, exp, mod) => {
    let result = 1;
    base = base % mod;
  
    while (exp > 0) {
      if (exp % 2 === 1) {
        result = (result * base) % mod;
      }
      exp = Math.floor(exp / 2);
      base = (base * base) % mod;
    }
  
    return result;
};

export const decryptUrl = (serverPublicKey, clientPrivateKey, prime, videoUrl) => {
  
  const clientSharedSecret = modExp(
    serverPublicKey,
    clientPrivateKey,
    prime
  );

  // Decrypt the video URL
  return JSON.parse(
    CryptoJS.AES.decrypt(
      videoUrl,
      clientSharedSecret.toString(),
      { format: CryptoJSAesJson }
    ).toString(CryptoJS.enc.Utf8)
  );
};

export const CryptoJSAesJson = {
    stringify: function (cipherParams) {
        const vbJsonString = {
          ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)
        };
        if (cipherParams.iv) {
          vbJsonString['iv'] = cipherParams.iv.toString()
        };
        if (cipherParams.salt) {
          vbJsonString['s'] = cipherParams.salt.toString()
        };
        return JSON.stringify(vbJsonString);
    },
    parse: function (jsonStr) {
        const vbJsonParse = JSON.parse(jsonStr);
        const cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Base64.parse(vbJsonParse.ct)
        });
        if (vbJsonParse.iv) {
          cipherParams['iv'] = CryptoJS.enc.Hex.parse(vbJsonParse.iv)
        }
        if (vbJsonParse['s']) {
          cipherParams.salt = CryptoJS.enc.Hex.parse(vbJsonParse.s)
        }
        return cipherParams;
    }    
}