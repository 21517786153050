import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate }) => {
  // Function to calculate the time left
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate).getTime() - new Date().getTime();

    let timeLeft = {};
    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const months = Math.floor(days / 31);
      const remainingDays = days % 31;

      timeLeft = {
        months,
        days: remainingDays,
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000   
 * 60)) / 1000),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());   


  // Update the countdown every second
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer); // Clear the timer when the component unmounts
  });

  return (
    <div>
      {timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0 ? (
        <div>0d 0h 0m 0s</div>
      ) : (
        <div className="text-red-600">
          {timeLeft.months > 0 ? `${timeLeft.months}m ` : ""}
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;