import React, { useState, useEffect } from "react";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import { formatDate } from "../../utils/util-functions/formatDate";
import CALENDER_ICON from "../../assets/CalendarBlank.png";
import StarRatings from "react-star-ratings";
import CLOCK_ICON from "../../assets/Clock.png";
import { useNavigate } from "react-router-dom";
import { ScrollArea } from "../../components/ui/scroll-area";
import { ChevronsLeft, ChevronsRight } from "lucide-react";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";

const SuggestedVideos = ({ id, subjectid, clickUrl }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [suggestedVideosData, setSuggestedVideosData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchSuggestedVideos = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_CLASSROOM_SUGGESTED_VIDEOS +
          `lecture_id=${id}&subject_id=${subjectid}&limit=10&page=${currentPage}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchSuggestedVideos(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setSuggestedVideosData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("Error fetchSuggestedVideos", err);
    }
  };

  useEffect(() => {
    fetchSuggestedVideos();
  }, [currentPage]);

  function handleClick(id, subject_id = "") {
    if (subject_id === "") {
      navigate(clickUrl + `/${id}`);
    } else {
      navigate(clickUrl + `/${id}/${subject_id}`);
    }
  }

  return (
    <div style={{ fontFamily: "DM Sans" }}>
      <div className="text-2xl mb-5">Related Videos</div>
      <ScrollArea className="w-[26rem] h-[36rem] border border-[#DFE3EC] rounded-2xl p-4 mt-2 shadow-xl">
        {suggestedVideosData?.lectures?.map((ele, i) => (
          <div
            className="bg-[#F8FCFD] border border-[#DFE3EC] rounded-lg p-2 flex gap-4 cursor-pointer items-center my-3"
            onClick={() => handleClick(ele?.id, ele?.subject_id)}
            key = {i}
          >
            {/* Left Section */}
            <div className="w-[8rem]">
              <img src={ele?.thumbnail} alt={ele?.id} className="rounded-lg" loading = "lazy"/>
            </div>
            {/* Right Section */}
            <div className="w-[13rem]">
              <div className="flex items-center">
                <div>
                  <StarRatings
                    rating={Math.floor(ele?.rating)}
                    starRatedColor="#FCB129"
                    numberOfStars={5}
                    starDimension="0.7rem"
                  />
                </div>
                <div className="ml-4 italic text-sm">
                  ({ele?.total_rating} ratings)
                </div>
              </div>
              <div className="font-semibold my-1 text-sm">
                {stripText(stripHtml(ele?.description), 40)}
              </div>
              <div className="flex gap-2 items-center">
                <div className="text-sm flex gap-2 items-center">
                  <div>
                    <img src={CALENDER_ICON} alt="calender-icon" loading = "lazy"/>
                  </div>
                  <div>{formatDate(ele?.created_at)}</div>
                </div>
                <div className="text-sm flex gap-2 items-center">
                  <div>
                    <img src={CLOCK_ICON} alt="calender-icon" loading = "lazy"/>
                  </div>
                  <div>{ele?.video_length}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </ScrollArea>
      <div className="flex justify-between mt-[3rem]">
        <div></div>
        <div className="flex gap-2 items-center mr-2">
          {currentPage > 0 ? (
            <div className="cursor-pointer hover:-translate-x-1 transition">
              <ChevronsLeft
                onClick={() => setCurrentPage((currentPage) => currentPage - 1)}
              />
            </div>
          ) : (
            <ChevronsLeft className="text-gray-300" />
          )}
          <div>
            Page {currentPage + 1} of {totalPages}
          </div>
          {currentPage < totalPages - 1 ? (
            <div className="cursor-pointer hover:translate-x-1 transition">
              <ChevronsRight
                onClick={() => setCurrentPage((currentPage) => currentPage + 1)}
              />
            </div>
          ) : (
            <ChevronsRight className="text-gray-300" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SuggestedVideos;
