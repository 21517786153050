import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";

const useTestDescription = (testId) => {
  const [testDescription, setTestDescription] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchTestDescription = async (retries = 6) => {
    try {
      const requestOptions = {
        method: "GET",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_TEST_PACKAGE_DETAILS_DESCRIPTION.replace(
          /TEST_ID/,
          testId
        ),
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchTestDescription(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setTestDescription(json?.data);
    } catch (err) {
      console.log("Error fetchTestDescription", err);
    }
  };

  useEffect(() => {
    fetchTestDescription();
  }, [testId]);

  return testDescription;
};

export default useTestDescription;
