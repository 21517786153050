import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";

const useLandingPageData = () => {
  const [landingPageData, setLandingPageData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchLandingPageData = async (retries = 6) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_LANDING_PAGE_DATA
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchLandingPageData(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }

      const json = await response.json();
      setLandingPageData(json);
    } catch (err) {
      console.error("Error in useLandingPageData: ", err);
    }
  };

  useEffect(() => {
    fetchLandingPageData();
  }, []);

  return landingPageData;
};

export default useLandingPageData;
