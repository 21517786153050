import React from "react";
import DPPTab from "../../CourseDetail/DPPTab";

const TestDPPTab = ({ testId, isPurchased }) => {
  return (
    <div className="mx-[1rem]">
      <DPPTab
        testPackageId={testId}
        isPurchased={isPurchased}
        showMetaDescription={false}
      />
    </div>
  );
};

export default TestDPPTab;
