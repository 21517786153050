import React from "react";

const VideoDescription = ({ data }) => {
  return (
    <div className="my-6">
      <div className="text-2xl font-bold mb-2">{data?.title}</div>
      <div
        className="custom-html-content space-y-4"
        dangerouslySetInnerHTML={{ __html: data?.description }}
      />
    </div>
  );
};

export default VideoDescription;
