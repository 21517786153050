import React from "react";
import { useLocation } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import {
  PRIVACY_POLICY_HEADING,
  PRIVACY_POLICY_CONSTANTS,
} from "../../constants/privacy_policy";
import {
  REFUND_POLICY_HEADING,
  REFUND_POLICY_CONSTANTS,
} from "../../constants/refund_policy";
import {
  TERMS_AND_CONDITION_HEADING,
  TERMS_AND_CONDITIONS_CONSTANTS,
} from "../../constants/terms_and_conditions";
import LegalPageBody from "./LegalPageBody";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";

const LegalPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);

  return (
    <div>
      {location.pathname === UNPROTECTED_ROUTES?.terms && (
        <LegalPageBody
          heading={TERMS_AND_CONDITION_HEADING}
          body={TERMS_AND_CONDITIONS_CONSTANTS}
        />
      )}
      {location.pathname === UNPROTECTED_ROUTES?.privacy && (
        <LegalPageBody
          heading={PRIVACY_POLICY_HEADING}
          body={PRIVACY_POLICY_CONSTANTS}
        />
      )}
      {location.pathname === UNPROTECTED_ROUTES?.return && (
        <LegalPageBody
          heading={REFUND_POLICY_HEADING}
          body={REFUND_POLICY_CONSTANTS}
        />
      )}
    </div>
  );
};

export default LegalPage;
