import React, { useState } from "react";
import { Document, Page } from "react-pdf";

const PdfComponet = ({ pdf }) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  return (
    <div>
      <div>
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page) => (
              <Page
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={550}
                height={500}
              />
            ))}
        </Document>
      </div>
    </div>
  );
};

export default PdfComponet;
