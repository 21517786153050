import React, { useState } from "react";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_COURSES_AVAILABLE } from "../../constants/text_constants";
import CourseCard from "./CourseCard";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { useNavigate } from "react-router-dom";

const CoursesAvailableSection = ({ data }) => {
  const navigate = useNavigate();

  if (!data || data?.length === 0) return <div></div>;
  const displayCards = data.slice(0, 6);
  
  return (
    <div className="my-[6rem]">
      <SectionHeading
        heading={LANDING_PAGE_COURSES_AVAILABLE?.heading}
        subHeading={LANDING_PAGE_COURSES_AVAILABLE?.subHeading}
        headingColor={"#130C40"}
      />
      <div className="flex flex-col justify-center items-center mt-[1rem] border border-gray-200 p-5 rounded-xl shadow-2xl shadow-gray-300">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[2rem]">
          {displayCards.map((ele, i) => (
            <CourseCard id={ele?.id} key = {i} {...ele} />
          ))}
        </div>
        {data?.length > 2 && (
        <div className="flex justify-center items-center mt-16">
          <button
            className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all"
            onClick={() => {navigate(UNPROTECTED_ROUTES.courses)}}
          >
            View All Courses
          </button>
        </div>
      )}
      </div>
      
    </div>
  );
};

export default CoursesAvailableSection;
