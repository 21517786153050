import React, { useState, useEffect } from "react";
import PageBanner from "../PageBanner/PageBanner";
import { FREE_LIBRARY_VIDOES_BANNER } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import TabHeadings from "./TabHeadings";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { setFreeLibraryVideosCache } from "../../configs/cache";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import { Card, CardContent } from "../../components/ui/card";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import StarRatings from "react-star-ratings";
import { formatDate } from "../../utils/util-functions/formatDate";
import { Calendar, Clock } from "lucide-react";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { toast } from "sonner";
import IntegratedPagination from "../Pagination/IntegratedPagination";

const FreeLibraryVideos = () => {
  const [libraryVideosData, setLibraryVideosData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const cache = useSelector((store) => store.cache.freeLibraryVideosCache);
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);

  const fetchFreeLibraryVidoes = async (retries = 6) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_FREE_LIBRARY_VIDEOS + currentPage
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchFreeLibraryVidoes(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      setLibraryVideosData(json?.data);
      dispatch(setFreeLibraryVideosCache([json?.data, currentPage]));
    } catch (err) {
      console.error("Error fetchFreeLibraryVidoes : ", err);
    }
  };

  useEffect(() => {
    if (cache[currentPage]) {
      setLibraryVideosData(cache[currentPage]);
      setTotalPages(Math.ceil(cache[currentPage]?.total / PAGE_LIMIT));
    } else {
      fetchFreeLibraryVidoes();
    }
  }, [currentPage]);

  function handleClick(id) {
    const userAuthenticated = localStorage.getItem("authenticated") === "1";
    if (!userAuthenticated) {
      toast("Please login to view this video 🥲");
    } else {
      navigate(UNPROTECTED_ROUTES?.freeLibraryVideosPlay + `/${id}`);
    }
  }

  return (
    <div className="overflow-hidden">
      <PageBanner
        title={FREE_LIBRARY_VIDOES_BANNER?.title1}
        subtitle={FREE_LIBRARY_VIDOES_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />

      <div className="lg:mx-[10rem] md:mx-[3rem] mx-[1rem]">
        <TabHeadings
          index={0}
          text={"Total Videos"}
          total={libraryVideosData?.total}
        />
      </div>
      <div>
        {(!libraryVideosData || libraryVideosData?.videos?.length === 0) && (
          <LoadingLottie />
        )}
      </div>
      <div className="mt-10 grid md:grid-cols-2 grid-cols-1 lg:mx-[10rem] mx-[1rem] lg:ml-[12rem] md:ml-[3.5rem] ml-[1rem] gap-5">
        {libraryVideosData?.videos.map((ele, i) => (
          <div key={i}>
            <Card className="lg:w-[27rem] md:w-[20rem] w-[22rem] shadow-xl">
              <CardContent
                style={{ fontFamily: "DM Sans" }}
                className="p-0"
                onClick={() => handleClick(ele?.id)}
              >
                <div className="flex gap-1 pl-2">
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={ele?.thumbnail}
                      alt={ele?.title}
                      className="lg:w-[15rem] lg:h-[8rem] w-[10rem] h-[6rem] rounded-lg"
                      loading="lazy"
                    />
                  </div>
                  <div className="lg:p-3 p-2 flex flex-col text-sm">
                    <div className="lg:text-[1rem] text-xs font-bold">
                      {ele?.title}
                    </div>
                    <div className="lg:text-sm text-xs">
                      {stripText(stripHtml(ele?.description))}
                    </div>
                    <div>
                      <StarRatings
                        rating={Math.floor(ele?.rating)}
                        starRatedColor="#FCB129"
                        numberOfStars={5}
                        starDimension="0.8rem"
                      />
                    </div>
                    <div className="italic lg:text-sm text-xs my-1">
                      ({ele?.total_rating} Ratings)
                    </div>
                    <div className="text-xs flex items-center gap-2 my-1">
                      <Calendar className="w-[1.2rem]" />
                      {formatDate(ele?.created_at)}
                    </div>
                    <div className="flex justify-between">
                      <div className="text-xs flex items-center gap-2">
                        <Clock className="w-[1.2rem]" />
                        {ele?.video_length}
                      </div>
                      <div className="">
                        <button
                          className="border-2 border-[#5A277C] md:px-2 md:py-1 px-2 py-0.5 rounded-xl hover:bg-[#5A277C] hover:text-white"
                          onClick={() => handleClick(ele?.id)}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      <div className="mt-[5rem]">
        <IntegratedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default FreeLibraryVideos;
