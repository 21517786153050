import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useTestPackageHeaders from "../../utils/use-TestPackageHeaders";
import TestDescriptionBanner from "../PageBanner/TestDescriptionBanner";
import TabHeadings from "./TabHeadings";
import DescriptionTab from "./DescriptionTab";
import SolutionTab from "./SolutionTab";
import TestAnnouncementTab from "./TestAnnouncementTab";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import TestSeriesTab from "../CourseDetail/TestSeriesTab";
import DPPTab from "../CourseDetail/DPPTab";
import DetailsResponsive from "../DetailResponsive/DetailsResponsive";
import { ITEM_TEST_PACKAGE } from "../../constants/text_constants";

const TestDetails = () => {
  const { testId } = useParams();
  const testPackageHeaders = useTestPackageHeaders(testId);
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);
  const isPurchased = testPackageHeaders?.details[0]?.is_purchased;

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (innerWidth <= 450) {
    return (
      <DetailsResponsive
        headerDetails={testPackageHeaders}
        item={ITEM_TEST_PACKAGE}
        isPurchased={isPurchased}
        itemId={testId}
      />
    );
  }

  return (
    <div className="-mb-[5rem] overflow-hidden">
      <TestDescriptionBanner
        testHeaders={testPackageHeaders}
        isPurchased={isPurchased}
      />
      <TabHeadings index={index} setIndex={setIndex} />
      <div
        className="mx-[5rem] my-[2rem] w-[52rem]"
        style={{ fontFamily: "DM Sans" }}
      >
        {index === 0 && <DescriptionTab testId={testId} />}
        {index === 1 && (
          <TestSeriesTab
            isPurchased={isPurchased}
            testPackageId={testId}
            showMetaDescription={true}
          />
        )}
        {index === 2 && (
          <DPPTab
            isPurchased={isPurchased}
            testPackageId={testId}
            showMetaDescription={true}
          />
        )}
        {index === 3 && (
          <SolutionTab isPurchased={isPurchased} testPackageId={testId} />
        )}
        {index === 4 && <TestAnnouncementTab testId={testId} />}
      </div>
    </div>
  );
};

export default TestDetails;
