import React from "react";
import Courses from "./Courses";
import { Separator } from "../../../components/ui/separator";
import Tests from "./Tests";
import Books from "./Books";
import Library from "./Library";
import LOGOUT_ICON from "../../../assets/LOGOUT_ICON.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../../utils/userLogout";

const UnCollapsedSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleLogout() {
    userLogout(navigate, dispatch);
  }
  return (
    <div
      className="sticky top-[6rem] w-[10rem] mt-2 h-screen lg:block md:hidden hidden mx-[0.5rem] text-[0.9rem]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex flex-col gap-[5rem]">
        <div>
          <Courses />
          <Separator className="my-[1rem]" />
          <Tests />
          <Separator className="my-[1rem]" />
          <Books />
          <Separator className="my-[1rem]" />
          <Library />
        </div>
        {localStorage.getItem("authenticated") === "1" && (
          <div onClick={handleLogout} className = "flex items-center gap-2 absolute top-[38rem]">
            <img
              src={LOGOUT_ICON}
              alt="LOGOUT_ICON"
              className="w-[3rem] cursor-pointer"
            />
            <p className = "text-red-400 font-bold">Logout</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UnCollapsedSidebar;
