import React from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

const SelectField = ({ text, value, setValue, data, labelText }) => {
  return (
    <div>
      <div
        style={{
          fontFamily: "DM Sans",
          color: "#3F3D4B",
          fontWeight: "600",
          fontStyle: "normal",
          lineHeight: "normal",
        }}
        className="mb-2"
      >
        {text}
      </div>
      <Select value={value} onValueChange={(value) => setValue(value)}>
        <SelectTrigger className="md:w-[180px] w-[120px]">
          <SelectValue placeholder={"Select " + text} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{labelText}</SelectLabel>
            {data?.map((d, i) => (
              <SelectItem value={d} key={i}>
                {d.name}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

export default SelectField;
