import React from 'react'
import LoginPageRightHeader from '../Login/LoginPageRightHeader'
import StudentDetailsForm from './StudentDetailsForm'

const StudentDetailsRight = () => {
  return (
    <div>
      <LoginPageRightHeader/>
      <StudentDetailsForm showTitle = {true} btnText = {"Submit"}/>
    </div>
  )
}

export default StudentDetailsRight
