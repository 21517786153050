import React, { useState } from "react";
import { EllipsisVertical, UserRoundPen } from "lucide-react";
import { formatDate } from "../../utils/util-functions/formatDate";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";

const UserComment = ({
  attachment,
  comment,
  student_name,
  thumbnail,
  created_at,
  replies,
  user_id,
  showButtons,
  showAttachment,
  id,
  deleteComment,
  submitReply,
  setEditCommentData,
  setDoubtText
}) => {
  const [viewReplies, setViewReplies] = useState(false);
  const [reply, setReply] = useState(false);
  const [replyText, setReplyText] = useState("");
  const userId = JSON.parse(localStorage.getItem("userDetail"))?.userId;

  function handleDelete() {
    deleteComment(id);
  }

  function handleReplySubmit(){
    submitReply(id, replyText, "reply");
    setReply(false);
    setReplyText("");
  }

  function handleEdit() {
    const selectedCommentData = {
      id, comment
    }
    setDoubtText(comment)
    setEditCommentData(selectedCommentData);
  }

  return (
    <div>
      <div className="my-2 flex gap-2">
        <div>
          <Avatar>
            <AvatarImage src={thumbnail} alt={student_name} />
            <AvatarFallback>
              <UserRoundPen />
            </AvatarFallback>
          </Avatar>
        </div>
        <div>
          <div className="flex justify-between w-[32rem]">
            <div className="flex gap-2 items-center">
              <div className="font-semibold text-black">{student_name}</div>
              <div className="text-xs italic">{formatDate(created_at)}</div>
            </div>
            {user_id === userId && (
              <div>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <EllipsisVertical className="text-sm" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuLabel>Actions</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={handleEdit}>
                      Edit
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleDelete}>
                      Delete
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
          </div>
          <div className="my-1 w-[29rem]">{comment}</div>
          {showAttachment && attachment !== "" && <img src = {attachment} alt={student_name} className = "max-w-[28rem]" loading = "lazy"/>}
          {showButtons && (
            <div className="flex gap-4 mt-1.5 text-[#5A277C]">
              {!viewReplies && (
                <button onClick={() => setViewReplies(true)}>
                  View({replies?.length})
                </button>
              )}
              {viewReplies && (
                <button onClick={() => setViewReplies(false)}>
                  Close({replies?.length})
                </button>
              )}

              {!reply && <button onClick={() => setReply(true)}>Reply</button>}
              {reply && (
                <button
                  onClick={() => {
                    setReplyText("");
                    setReply(false);
                  }}
                >
                  Close Reply
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <div>
        {viewReplies &&
          replies?.length > 0 &&
          replies?.map((ele, i) => (
            <div className="ml-10" key = {i}>
              <UserComment {...ele} key={ele?.id} showButtons={false} showAttachment={false}/>
            </div>
          ))}
      </div>
      <div>
        {reply && (
          <div className="ml-12 flex gap-4 items-center">
            <div>
              <input
                type="text"
                placeholder={`Reply to ${student_name} ...`}
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
                className="w-[20rem] p-2 outline-none border-b border-[#5A277C] bg-[#F8FCFD]"
              />
            </div>
            <div>
              <button className="px-2 py-1 bg-[#5A277C] rounded-lg text-white hover:shadow-xl" onClick = {handleReplySubmit}>
                Reply
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserComment;
