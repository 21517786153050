import React, { useState } from "react";
import useCourseSubjects from "../../../utils/use-CourseSubjects";
import { COURSE_DETAILS_CLASSROOM_HEADING } from "../../../constants/text_constants";
import InValidLottie from "../../Lottie-Components/InValidLottie";
import CourseSubjectsDropdown from "./CourseSubjectsDropdown";
import ClassroomTabLectures from "../../CourseDetail/ClassroomTabLectures";
import ClassroomTabNotes from "../../CourseDetail/ClassroomTabNotes";

const CourseClassroomTab = ({ courseId, isPurchased }) => {
  const courseSubjects = useCourseSubjects(courseId);
  const [subjectId, setSubjectId] = useState(null);
  const [showLecture, setShowLecture] = useState(true);
  const userAuthenticated = localStorage.getItem("authenticated") === "1";

  if (!userAuthenticated) {
    return (
      <div>
        <div className="lg:text-2xl text-lg font-semibold my-6">
          {COURSE_DETAILS_CLASSROOM_HEADING}
        </div>
        <div>
          <InValidLottie text={"You Need to Login to view Classroom"} />
        </div>
      </div>
    );
  }

  return (
    <div className="mx-[1.3rem]">
      <div className="lg:text-2xl text-lg font-semibold my-6">
        {COURSE_DETAILS_CLASSROOM_HEADING}
      </div>

      <div>
        {/* Section 1 - Course DropDown*/}
        <div>
          <CourseSubjectsDropdown
            courseSubjects={courseSubjects}
            setSubjectId={setSubjectId}
            defaultSubjectId={courseSubjects?.subjects[0]?.id}
            subjectId={subjectId}
          />
        </div>
        {/* Section 2 - Default view when no Subject Id is selected*/}
        {!subjectId && (
          <div className="my-6 text-center">
            Select Subject to View{" "}
            <span className="font-semibold text-[#01B2C2] underline underline-offset-4">
              Lectures
            </span>{" "}
            and{" "}
            <span className="font-semibold text-[#01B2C2] underline underline-offset-4">
              Notes
            </span>
          </div>
        )}
        {/* Section 3 - SubjectId is selected and Tabs View */}
        {subjectId && (
          <div className="flex justify-between items-center bg-[#F8FCFD] border border-[#DFE3EC] px-4 py-3 rounded-t-xl font-semibold mt-[1rem]">
            <div
              onClick={() => setShowLecture(true)}
              className={showLecture ? "text-[#03B3C3]" : ""}
            >
              Lectures
            </div>
            <div className="text-[#DFE3EC] text-2xl"> | </div>
            <div
              onClick={() => setShowLecture(false)}
              className={!showLecture ? "text-[#03B3C3]" : ""}
            >
              Notes
            </div>
          </div>
        )}
        {/* Section 4 - SubjectId is Selected and Lectures View */}
        {subjectId && showLecture && (
            <ClassroomTabLectures
            subjectId={subjectId?.id}
            defaultSubjectId={courseSubjects?.subjects[0]?.id}
            isPurchased={isPurchased}
          />
        )}
        {subjectId && !showLecture && (
            <ClassroomTabNotes
            subjectId={subjectId?.id}
            defaultSubjectId={courseSubjects?.subjects[0]?.id}
            isPurchased={isPurchased}
          />
        )}
      </div>
    </div>
  );
};

export default CourseClassroomTab;
