import React from "react";
import IPHONE_MOCKUP from "../../assets/iphoneMockup.png";
import TOP_FOOTER_BG from "../../assets/BgOverlayImage.jpeg";
import SectionHeading from "../LandingPage/SectionHeading";
import { TOP_FOOTER } from "../../constants/text_constants";
import ANDROID_QR from "../../assets/androidQr.png";
import PLAYSTORE_BANNER from "../../assets/playStoreLink.png";
import { Link } from "react-router-dom";

const FooterTop = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, rgba(34, 211, 238, 0.8), rgba(147, 51, 234, 0.8)), url('${TOP_FOOTER_BG}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        fontFamily: "DM Sans",
      }}
      className="lg:rounded-s-full lg:rounded-e-full flex justify-center items-center lg:gap-[10rem] md:gap-[4rem] gap-0 md:h-[23rem] h-[19rem] lg:mx-10 mx-5 rounded-3xl"
    >
      <div>
        <img
          alt="android-qr-code"
          src={ANDROID_QR}
          className="h-[10rem] rounded-lg hidden lg:block md:block md:h-[7rem]"
          loading = "lazy"
        />
      </div>
      <div>
        <img
          src={IPHONE_MOCKUP}
          alt="iphone-mockup"
          className="lg:h-[30rem] relative lg:-top-20 h-[18rem] -top-1 -left-4 md:h-[28rem]"
          loading = "lazy"
        />
      </div>
      <div>
        <SectionHeading heading={TOP_FOOTER?.heading} />
        <div className="text-white lg:w-[20rem] md:w-[15rem] lg:text-lg md:text-sm w-[10rem] text-xs">{TOP_FOOTER?.subHeading}</div>
        <Link to={TOP_FOOTER?.playStoreLink} target="_blank">
          <img
            src={PLAYSTORE_BANNER}
            alt="rank-plus-playstore"
            className="lg:h-12 md:h-11 lg:mt-5 h-9 mt-4"
            loading = "lazy"
          />
        </Link>
      </div>
    </div>
  );
};

export default FooterTop;
