import React, { useState, useEffect } from "react";
import DetailsHeading from "./DetailsHeading";
import HorizontalScrollTab from "./HorizontalScroll";
import DescriptionTab from "./DescriptionTab";
import {
  ITEM_COURSE,
  ITEM_TEST_PACKAGE,
  ITEM_BOOK,
} from "../../constants/text_constants";
import AnnouncementTab from "../CourseDetail/AnnouncementTab";
import TestAnnouncementTab from "../TestDetails/TestAnnouncementTab";
import CourseDPPTab from "./course/CourseDPPTab";
import CourseLiveTestsTab from "./course/CourseLiveTestsTab";
import TestDPPTab from "./test/TestDPPTab";
import TestLiveTestTab from "./test/TestLiveTestTab";
import TestSolutionTab from "./test/TestSolutionTab";
import CoursesLiveClassTab from "./course/CoursesLiveClassTab";
import CourseClassroomTab from "./course/CourseClassroomTab";

const DetailsResponsive = ({ headerDetails, item, isPurchased, itemId, currentTab =  "Description" }) => {
  const [tabHeading, setTabHeading] = useState(currentTab);

  // Use useEffect to update state when currentTab prop changes
  useEffect(() => {
    setTabHeading(currentTab);
  }, [currentTab]); // This will run when currentTab changes

  return (
    <div className="grid grid-cols-1 gap-y-[1rem] pt-[1rem]">
      <DetailsHeading title={headerDetails?.details?.[0]?.name} />
      <HorizontalScrollTab
        item={item}
        setTabHeading={setTabHeading}
        tabHeading={tabHeading}
        itemId={itemId}
      />
      {tabHeading === "Description" && (
        <DescriptionTab
          item={item}
          headerDetails={headerDetails}
          itemId={itemId}
        />
      )}

      {/* courses */}
      {tabHeading === "Live Class" && item === ITEM_COURSE && (
        <div>
          <CoursesLiveClassTab courseId={itemId} isPurchased={isPurchased} />
        </div>
      )}
      {tabHeading === "Classroom" && item === ITEM_COURSE && (
        <div>
          <CourseClassroomTab courseId={itemId} isPurchased={isPurchased} />
        </div>
      )}
      {tabHeading === "Live Tests" && item === ITEM_COURSE && (
        <div>
          <CourseLiveTestsTab courseId={itemId} isPurchased={isPurchased} />
        </div>
      )}
      {tabHeading === "DPP" && item === ITEM_COURSE && (
        <div>
          <CourseDPPTab courseId={itemId} isPurchased={isPurchased} />
        </div>
      )}
      {tabHeading === "Announcement" && item === ITEM_COURSE && (
        <div className="mx-[1rem] -mb-[4rem]">
          <AnnouncementTab courseId={itemId} />
        </div>
      )}
      {/* Test Package */}
      {tabHeading === "Live Test" && item === ITEM_TEST_PACKAGE && (
        <div>
          <TestLiveTestTab testId={itemId} isPurchased={isPurchased} />
        </div>
      )}
      {tabHeading === "DPP" && item === ITEM_TEST_PACKAGE && (
        <div>
          <TestDPPTab testId={itemId} isPurchased={isPurchased} />
        </div>
      )}
      {tabHeading === "Solution" && (
        <div>
          <TestSolutionTab testId={itemId} isPurchased={isPurchased} />
        </div>
      )}
      {tabHeading === "Announcement" && item === ITEM_TEST_PACKAGE && (
        <div className="mx-[1rem] -mb-[4rem]">
          <TestAnnouncementTab testId={itemId} />
        </div>
      )}
    </div>
  );
};

export default DetailsResponsive;
