
export const TERMS_AND_CONDITION_HEADING = "TERMS & CONDITIONS"

export const TERMS_AND_CONDITIONS_CONSTANTS = [
  {
    title: "COMMUNIACATION",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "By using our service, you agree to subscribe to our courses and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at quiresrankplus@gmail.com or via what's app +91 8235067766.",
      },
    ],
  },
  {
    title: "PURCHASE",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "If you wish to purchase any product or service made available through Service, you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card, your billing address, and your shipping information.",
      },
      {
        title: null,
        point:
          "You represent and warrant that: (i) you have the legal right to use any card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.",
      },
      {
        title: null,
        point:
          "We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.",
      },
      {
        title: null,
        point:
          "We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.",
      },
    ],
  },
  {
    title: "SUBSCRIPTION",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          'Parts of service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles will be set depending on the type of subscription plan you select when purchasing a Subscription.',
      },
      {
        title: null,
        point:
          "At the end of each Billing Cycle, your Subscription will automatically/manually renew under the exact same conditions unless you cancel it or RanKplus cancels it.",
      },
      {
        title: null,
        point:
          "You may cancel your Subscription renewal either through your online account management page or by contacting quiresrankplus@gmail.com or WhatsApp : +91 8235067766 customer support team.",
      },
      {
        title: null,
        point:
          "A valid payment method is required to process the payment for your subscription. You shall provide RanKplus with accurate and complete billing information that may include but not limited to full name, address, state, postal or zip code, telephone number, and a valid payment method information. Should automatic billing fail to occur for any reason, RanKplus reserves the right to terminate your access to the Service with immediate effect.",
      },
    ],
  },
  {
    title: "FEE CHANGES",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "RanKplus, in its sole discretion and at any time, may modify subscription fees for the services. Any subscription fee change will become effective at the end of the then-current billing cycle.",
      },
      {
        title: null,
        point:
          "RanKplus will provide you with a reasonable prior notice of any change in subscription fees to give you an opportunity to terminate your subscription before such change becomes effective.",
      },
      {
        title: null,
        point:
          "Your continued use of service after subscription fee change comes into effect constitutes your agreement to pay the modified subscription fee amount.",
      },
    ],
  },
  {
    title: "RETURN & REFUND POLICY",
    subtitle: null,
    points: [
      {
        title: "Return Policy",
        point:
          " There is no any provision for Return , Because its a Digital Product and Once Purchased, it can not be returned. So before purchase any service/course kindly read all information given in description of that course/service carefully.",
      },
      {
        title: "Shipping Policy",
        point:
          "There is no any provision for Shiping , Because its a Digital Product and Once Purchased, you will get the access of the Content through Application Only.",
      },
      {
        title: "Refund Policy",
        point:
          "If you have made double payment of the same product mistakenly or because of the System Glitch, then Only Refund Option is Available and not any other any Conditions. Please write us on rankplusofficial@gmail.com with the Concerned Issue.",
      },
    ],
  },
  {
    title: "ACCOUNT",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "When you create an account with us, you guarantee that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on service.",
      },
      {
        title: null,
        point:
          "You are responsible for maintaining the confidentiality of your account and password, including limited to the restriction of access to your account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.",
      },
      {
        title: null,
        point:
          "You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.",
      },
      {
        title: null,
        point:
          "We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.",
      },
    ],
  },
  {
    title: "ERROR REPORTING & FEEDBACK",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "You may provide us either directly at quiresrankplus@gmail.com or via What’s App +91 8235067766 with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”).",
      },
    ],
  },
  {
    title: "OWNERSHIP OF RIGHTS",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "All rights, including copyright, in this application, and YouTube channel are owned by or licensed by us. Any use of this Application/YouTube channel contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use is prohibited without our permission.",
      },
      {
        title: null,
        point:
          "You may not modify, distribute or re-post something on this Application/YouTube channel foe any purpose. You acknowledge that you do not acquire any ownership rights by downloading copyrighted material.",
      },
    ],
  },
  {
    title: "INTELLECTUAL PROPERTY",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "Service and its original content, features and functionality are and will remain the exclusive property of RanKplus and its licensors. Service is protected by copyright, trademark, and other laws of India.",
      },
      {
        title: null,
        point:
          "Our trademarks may not be used in connection with any product or service without the prior written consent of RanKplus",
      },
    ],
  },
  {
    title: "GOVERNING LAW",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "These Terms shall be governed and construed in accordance with the laws of India, which governing law applies to agreement without regard to its conflict of law provisions.",
      },
      {
        title: null,
        point:
          "Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our service and supersede and replace any prior agreements we might have had between us regarding service.",
      },
    ],
  },
  {
    title: "CHANGES TO SERVICE",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.",
      },
    ],
  },
  {
    title: "AMENDMENTS TO TERMS",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.",
      },
      {
        title: null,
        point:
          "Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.",
      },
      {
        title: null,
        point:
          "By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use service.",
      },
    ],
  },
  {
    title: "ACKNOWLEDGEMENT",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "By using service or other services provided by us, you acknowledge that you have read these terms of service and agree to be bound by them.",
      },
    ],
  },
  {
    title: "CONTACT US",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "Kindly send your feedback, quires or requests for any technical support by email: quiresrankplus@gmail.com or by what's app +918235067766.",
      },
      {
        title: null,
        point:
          "For delete your account send request by email: quiresrankplus@gmail.com or by what's app +918235067766, our team will reach out to you and will delete your account upon your confirmation within 48hrs in working days.",
      },
    ],
  },
];
