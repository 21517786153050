import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";

const PdfComponent = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    const fetchPdf = async () => {
      try {
        console.log("Fetching PDF from URL:", pdf);
        const response = await fetch(pdf, { method: "GET" });

        if (!response.ok) {
          throw new Error(`Failed to fetch PDF. Status: ${response.status}`);
        }

        const blob = await response.blob();
        console.log("PDF fetched successfully");
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
      } catch (err) {
        console.error("Error fetching PDF:", err);
        setError("Failed to load PDF. Please try again later.");
      }
    };

    fetchPdf();

    return () => {
      window.removeEventListener("resize", handleResize);
      if (pdfBlobUrl) {
        console.log("Revoking blob URL");
        URL.revokeObjectURL(pdfBlobUrl);
      }
    };
  }, [pdf]);

  function onDocumentLoadSuccess({ numPages }) {
    console.log("PDF loaded successfully, total pages:", numPages);
    setNumPages(numPages);
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      {pdfBlobUrl ? (
        <div>
          <Document file={pdfBlobUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from({ length: numPages }, (_, i) => (
              <Page
                key={`page_${i + 1}`}
                pageNumber={i + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={innerWidth <= 500 ? 300 : 550}
                height={500}
              />
            ))}
          </Document>
        </div>
      ) : (
        <div>Loading PDF...</div>
      )}
    </div>
  );
};

export default PdfComponent;