import React from "react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../../components/ui/input-otp";

const OTPField = ({otp, setOtp}) => {
  return (
    <div className = "my-5 lg:flex lg:flex-row lg:items-center lg:gap-5 flex flex-col justify-center items-center gap-4">
      <div className = "block text-left font-medium text-gray-700 lg:mb-2 -mb-2">OTP</div>
      <InputOTP maxLength={6} value = {otp} onChange = {(value) => setOtp(value)}>
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
    </div>
  );
};

export default OTPField;
