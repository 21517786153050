import React from "react";
import LoginBanner from "../../assets/LoginBanner.png";

const LoginPageLeft = () => {
  return (
    <div>
      <img
        alt="login-banner"
        src={LoginBanner}
        className="lg:h-[47rem] rounded-2xl hidden lg:block"
        loading="lazy"
      />
    </div>
  );
};

export default LoginPageLeft;
