import React, { useState, useEffect } from "react";
import PageBanner from "../PageBanner/PageBanner";
import { FREE_LIBRARY_NOTES_BANNER } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import TabHeadings from "./TabHeadings";
import { useDispatch, useSelector } from "react-redux";
import { setFreeLibraryNotesCache } from "../../configs/cache";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import PDFIcon from "../../assets/PDF_ICON_2.png";
import { Card, CardContent } from "../../components/ui/card";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import { modExp } from "../../utils/util-functions/cryptoUtils";
import PDFDialogButton from "../PDF-View-Component/PDFDialogButton";
import DecodePdfUrl from "../../utils/util-functions/DecryptionUtil";
import IntegratedPagination from "../Pagination/IntegratedPagination";
import { decryptUrl } from "../../utils/util-functions/cryptoUtils";

const FreeLibraryNotes = () => {
  const [libraryNotesData, setLibraryNotesData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const cache = useSelector((store) => store.cache.freeLibraryNotesCache);
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);
  const [encryptionData, setEncryptionData] = useState(null);

  const setPubPvtKey = () => {
    const prime = 23;
    const base = 5;

    const clientPrivateKey = Math.floor(Math.random() * 100);
    const clientPublicKey = modExp(base, clientPrivateKey, prime);

    const cryptoData = {
      clientPrivateKey: clientPrivateKey,
      clientPublicKey: clientPublicKey,
      prime: prime,
    };

    setEncryptionData(cryptoData);
  };

  const fetchFreeLibraryNotes = async (retries = 6) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_FREE_LIBRARY_NOTES +
          currentPage +
          `/?publicKey=${encryptionData?.clientPublicKey}`
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchFreeLibraryNotes(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setTotalPages(Math.ceil(json?.data?.total / parseInt(json?.data?.limit)));
      setLibraryNotesData(json?.data);
      dispatch(setFreeLibraryNotesCache([json?.data, currentPage]));
    } catch (err) {
      console.error("Error fetchFreeLibraryNotes", err);
    }
  };

  useEffect(() => {
    // if (cache[currentPage]) {
    //   setLibraryNotesData(cache[currentPage]);
    //   setTotalPages(Math.ceil(cache[currentPage]?.total / 12));
    // } else if(encryptionData){
      
    // }
    fetchFreeLibraryNotes();
  }, [encryptionData, currentPage]);

  useEffect(() => setPubPvtKey(), []);

  return (
    <div className="overflow-hidden">
      <PageBanner
        title={FREE_LIBRARY_NOTES_BANNER?.title1}
        subtitle={FREE_LIBRARY_NOTES_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className="lg:mx-[10rem] md:mx-[3rem] mx-[1rem]">
        <TabHeadings
          index={1}
          text={"Total Notes"}
          total={libraryNotesData?.total}
        />
      </div>
      <div>
        {(!libraryNotesData || libraryNotesData?.notes?.length === 0) && (
          <LoadingLottie />
        )}
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mx-[10rem] md:mx-[3rem] mx-[1rem] lg:gap-8 gap-4 mt-10">
        {libraryNotesData?.notes.map((ele, i) => (
          <div key={i}>
            <Card className="shadow-xl rounded-2xl">
              <CardContent style={{ fontFamily: "DM Sans" }} className="p-2">
                <div className="flex lg:gap-8 gap-4">
                  <div>
                    <img alt={ele?.title} src={PDFIcon} className="lg:w-[5rem] w-[4rem] opacity-80" loading = "lazy" />
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="lg:text-xl text-sm font-bold">{ele?.title}</div>
                    <div>
                      <PDFDialogButton
                        BtnStyle={
                          "border-2 border-[#5A277C] lg:px-2 lg:py-1 px-1 py-0.3 rounded-xl hover:bg-[#5A277C]"
                        }
                        BtnText={"View"}
                        BtnTextStyle={"text-[#5A277C] hover:text-white"}
                        pdfUrl={DecodePdfUrl(
                          libraryNotesData,
                          ele?.note_pdf,
                          encryptionData
                        )}
                        // pdfUrl={
                        //   decryptUrl(
                        //     libraryNotesData?.publicKey,
                        //     encryptionData?.clientPrivateKey,
                        //     encryptionData?.prime,
                        //     ele?.note_pdf
                        //   )
                        // }
                        DownloadFileName={ele?.title}
                        showIcon={false}
                        DownloadIcon={""}
                        ShowBtnText={true}
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      <div className="mt-[5rem]">
        <IntegratedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default FreeLibraryNotes;
