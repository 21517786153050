import React, { useState } from "react";

const StarRating = ({ rating }) => {
  
  return (
    <div className = "flex lg:gap-[0.6rem] md:gap-[0.5rem] gap-[0.4rem]">
      {[1, 2, 3, 4, 5].map((num, i) => (
        <div
          key={num}
          className={
            num <= rating
              ? "text-yellow-400 cursor-pointer"
              : "cursor-pointer"
          }
        >
          &#9733;
        </div>
      ))}
    </div>
  );
};

export default StarRating;
