import React, { useState, useEffect } from "react";
import {
  BOOK_DETAILS_SOLUTION_HEADING,
  PAGE_LIMIT,
} from "../../constants/text_constants";
import InValidLottie from "../Lottie-Components/InValidLottie";
import { Lock } from "lucide-react";
import { toast } from "sonner";
import PDF_ICON from "../../assets/FilePdf.png";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import Pagination from "../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import { modExp } from "../../utils/util-functions/encryptionUtils";
import DecodePdfUrl from "../../utils/util-functions/DecryptionUtil";
import PDFDialogButton from "../PDF-View-Component/PDFDialogButton";

const SolutionTab = ({ isPurchased, testPackageId }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [solutionsData, setSolutionsData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [encryptionData, setEncryptionData] = useState(null);

  const setPubPvtKey = () => {
    const prime = 23;
    const base = 5;

    const clientPrivateKey = Math.floor(Math.random() * 100);
    const clientPublicKey = modExp(base, clientPrivateKey, prime);

    const cryptoData = {
      clientPrivateKey: clientPrivateKey,
      clientPublicKey: clientPublicKey,
      prime: prime,
    };

    setEncryptionData(cryptoData);
  };

  const fetchSolutions = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_TEST_DETAILS_SOLUTION +
          `page=${currentPage}&test_package_id=${testPackageId}` +
          `&publicKey=${encryptionData.clientPublicKey}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchSolutions(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setSolutionsData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("fetchSolutions error", err);
    }
  };

  useEffect(() => {
    fetchSolutions();
  }, [encryptionData]);

  useEffect(() => setPubPvtKey(), [currentPage, testPackageId]);

  const userAuthenticated = localStorage.getItem("authenticated") === "1";
  if (!userAuthenticated) {
    return (
      <div>
        <div className="text-2xl font-semibold my-6">
          {BOOK_DETAILS_SOLUTION_HEADING}
        </div>
        <div>
          <InValidLottie text={"You Need to Login to view Solutions"} />
        </div>
      </div>
    );
  }

  if (solutionsData?.total === 0) {
    return (
      <div>
        <div className="text-2xl font-semibold my-6">
          {BOOK_DETAILS_SOLUTION_HEADING}
        </div>
        <div>
          <InValidLottie text={"No Solutions as of now !"} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="text-2xl font-semibold my-6">
        {BOOK_DETAILS_SOLUTION_HEADING}
      </div>
      <div>
        {solutionsData?.solutions?.map((ele, i) => (
          <div
            key={i}
            className="bg-[#F8FCFD] border border-[#DFE3EC] rounded-lg p-4 my-4 flex items-center justify-between shadow-lg"
          >
            {/* left section */}
            <div className="flex items-center gap-2">
              <div className="bg-white p-2 rounded-full">
                <img src={PDF_ICON} alt="pdf icon" loading = "lazy"/>
              </div>
              <div className="w-[17rem] font-semibold">{ele?.title}</div>
            </div>
            {/* Right Section */}
            {solutionsData?.is_package_purchased && (
              <div>
                <PDFDialogButton
                  BtnStyle={"bg-[#03B3C3] p-2 rounded-full cursor-pointer"}
                  BtnText={""}
                  BtnTextStyle={""}
                  ShowBtnText={false}
                  pdfUrl={DecodePdfUrl(
                    solutionsData,
                    ele?.attachment,
                    encryptionData
                  )}
                  DownloadFileName={ele?.title}
                  showIcon={true}
                  DownloadIcon={DOWNLOAD_ICON}
                  DownloadIconStyle=""
                />
              </div>
            )}
            {!solutionsData?.is_package_purchased && (
              <div
                className="bg-[#5A277C] p-2 rounded-full cursor-pointer"
                onClick={() =>
                  toast("To access this, you need to purchase the course")
                }
              >
                <Lock className="text-white" />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="mt-[3rem]">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default SolutionTab;
