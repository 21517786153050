export const REFUND_POLICY_HEADING = "REFUND POLICY";

export const REFUND_POLICY_CONSTANTS = [
  {
    title: "PURCHASE",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "If you wish to purchase any product or service made available through Service, you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card, your billing address, and your shipping information. ",
      },
      {
        title: null,
        point:
          "You represent and warrant that: (i) you have the legal right to use any card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete. ",
      },
      {
        title: null,
        point:
          "We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.",
      },
      {
        title: null,
        point:
          " We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.",
      },
    ],
  },
  {
    title: "SUBSCRIPTION",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          'Parts of service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles will be set depending on the type of subscription plan you select when purchasing a Subscription.',
      },
      {
        title: null,
        point:
          "At the end of each Billing Cycle, your Subscription will automatically/manually renew under the exact same conditions unless you cancel it or RanKplus cancels it. ",
      },
      {
        title: null,
        point:
          "You may cancel your Subscription renewal either through your online account management page or by contacting support@rankplusofficial.com or WhatsApp : +91 8235067766 customer support team.",
      },
      {
        title: null,
        point:
          "A valid payment method is required to process the payment for your subscription. You shall provide RanKplus with accurate and complete billing information that may include but not limited to full name, address, state, postal or zip code, telephone number, and a valid payment method information. Should automatic billing fail to occur for any reason, RanKplus reserves the right to terminate your access to the Service with immediate effect. ",
      },
    ],
  },
  {
    title: "FEE CHANGES ",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "RanKplus, in its sole discretion and at any time, may modify subscription fees for the services. Any subscription fee change will become effective at the end of the then-current billing cycle. ",
      },
      {
        title: null,
        point:
          "RanKplus will provide you with a reasonable prior notice of any change in subscription fees to give you an opportunity to terminate your subscription before such change becomes effective.",
      },
      {
        title: null,
        point:
          "Your continued use of service after subscription fee change comes into effect constitutes your agreement to pay the modified subscription fee amount.",
      },
    ],
  },
  {
    title: "RETURN AND REFUND POLICY",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "There is no any provision for refunds. So before purchase any service/course kindly read all information given in description of that course/service carefully.",
      },
    ],
  },
];
