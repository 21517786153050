import React from "react";
import { useParams } from "react-router-dom";
import useBookHeaders from "../../utils/use-BookHeaders";
import BookDescriptionBanner from "../PageBanner/BookDescriptionBanner";
import BookDescription from "./BookDescription";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";

const BookDetails = () => {
  const { bookId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookHeaders = useBookHeaders(bookId, dispatch, navigate);
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);
  const isPurchased = bookHeaders?.bookHeadersData?.details[0]?.is_purchased;

  return (
    <div className="-mb-[5rem]">
      <BookDescriptionBanner
        bookHeaders={bookHeaders?.bookHeadersData}
        cryptoData = {bookHeaders?.cryptoData}
        isPurchased={isPurchased}
      />
      <div
        className="mx-[5rem] my-[2rem] w-[52rem]"
        style={{ fontFamily: "DM Sans" }}
      >
        <BookDescription
          bookId={bookId}
          description={bookHeaders?.bookHeadersData?.details[0].description}
        />
      </div>
    </div>
  );
};

export default BookDetails;
