import React, { useState, useEffect } from "react";
import { USER_DETAILS_MY_OFFERS_HEADING } from "../../constants/text_constants";
import { ArrowBigRightDash } from "lucide-react";
import useMyOffers from "../../utils/use-myOffers";
import OfferCard from "../Card-Component/OfferCard";
import { Link } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../routes/routes";
import Lottie from "lottie-react";
import AnimationData from "../../assets/LookingLottie.json";

const UserOffers = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const myOffers = useMyOffers(currentPage, setTotalPages, 2);
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="text-2xl font-semibold">
          {USER_DETAILS_MY_OFFERS_HEADING}
        </div>
        <Link to={PROTECTED_ROUTES?.offers}>
          <div className="flex items-center gap-1 hover:text-cyan-400">
            More Offers
            <span className="hover:translate-x-2 transition-all cursor-pointer">
              <ArrowBigRightDash />
            </span>
          </div>
        </Link>
      </div>
      <div className="mt-8 grid grid-cols-1 gap-5">
        {myOffers?.offers?.length > 0 &&
          myOffers?.offers?.map((ele, i) => <OfferCard {...ele} key={i}/>)}
        {(!myOffers || myOffers?.total === 0) && (
          <div className = "flex justify-center items-center">
            <div className="w-[18rem]">
              <Lottie animationData={AnimationData} />
              <div className = "text-center text-[#5A277C] font-semibold text-xl">No offers as of now</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserOffers;
