import React from 'react'
import Lottie from "lottie-react";
import AnimationData from "../../assets/EmptyCartLottie.json";


const EmptyCartLottie = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-[20rem]">
        <Lottie animationData={AnimationData} />
      </div>
      <div
        className="text-[#130C40] text-3xl font-bold mt-5 w-[20rem] text-center mb-20"
        style={{ fontFamily: "DM Sans" }}
      >
        Cart is Empty !!
      </div>
    </div>
  )
}

export default EmptyCartLottie
