export const PRIVACY_POLICY_HEADING = "PRIVACY POLICY";

export const PRIVACY_POLICY_CONSTANTS = [
  {
    title: null,
    subtitle:
      "At RanKplus the Learning App, we are committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and disclose personal information from our Application the App. Please read this Privacy Policy carefully before using the Site/App. By using the Site/App/App, you agree to this Privacy Policy. If you do not agree to this Privacy Policy, please do not use the Site/App.",
    points: [],
  },
  {
    title: "DEFINITIONS AND KEY TERMS",
    subtitle: null,
    points: [
      {
        title: "PERSONAL INFORMATION",
        point:
          "It means information that can be used to identify you, such as your name, email address, phone number, and other contact details.",
      },
      {
        title: "USAGE INFORMATION",
        point:
          "It means information about how you use the Site/App, such as the pages you visit, the frequency of your visits, and the length of your visits.",
      },
      {
        title: "DEVICE INFORMATION",
        point:
          "It means information about the device you use to access the Site/App, such as the device type, operating system, and internet browser.",
      },
    ],
  },
  {
    title: "GOVERNANCE/LAW",
    subtitle:
      "This Privacy Policy is governed by the laws of New Delhi, India.",
    points: [],
  },
  {
    title: "Information Collection and Use",
    subtitle: "We may collect the following types of information from you:",
    points: [],
  },
  {
    title: "Personal Information",
    subtitle:
      "This includes information that can be used to identify you, such as your name, email address, phone number, and other contact details.",
    points: [],
  },
  {
    title: "Usage Information",
    subtitle:
      "This includes information about how you use the Site/App, such as the pages you visit, the frequency of your visits, and the length of your visits.",
    points: [],
  },
  {
    title: "Device Information",
    subtitle:
      "This includes information about the device you use to access the Site/App, such as the device type, operating system, and internet browser.",
    points: [],
  },
  {
    title: "Information Collection and Use",
    subtitle: "We collect this information through various means",
    points: [],
  },
  {
    title: "Directly from You",
    subtitle:
      "For example, when you provide your personal information to us through a contact form on the Site/App.",
    points: [],
  },
  {
    title: "Automatically through the Site/App",
    subtitle:
      "For example, when we use cookies and other tracking technologies to collect information about how you use the Site/App. We may use your information for the following purposes:",
    points: [
      {
        title: null,
        point: "To provide, improve, and maintain the Site/App",
      },
      {
        title: null,
        point: "To respond to your questions and requests",
      },
      {
        title: null,
        point:
          "To communicate with you about news, updates, and promotional offers",
      },
      {
        title: null,
        point: "To monitor the Site/App for security purposes",
      },
      {
        title: null,
        point:
          "To conduct analytics to understand how our users use the Site/App",
      },
      {
        title: null,
        point:
          "For billing purposes: If you make a purchase through the Site/App, we will use your personal and billing information to process your payment.",
      },
    ],
  },
  {
    title: "RETURN & REFUND POLICY",
    subtitle: null,
    points: [
      {
        title: "Return Policy",
        point:
          "There is no any provision for Return , Because its a Digital Product and Once Purchased, it can not be returned. So before purchase any service/course kindly read all information given in description of that course/service carefully.",
      },
      {
        title: "Shipping Policy",
        point:
          "There is no any provision for Shiping , Because its a Digital Product and Once Purchased, you will get the access of the Content through Application Only.",
      },
      {
        title: "Refund Policy",
        point:
          " If you have made double payment of the same product mistakenly or because of the System Glitch, then Only Refund Option is Available and not any other any Conditions. Please write us on rankplusofficial@gmail.com with the Concerned Issue.",
      },
    ],
  },
  {
    title: "ACCOUNT",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          " When you create an account with us, you guarantee that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on service.",
      },
      {
        title: null,
        point:
          "You are responsible for maintaining the confidentiality of your account and password, including limited to the restriction of access to your account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.",
      },
      {
        title: null,
        point:
          "You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.",
      },
      {
        title: null,
        point:
          "We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.",
      },
    ],
  },
  {
    title: "ERROR REPORTING & FEEDBACK",
    subtitle: null,
    points: [
      {
        title: null,
        point:
          "You may provide us either directly at quiresrankplus@gmail.com or via What’s App +91 8235067766 with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”).",
      },
    ],
  },
  {
    title: "Information Sharing and Disclosure",
    subtitle:
      "We may share or disclose your information in the following circumstances:",
    points: [
      {
        title: "With third-party service providers",
        point:
          "We may share your information with third-party service providers who perform services on our behalf, such as hosting, analytics, and marketing.",
      },
      {
        title: "As required by law",
        point:
          "We may disclose your information if we are required to do so by law, such as in response to a subpoena, court order, or government request.",
      },
      {
        title: "To protect our rights and property",
        point:
          "We may disclose your information if we believe it is necessary to protect our rights or property.",
      },
      {
        title:
          "In a merger, acquisition, or sale of all or a portion of our assets",
        point:
          "We may disclose your information in the event of a merger, acquisition, or sale of all or a portion of our assets.",
      },
    ],
  },
  {
    title: "Data Security",
    subtitle:
      "We take reasonable measures to protect the security of your personal information. This includes using industry-standard encryption technologies to protect sensitive information during transmission and implementing strict access controls to prevent unauthorized access to our systems. However, no system can be 100% secure, and we cannot guarantee the security of your information. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us. We Do Not Store Banking Information, Credit Card, Debit Card, or Personal Identity Information like PAN, Aadhaar, Social Security, etc. We do not store or process any sensitive personal identity information such as PAN, Aadhaar, Social Security numbers, credit card or debit card information, or any other financial information. If you make a purchase through the Site/App, the payment will be processed by a secure third-party payment processor.",
    points: [],
  },
  {
    title: "Child Protection",
    subtitle:
      "We take the protection of children’s privacy and personal information very seriously. Our Site/App is not intended for children under the age of 13, and we do not knowingly collect or solicit personal information from children under the age of 13. If we become aware that a child under the age of 13 has provided us with personal information, we will take steps to delete such information. If you are a parent or guardian and believe that your child under the age of 13 has provided us with personal information, please contact us at quiresrankplus@gmail.com.",
    points: [],
  },
  {
    title: "Changes to This Privacy Policy",
    subtitle:
      "We may update this Privacy Policy occasionally to reflect changes to our practices or for other operational, legal, or regulatory reasons. If we change this Privacy Policy, we will update the “Effective Date” at the top of this page and post the updated Privacy Policy on the Site/App. Your continued use of the Site/App after posting changes to this Privacy Policy means you accept those changes.",
    points: [],
  },
  {
    title: "Contact Us",
    subtitle:
      "If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at quiresrankplus@gmail.com.",
    points: [],
  },
];
