import React from 'react'
import Lottie from "lottie-react";

const CustomLottie = ({animationData, text, width = "w-[30rem]"}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className= {width}>
        <Lottie animationData={animationData} />
      </div>
      <div
        className="text-[#130C40] text-3xl font-bold w-[25rem] text-center"
        style={{ fontFamily: "DM Sans" }}
      >
        {text}
      </div>
    </div>
  )
}

export default CustomLottie
