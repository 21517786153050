import React, { useState } from "react";
import { COURSE_DETAILS_CLASSROOM_HEADING } from "../../constants/text_constants";
import useCourseSubjects from "../../utils/use-CourseSubjects";
import ClassroomTabSubjects from "./ClassroomTabSubjects";
import ClassroomTabLectures from "./ClassroomTabLectures";
import ClassroomTabNotes from "./ClassroomTabNotes";
import InValidLottie from "../Lottie-Components/InValidLottie";

const ClassroomTab = ({ courseId, isPurchased }) => {
  const courseSubjects = useCourseSubjects(courseId);
  const [subjectId, setSubjectId] = useState(null);
  const [showLecture, setShowLecture] = useState(true);
  const userAuthenticated = localStorage.getItem("authenticated") === "1";

  if (!userAuthenticated) {
    return (
      <div>
        <div className="text-2xl font-semibold my-6">
          {COURSE_DETAILS_CLASSROOM_HEADING}
        </div>
        <div>
          <InValidLottie text={"You Need to Login to view Classroom"} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="text-2xl font-semibold my-6">
        {COURSE_DETAILS_CLASSROOM_HEADING}
      </div>
      {!userAuthenticated && (
        <div>
          <InValidLottie text={"You Need to Login to view Live Classes"} />
        </div>
      )}
      <div className="bg-[#F8FCFD] border border-[#DFE3EC] px-4 py-3 rounded-t-xl font-semibold flex">
        <div className="w-[290px]">Subjects</div>
        <div className="flex gap-5 items-center cursor-pointer">
          <div
            className={
              showLecture
                ? "text-[#03B3C3] underline underline-offset-[1.1rem]"
                : ""
            }
            onClick={() => setShowLecture(true)}
          >
            Lecture
          </div>
          <div
            className={
              !showLecture
                ? "text-[#03B3C3] underline underline-offset-[1.1rem]"
                : ""
            }
            onClick={() => setShowLecture(false)}
          >
            Notes
          </div>
        </div>
      </div>
      <div className="flex gap-2 mt-2">
        <div>
          <ClassroomTabSubjects
            courseSubjects={courseSubjects}
            setSubjectId={setSubjectId}
            defaultSubjectId={courseSubjects?.subjects[0]?.id}
            subjectId={subjectId}
          />
        </div>
        <div className="">
          {showLecture && (
            <ClassroomTabLectures
              subjectId={subjectId}
              defaultSubjectId={courseSubjects?.subjects[0]?.id}
              isPurchased={isPurchased}
            />
          )}
          {!showLecture && (
            <ClassroomTabNotes
              subjectId={subjectId}
              defaultSubjectId={courseSubjects?.subjects[0]?.id}
              isPurchased={isPurchased}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassroomTab;
