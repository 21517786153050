import React from "react";
import useCourseHeaders from "../../../utils/use-CourseHeaders";
import TestSeriesTab from "../../CourseDetail/TestSeriesTab";

const CourseLiveTestsTab = ({ courseId, isPurchased }) => {
  const courseHeaders = useCourseHeaders(courseId);

  return (
    <div className = "mx-[1rem]">
      <TestSeriesTab
        testPackageId={courseHeaders?.details[0]?.test_package_id}
        isPurchased={isPurchased}
        showMetaDescription = {false}
      />
    </div>
  );
};

export default CourseLiveTestsTab;
