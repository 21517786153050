import React from "react";
import DOWNLOAD_ICON from "../../../assets/DownLoadIcon.png";
import { Link } from "react-router-dom";


const DescriptionSyllabus = ({syllabus_url, syllabus_title}) => {
  return (
    <div className="mx-[0.5rem] pb-[0.7rem]">
      <h1 className="text-lg font-semibold">Syllabus</h1>
      <div>
        <div className="flex gap-2">
          <div className="flex gap-1 items-center">
            <div>{syllabus_title}</div>
            <div>
              <Link to={syllabus_url} target="_blank">
                <img
                  src={DOWNLOAD_ICON}
                  alt={syllabus_title}
                  className="bg-[#5A277C] p-1 rounded-full mx-1"
                  loading="lazy"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionSyllabus;
