import { useEffect, useRef } from "react";
import { AUTO_LOGOUT_TIMER } from "../constants/text_constants";

const useAutoLogout = (logoutCallback, timeout = AUTO_LOGOUT_TIMER) => {
  const timerRef = useRef(null);

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(logoutCallback, timeout);
  };

  useEffect(() => {
    const events = ["click", "mousemove", "keypress", "scroll", "touchstart"];
    events.forEach((event) => window.addEventListener(event, resetTimer));
    resetTimer();
    
    return () => {
      clearTimeout(timerRef.current);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [logoutCallback, timeout]);
};

export default useAutoLogout;
