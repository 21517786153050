import { configureStore } from "@reduxjs/toolkit";
import cacheSlice from "./cache";
import userSlice from "./userSlice";
import cartSlice from "./cartSlice";
import sidebarSlice from "./sidebarSlice";

const appStore = configureStore({
  reducer: {
    cache: cacheSlice,
    user: userSlice,
    cart : cartSlice,
    sidebar : sidebarSlice
  },
});

export default appStore;
