import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCitiesCache } from "../configs/cache";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";

const useCitiesData = (stateId) => {
  const [citiesData, setCitiesData] = useState(null);
  const cache = useSelector((store) => store.cache.citiesCache);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchCitiesData = async (retries = 6) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_CITIES + stateId
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchCitiesData(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setCitiesData(json?.data?.cities);
      dispatch(setCitiesCache([json?.data?.cities, stateId]));
    } catch (err) {
      console.error("Error useCitiesData", err);
    }
  };

  useEffect(() => {
    if (cache.stateId) {
      return cache.stateId;
    } else {
      fetchCitiesData();
    }
  }, [stateId]);

  return citiesData;
};

export default useCitiesData;
