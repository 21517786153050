import React from "react";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import StarRatings from "react-star-ratings";
import CALENDAR_ICON from "../../assets/Calendar.png";
import CALENDAR_BLANK_ICON from "../../assets/CalendarBlank.png";
import CLOCK_ICON from "../../assets/Clock.png";
import BOOK_ICON from "../../assets/BookOpenText.png";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import { Link } from "react-router-dom";
import { Card, CardContent } from "../../components/ui/card";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import handleBuy from "../Buy/handleBuy";
import { ITEM_COURSE } from "../../constants/text_constants";

const DescriptionBanner = ({ courseHeaders, isPurchased = "0" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8)), url('${BACKGROUND_IMAGE}')`,
        backgroundSize: "cover",
        fontFamily: "DM Sans",
        fontStyle: "normal",
      }}
      className="h-[23rem] px-[5rem] py-[3rem] text-[#130C40] flex relative"
    >
      
      {/* left Section */}
      <div>
        {/* Course Description */}
        <div>
          {courseHeaders?.details.map((ele, i) => (
            <div className="flex flex-col gap-5" key = {i}>
              {/* Star Rating and Total Ratings */}
              <div className="flex gap-3">
                <div>
                  <StarRatings
                    rating={Math.floor(ele?.rating)}
                    starRatedColor="#FCB129"
                    numberOfStars={5}
                    starDimension="1.2rem"
                  />
                </div>
                <div className="mt-[0.2rem] flex gap-2">
                  <div className="font-bold">{ele?.rating}</div>
                  <div className="font-light italic">({ele?.total_rating})</div>
                </div>
              </div>
              {/* Course Name */}
              <div className="font-bold text-5xl">{ele?.name}</div>
              {/* Course Desc */}
              <div className="text-sm w-[40rem]">{ele?.sub_description}</div>
              {/* Course start date, end date, validity, total Hours */}
              <div className="flex gap-10">
                {/* start date */}
                <div className="flex gap-2 items-start">
                  {/* icon */}
                  <div>
                    <img
                      src={CALENDAR_BLANK_ICON}
                      alt={ele?.starts_on}
                      className="mt-1"
                      loading = "lazy"
                    />
                  </div>
                  {/* text and value */}
                  <div>
                    <div className="text-[#82808D]">Start Date:</div>
                    <div>{ele?.starts_on}</div>
                  </div>
                </div>
                {/* end date */}
                <div className="flex gap-2 items-start">
                  {/* icon */}
                  <div>
                    <img
                      src={CALENDAR_BLANK_ICON}
                      alt={ele?.starts_on}
                      className="mt-1"
                      loading = "lazy"
                    />
                  </div>
                  {/* text and value */}
                  <div>
                    <div className="text-[#82808D]">End Date:</div>
                    <div>{ele?.ends_on}</div>
                  </div>
                </div>
                {/* validity */}
                <div className="flex gap-2 items-start">
                  {/* icon */}
                  <div>
                    <img
                      src={CALENDAR_ICON}
                      alt={ele?.starts_on}
                      className="mt-1"
                      loading = "lazy"
                    />
                  </div>
                  {/* text and value */}
                  <div>
                    <div className="text-[#82808D]">Validity:</div>
                    <div>{ele?.validity}</div>
                  </div>
                </div>
                {/* total hours */}
                <div className="flex gap-2 items-start">
                  {/* icon */}
                  <div>
                    <img
                      src={CLOCK_ICON}
                      alt={ele?.starts_on}
                      className="mt-1"
                      loading = "lazy"
                    />
                  </div>
                  {/* text and value */}
                  <div>
                    <div className="text-[#82808D]">Total Hours:</div>
                    <div>{ele?.duration}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Syllabus */}
        <div className="mt-3">
          <div className="flex gap-2 items-start">
            <div>
              <img src={BOOK_ICON} alt="" className="mt-1" loading = "lazy"/>
            </div>
            <div>
              <div className="text-[#82808D]">Syllabus</div>
              <div className="flex gap-2">
                {courseHeaders?.schedules.map((ele, i) => (
                  <div className="flex gap-1 items-center" key = {i}>
                    <div>{ele?.title}</div>
                    <div>
                      <Link to={ele?.attachment} target="_blank">
                        <img
                          src={DOWNLOAD_ICON}
                          alt={ele?.title}
                          className="bg-[#5A277C] p-1 rounded-full mx-1"
                          loading = "lazy"
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Right Section */}
      <div>
        <div className="absolute top-[11rem] right-[7rem]">
          <Card className="border border-white shadow-xl">
            <CardContent
              style={{ fontFamily: "DM Sans" }}
              className="aspect-square p-0"
            >
              <div>
                {courseHeaders?.details.map((ele, i) => (
                  <div className="flex flex-col items-center justify-center gap-3" key = {i}>
                    {/* Image Section */}
                    <div>
                      <img
                        src={ele?.course_banner}
                        alt={ele?.name}
                        className="w-[22rem] h-[12rem] rounded-t-lg"
                        loading = "lazy"
                      />
                    </div>
                    {/* pricing section */}
                    <div className="flex items-center gap-3">
                      {/* Discount percentage */}
                      <div>
                        {ele?.offer_discount !== "0" &&
                        ele?.offer_discount !== null ? (
                          <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                            {ele?.offer_discount}% off
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* offer_price */}
                      <div className="text-[#5A277C] font-bold text-xl">
                        ₹
                        {ele?.offer_discount !== "0" &&
                        ele?.offer_discount !== null
                          ? ele?.offer_price
                          : ele?.price}
                      </div>
                      {/* original price */}
                      {ele?.offer_discount !== "0" &&
                        ele?.offer_discount !== null && (
                          <div className="line-through text-gray-500">
                            ₹{ele?.price}
                          </div>
                        )}
                    </div>
                    {/* Buy Now btn */}
                    {isPurchased === "0" && (
                      <div>
                        <button
                          className="bg-[#5A277C] text-white w-[18rem] py-1 rounded-xl cursor-pointer mt-5 font-semibold"
                          onClick={() =>
                            handleBuy(
                              dispatch,
                              navigate,
                              ele?.id,
                              ITEM_COURSE,
                              ele?.name,
                              ele?.offer_price,
                              ele?.offer_discount,
                              ele?.price,
                              ele?.principal_amount,
                              ele?.course_banner,
                              ele?.course_icon
                            )
                          }
                        >
                          {" "}
                          Buy Now
                        </button>
                      </div>
                    )}
                    {/* Course Purchased Text */}
                    {isPurchased === "1" && (
                      <div>
                        <div className="bg-gradient-to-r from-cyan-400 to-purple-600 text-white w-[18rem] py-1 rounded-xl font-semibold text-center mt-[1rem]">
                          {" "}
                          Course Purchased
                        </div>
                      </div>
                    )}
                    {/* {isPurchased === "0" && <div className="pb-3">{""}</div>} */}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DescriptionBanner;
