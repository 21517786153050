import React, { useState, useEffect } from "react";
import useCourseDescription from "../../utils/use-CourseDescription";
import useCourseFAQ from "../../utils/use-CourseFAQ";
import {
  COURSE_DETAILS_DESCRIPTION_HEADING,
  COURSE_DETAILS_DESCRIPTION_FAQ_HEADING,
} from "../../constants/text_constants";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

const CourseDescriptionTab = ({ courseId }) => {
  const courseDescription = useCourseDescription(courseId);
  const courseFaq = useCourseFAQ(courseId);

  return (
    <div>
      <div className="text-2xl font-semibold my-3">
        {COURSE_DETAILS_DESCRIPTION_HEADING}
      </div>
      {courseDescription?.details.map((ele, i) => (
        <div
          key={i}
          className="space-y-2 md:text-[1rem] text-sm"
          dangerouslySetInnerHTML={{ __html: ele?.description }}
        />
      ))}
      <div className="text-2xl font-bold mt-10 mb-5">
        {COURSE_DETAILS_DESCRIPTION_FAQ_HEADING}
      </div>
      <div>
        {courseFaq?.map((ele, i) => (
          <div key={i}>
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger className = "md:text-[1rem] text-sm text-left">{ele?.question}</AccordionTrigger>
                <AccordionContent className = "md:text-[1rem] text-sm text-left">{ele?.answer}</AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseDescriptionTab;
