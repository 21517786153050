import React, { useState, useEffect, useCallback } from "react";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import { Button } from "../../components/ui/button";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { ScrollArea } from "../../components/ui/scroll-area";

const StudentFeedbackCard = ({ student_name, student_pic, student_feedback, selected_year, qualified_exam, enrolled_batch }) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [showReadMore, setShowReadMore] = useState(false);

  // Handle resizing the window
  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getReadMoreData = useCallback(() => {
    return (
      <Dialog>
        <DialogTrigger asChild> 
            <Link to="#"><span className="">Read More</span></Link>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[36rem] max-w-[22rem]">
          <DialogHeader>
            <DialogTitle>{student_name}</DialogTitle>
            <br />
            <hr />
            <br />
            <DialogDescription>
              <ScrollArea className="lg:w-[33rem] w-[20rem] lg:h-[36rem] h-[22rem] rounded-md">
                <div>{stripHtml(student_feedback)}</div>
              </ScrollArea>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    );
  }, [student_name, student_feedback]);

  // Memoized feedback data to avoid unnecessary re-renders
  const feedbackData = useCallback(() => {
    const data = stripText(stripHtml(student_feedback), 100, { returnTextsLengths: true });
    const { plainText, plainTextLength, stripedTextLength } = data;

    // Only update state if necessary to avoid infinite loop
    if (plainTextLength > stripedTextLength && !showReadMore) {
      setShowReadMore(true); // Update state only if required
    }

    return plainText;
  }, [student_feedback, showReadMore]); // Add showReadMore as a dependency

  return (
    <div className="-my-10">
      {/* Hexagon div */}
      <div
        style={{
          aspectRatio: "1",
          clipPath:
            "polygon(93.56% 74.55%,50.52% 100%,6.96% 75.45%,6.44% 25.45%,49.48% 0%,93.04% 24.55%)",
          backgroundImage: `url('${student_pic}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="bg-white md:w-[150px] w-[100px] relative md:top-[7rem] top-[4rem] md:left-[4rem] left-[2rem] flex flex-col justify-end z-10"
      >
        <div className="-mb-8">
          <div className="bg-[#5A277C] text-center text-white text-sm">
            {student_name}
          </div>
          <div
            className="bg-[#5A277C] mb-9 text-center text-white"
            style={{
              background:
                "linear-gradient(276deg, #6F3199 -0.84%, #1ECBDB 100%)",
              height: "20px",
            }}
          >
            {selected_year}
          </div>
        </div>
      </div>

      {innerWidth <= 400 && (
        <div className="relative top-[0.7rem] left-[1.7rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="110"
            height="20"
            viewBox="0 0 270 40"
            fill="none"
          >
            <path d="M0 0H270L262 20L270 40H0L9 20L0 0Z" fill="#5A277C" />
          </svg>
        </div>
      )}

      {innerWidth > 400 && (
        <div className="relative top-[1.4rem] left-[3rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="40"
            viewBox="0 0 270 40"
            fill="none"
          >
            <path d="M0 0H270L262 20L270 40H0L9 20L0 0Z" fill="#5A277C" />
          </svg>
        </div>
      )}

      <div className="bg-white border border-[#DFE3EC] md:w-[18rem] w-[10rem] h-[20rem] rounded-lg p-4 flex justify-center items-center shadow-xl lg:text-[1rem] text-sm">
        <div>
          <b> Qualified Exam: </b> {qualified_exam} <br /><br />
          {feedbackData()} {showReadMore && <> {getReadMoreData() }</>}
          <br /> <br />
          <b >Batch: </b> {enrolled_batch}
        </div>

        
      </div>
    </div>
  );
};

export default StudentFeedbackCard;
