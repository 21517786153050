export function convertTo12HourFormat(dateTime) {
  let [date, time24] = dateTime.split(" ");
  let [hours, minutes] = time24.split(":");

  // Determine AM or PM
  let period = +hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = +hours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
}
