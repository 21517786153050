import React, { useState, useEffect } from "react";

const TestingPage = () => {
  const [videos, setVideos] = useState([]);
  const API_KEY = "AIzaSyDw2NubxJNFOgrgxHnwgPkZTOfN-mq9SX0"; // Replace with your API key
  const CHANNEL_ID = "UCJJ2BK_dwxZpKzuY06K_Jpg"; // Replace with your channel ID
  const MAX_RESULTS = 5; // Number of videos to fetch

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=${MAX_RESULTS}`
      );
      const data = await response.json();
      setVideos(data.items);
    };

    fetchVideos();
  }, []);

  return (
    <div>
      <h2>Latest Videos</h2>
      <div className="video-list">
        {videos?.map((video) => (
          <div key={video.id.videoId} className="video-item">
            <iframe
              width="300"
              height="200"
              src={`https://www.youtube.com/embed/${video.id.videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={video.snippet.title}
            ></iframe>
            <p>{video.snippet.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestingPage;
