import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useBookHeaders from "../../utils/use-BookHeaders";
import BookDescriptionBanner from "../PageBanner/BookDescriptionBanner";
import BookDescriptionTab from "./BookDescription";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import DetailsResponsive from "../DetailResponsive/DetailsResponsive";
import { ITEM_BOOK } from "../../constants/text_constants";

const BookDetails = () => {
  const { bookId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookHeaders = useBookHeaders(bookId, dispatch, navigate);
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);
  const isPurchased = bookHeaders?.bookHeadersData?.details[0]?.is_purchased;

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (innerWidth <= 450) {
    return (
      <DetailsResponsive
        headerDetails={bookHeaders?.bookHeadersData}
        item={ITEM_BOOK}
        isPurchased={isPurchased}
        itemId={bookId}
      />
    );
  }

  return (
    <div className="-mb-[5rem] overflow-hidden">
      <BookDescriptionBanner
        bookHeaders={bookHeaders?.bookHeadersData}
        cryptoData={bookHeaders?.cryptoData}
        isPurchased={isPurchased}
        bookBanners={bookHeaders?.bookBanners}
      />
      <div
        className="mx-[5rem] my-[2rem] w-[52rem]"
        style={{ fontFamily: "DM Sans" }}
      >
        <BookDescriptionTab
          bookId={bookId}
          description={bookHeaders?.bookHeadersData?.details[0].description}
        />
      </div>
    </div>
  );
};

export default BookDetails;
