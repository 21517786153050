import React, { useState } from "react";
import MEGAPHONE_ICON from "../../assets/Megaphone.png";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { timeAgo } from "../../utils/util-functions/timeAgo";
import { UNPROTECTED_ROUTES, PROTECTED_ROUTES } from "../../routes/routes";
import {
  ITEM_COURSE,
  ITEM_TEST_PACKAGE,
  ITEM_BOOK,
} from "../../constants/text_constants";
import { useNavigate } from "react-router-dom";
import { ChevronUp, ChevronDown, SquareArrowOutUpRight } from "lucide-react";
import { useSelector } from "react-redux";

const NotificationCard = ({
  id,
  title,
  created_at,
  message,
  attachment,
  redirect_category,
  redirect_id,
  subject_id,
  test_package_id,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const isSideBarOpen = useSelector((store) => store.sidebar.isSideBarOpen);

  function handleClick() {
    if (redirect_category === ITEM_COURSE) {
      navigate(UNPROTECTED_ROUTES?.courseDetails + `/${redirect_id}`);
    }
    if (redirect_category === ITEM_TEST_PACKAGE) {
      navigate(UNPROTECTED_ROUTES?.testDetails + `/${test_package_id}`);
    }
    if (redirect_category === ITEM_BOOK) {
      navigate(UNPROTECTED_ROUTES?.bookDetails + `/${redirect_id}`);
    }
    if (redirect_category === "video") {
      navigate(PROTECTED_ROUTES?.classroom + `/${redirect_id}/${subject_id}`);
    }
  }

  return (
    <div onClick={() => setShow(!show)}>
      <div
        className={
          "bg-[#F8FCFD] border border-[#DFE3EC] lg:p-4 p-2 rounded-xl flex lg:gap-5 gap-1 items-start shadow-lg hover:-translate-y-2 hover:shadow-2xl transition-all " +
          (redirect_category !== "" ? "cursor-pointer" : "")
        }
        key={id}
      >
        <div className="bg-white p-2 rounded-full">
          <img
            src={MEGAPHONE_ICON}
            alt="megaphone-icon"
            className="lg:w-[2rem] lg:h-[2rem] md:w-[1.5rem] md:h-[1.5rem] w-[1rem] h-[1rem]"
            loading = "lazy"
          />
        </div>
        <div className="flex flex-col gap-2 w-3/4">
          <div className="flex justify-between items-center lg:w-[43rem] md:w-[32rem] w-[17rem] mt-[0.5rem]">
            <div className="font-bold lg:text-lg md:text-[1rem] text-[0.9rem] lg:mb-0 mb-2 lg:w-[22rem] md:w-[25rem] w-[14rem]">
              <div>{title}</div>
              <div className="text-[#82808D] font-extralight lg:ml-2 ml-1 italic lg:text-sm md:text-xs text-[0.7rem]">
                {timeAgo(created_at)}
              </div>
              {(redirect_category !== "" && show)&& <span onClick = {handleClick}><SquareArrowOutUpRight className = "inline ml-2 lg:h-8 md:h-4 h-3"/></span>}
            </div>
            <div className = {(isSideBarOpen ? "" : "mr-[3rem]")}>{show ? <ChevronUp /> : <ChevronDown />}</div>
          </div>
          {show && <div className="lg:w-[40rem] md:w-[39rem] w-[15rem] lg:text-[1rem] md:text-[1rem] text-[0.8rem]">{stripHtml(message)}</div>}
          {show && (
            <div>
              {attachment !== "" && (
                <img
                  src={attachment}
                  alt={title}
                  className="lg:w-[30rem] lg:h-[15rem] md:w-[20rem] md:h-[9rem] w-[16rem] h-[7rem] rounded-xl self-center lg:mt-2 mt-1"
                  loading = "lazy"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
