import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import LiveChat from "./LiveChat";
import useClassroomVideoData from "../../utils/use-classroomVideoData";
import CustomVideoPlayer from "../VideoPlayer/CustomVideoPlayer";
import Rating from "../Classroom/Rating";
import VideoDescription from "../Classroom/VideoDescription";

const LiveClassroom = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);
  const videoData = useClassroomVideoData(id);
  const data = videoData?.details?.lecture?.[0];

  return (
    <div className="flex m-12 gap-2 mb-[2rem]">
      <div className="w-3/4">
        <div className="flex flex-col">
          <div>{data && <CustomVideoPlayer data={data} />}</div>
          <div>
            <VideoDescription data={data} />
          </div>
          <div>
            <Rating />
          </div>
        </div>
      </div>
      <div>
        <LiveChat id={id} />
      </div>
    </div>
  );
};

export default LiveClassroom;
