import React from "react";
import { BOOKS_PAGE_BOOKS_LIST_TAB_HEADINGS } from "../../constants/text_constants";
import { useSelector } from "react-redux";

const BooksListHeader = ({ setShowAllBooks, showAllBooks }) => {
  const allBooksCache = useSelector((store) => store.cache.allBooksCache);
  const myBooksCache = useSelector((store) => store.cache.myBooksCache);
  const authenticated = localStorage.getItem("authenticated") === "1";
  return (
    <div
      className="flex justify-between border-b border-gray-200 pb-2 text-[#3F3D4B]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex lg:gap-5 gap-3 cursor-pointer lg:text-lg text-xs">
        <div
          className={
            showAllBooks
              ? "text-[#03B3C3] underline lg:underline-offset-[0.9rem] underline-offset-[0.75rem] font-semibold"
              : ""
          }
          onClick={() => setShowAllBooks(true)}
        >
          {BOOKS_PAGE_BOOKS_LIST_TAB_HEADINGS[0]}
        </div>
        <div
          className={
            !showAllBooks
              ? "text-[#03B3C3] underline lg:underline-offset-[0.9rem] underline-offset-[0.75rem] font-semibold"
              : ""
          }
          onClick={() => setShowAllBooks(false)}
        >
          {BOOKS_PAGE_BOOKS_LIST_TAB_HEADINGS[1]}
        </div>
      </div>
      {authenticated ? (
        showAllBooks ? (
          <div className="lg:text-lg text-xs">Total Books : {allBooksCache[0]?.total}</div>
        ) : (
          <div className="lg:text-lg text-xs">Purchased Books : {myBooksCache[0]?.total}</div>
        )
      ) : !showAllBooks ? (
        <div className="lg:text-lg text-xs">Purchased Books : 0</div>
      ) : (
        <div className="lg:text-lg text-xs">Total Books : {allBooksCache[0]?.total}</div>
      )}
    </div>
  );
};

export default BooksListHeader;
