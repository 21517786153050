import React, { useState, useEffect } from "react";
import UserIconAvatar from "../UserIcon/UserIcon";
import { Paperclip } from "lucide-react";

const UserInput = ({
  setFile,
  placeholder,
  inputWidth,
  btnText,
  callback,
  setDoubtText,
  doubtText,
  editCommentData = null,
  allowFileUpload = true
}) => {
  const handleFileChange = (e) => {
    const fileSelected = e.target.files[0];
    if (fileSelected) {
      setFile(fileSelected);
    }
  };

  return (
    <div className="flex justify-between items-center">
      {/* left section */}
      <div className="flex gap-2 items-center">
        <div>
          <UserIconAvatar userName={""} />
        </div>
        <div>
          <input
            type="text"
            placeholder={placeholder}
            onChange={(e) => setDoubtText(e.target.value)}
            className={"py-1 px-2 outline-none " + inputWidth}
            value={doubtText}
          />
        </div>
      </div>
      {/* Right Section */}
      <div className="flex items-center gap-2">
        {allowFileUpload && <div>
          {/* <Paperclip className="w-6 h-6" /> */}
          <input
            type="file"
            id="file"
            className="hidden"
            accept=".png, .jpeg, .jpg"
            onChange={handleFileChange}
          />
          <label htmlFor="file" className="cursor-pointer">
            <Paperclip className="w-5 h-5" />
          </label>
        </div>}
        <div>
          <button
            className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-xl"
            onClick={callback}
          >
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInput;
