import { UNPROTECTED_ROUTES, PROTECTED_ROUTES } from "./routes";
import { createBrowserRouter } from "react-router-dom";

// component imports
import App from "../App";
import LandingPage from "../app_components/LandingPage/LandingPage";
import LoginPage from "../app_components/Login/LoginPage";
import StudentDetails from "../app_components/StudentDetails/StudentDetails";
import LegalPage from "../app_components/LegalPage/LegalPage";
import TestSeriesPage from "../app_components/TestSeries/TestSeriesPage";
import CoursesPage from "../app_components/Courses/CoursesPage";
import BooksPage from "../app_components/Books/BooksPage";
import BlogsPage from "../app_components/Blogs/BlogsPage";
import UserProfile from "../app_components/UserProfile/UserProfile";
import ProtectedRoute from "./ProtectedRoute";
import CourseDetails from "../app_components/CourseDetail/CourseDetails";
import TestDetails from "../app_components/TestDetails/TestDetails";
import BookDetails from "../app_components/BookDetails/BookDetails";
import FreeLibraryVideos from "../app_components/FreeLibrary/FreeLibraryVideos";
import FreeLibraryNotes from "../app_components/FreeLibrary/FreeLibraryNotes";
import FreeLibraryTests from "../app_components/FreeLibrary/FreeLibraryTests";
import ErrorPage from "../app_components/Error/ErrorPage";
import ClassroomPage from "../app_components/Classroom/ClassroomPage";
import LiveClassroom from "../app_components/liveClassroom/LiveClassroom";
import BuyPage from "../app_components/Buy/BuyPage";
import MyOffersPage from "../app_components/Offers/MyOffersPage";
import PaymentPage from "../app_components/Payment/PaymentPage";
import NotificationsPage from "../app_components/Notifications/NotificationsPage";
import LibraryVideosPlay from "../app_components/FreeLibrary/LibraryVideosPlay";
import SupportPage from "../app_components/Support/SupportPage";

// deleted
import TestingPage from "../app_components/Testing/TestingPage";

const appRouter = createBrowserRouter([
  {
    path: UNPROTECTED_ROUTES?.home,
    element: <App />,
    children: [
      {
        path: "/index.html",
        element: <LandingPage />,
      },
      {
        path: UNPROTECTED_ROUTES?.home,
        element: <LandingPage />,
      },
      {
        path: UNPROTECTED_ROUTES?.login,
        element: <LoginPage />,
      },
      {
        path: UNPROTECTED_ROUTES?.studentDetails,
        element: <StudentDetails />,
      },
      {
        path: UNPROTECTED_ROUTES?.terms,
        element: <LegalPage />,
      },
      {
        path: UNPROTECTED_ROUTES?.privacy,
        element: <LegalPage />,
      },
      {
        path: UNPROTECTED_ROUTES?.return,
        element: <LegalPage />,
      },
      {
        path: UNPROTECTED_ROUTES?.testSeries,
        element: <TestSeriesPage />,
      },
      {
        path: UNPROTECTED_ROUTES?.courses,
        element: <CoursesPage />,
      },
      {
        path: UNPROTECTED_ROUTES?.books,
        element: <BooksPage />,
      },
      {
        path: UNPROTECTED_ROUTES?.blogs,
        element: <BlogsPage />,
      },
      {
        path: PROTECTED_ROUTES?.profile,
        element: <ProtectedRoute element={<UserProfile />} />,
      },
      {
        path: UNPROTECTED_ROUTES?.courseDetails + "/:courseId",
        element: <CourseDetails />,
      },
      {
        path: UNPROTECTED_ROUTES?.testDetails + "/:testId",
        element: <TestDetails />,
      },
      {
        path: UNPROTECTED_ROUTES?.bookDetails + "/:bookId",
        element: <BookDetails />,
      },
      {
        path: UNPROTECTED_ROUTES?.freeLibrary,
        element: <FreeLibraryVideos />,
      },
      {
        path: UNPROTECTED_ROUTES?.freeLibraryVideos,
        element: <FreeLibraryVideos />,
      },
      {
        path: UNPROTECTED_ROUTES?.freeLibraryNotes,
        element: <FreeLibraryNotes />,
      },
      {
        path: UNPROTECTED_ROUTES?.freeLibraryTests,
        element: <FreeLibraryTests />,
      },
      {
        path: "/testing",
        element: <TestingPage />,
      },
      {
        path: PROTECTED_ROUTES?.classroom + "/:id/:subjectid",
        element: <ProtectedRoute element={<ClassroomPage />} />,
      },
      {
        path: PROTECTED_ROUTES?.live + "/:id",
        element: <ProtectedRoute element={<LiveClassroom />} />,
      },
      {
        path: PROTECTED_ROUTES?.buy,
        element: <ProtectedRoute element={<BuyPage />} />,
      },
      {
        path: PROTECTED_ROUTES?.offers,
        element: <ProtectedRoute element={<MyOffersPage />} />,
      },
      {
        path: PROTECTED_ROUTES?.notifications,
        element: <ProtectedRoute element={<NotificationsPage />} />,
      },
      {
        path: PROTECTED_ROUTES?.payment,
        element: <ProtectedRoute element={<PaymentPage />} />,
      },
      {
        path : UNPROTECTED_ROUTES?.freeLibraryVideosPlay + "/:id",
        element : <LibraryVideosPlay/>
      },
      {
        path : PROTECTED_ROUTES?.support,
        element : <SupportPage/>
      }
    ],
    errorElement: <ErrorPage />,
  },
]);

export default appRouter;
