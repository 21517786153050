import React from "react";
import { Card, CardContent } from "../../components/ui/card";
import { ThumbsUp, Share2 } from "lucide-react";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import { formatDate } from "../../utils/util-functions/formatDate";
import CALENDER_ICON from "../../assets/CalendarBlank.png";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { ScrollArea } from "../../components/ui/scroll-area";

// deleted
import TEMP_BANNER from "../../assets/temp_banner_img.jpeg";

const BlogsCard = ({
  likes,
  shares,
  title,
  description,
  created_at,
  blogimg_url,
}) => {
  return (
    <div>
      <Card className="lg:w-[25rem] md:w-[17rem] w-[15rem] lg:max-h-[27rem] md:max-h-[25rem] max-h-[22rem]">
        <CardContent
          style={{ fontFamily: "DM Sans" }}
          className="aspect-square p-0"
        >
          {/* Banner image */}
          <div>
            {/* <img src={blogimg_url} alt="blogimg-url" /> */}
            <img
              src={TEMP_BANNER}
              alt="blogimg-url"
              loading="lazy"
              className="rounded-t-xl lg:w-[25rem] lg:h-[14rem] md:w-[17rem] md:h-[10rem] w-[15rem] h-[8rem]"
            />
          </div>
          {/* title and likes, shares */}
          <div>
            {/* title */}
            <div className="font-semibold lg:w-[25rem] md:w-[17rem] w-[15rem] my-2 px-3 lg:text-xl md:test-sm text-sm">
              {title}
            </div>
            {/* likes*/}
            <div className="flex justify-between my-2 px-3 lg:text-[1rem] md:text-xs text-xs">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-1">
                  {likes} <ThumbsUp />
                </div>
                {/* shares */}
                <div className="flex items-center gap-1">
                  {shares} <Share2 />
                </div>
              </div>
              <div className="flex gap-1 items-center">
                <div>
                  <img src={CALENDER_ICON} alt="calender-icon" loading="lazy" />
                </div>
                <div className="font-extralight md:text-sm text-xs">
                  {formatDate(created_at)}
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 lg:text-sm text-xs lg:w-[24rem] md:w-[16rem] w-[14rem]">
            {stripText(stripHtml(description), 120)}
          </div>

          <Dialog>
            <DialogTrigger>
              <div className="text-[#1ECBDB] px-3 mt-1 cursor-pointer font-semibold lg:text-[1rem] text-sm">
                Read More
              </div>
            </DialogTrigger>
            <DialogContent className="lg:max-w-[800px] md:max-w-[600px] max-w-[30rem] lg:w-[90vw] w-[90vw] lg:h-[90vh] md:h-[90vh] h-[140vw]">
              <DialogHeader>
                <DialogTitle className="lg:w-[40rem] md:w-[25rem] w-[18rem] text-2xl">
                  <div className="bg-gradient-to-r from-cyan-400 to-purple-600 bg-clip-text text-transparent lg:text-3xl text-xl font-bold">
                    {title}
                  </div>
                </DialogTitle>
                <DialogDescription>
                  <ScrollArea className="lg:w-[100%] w-[90%] lg:h-[36rem] md:h-[30rem] h-[25rem] rounded-md lg:p-4 -p-1">
                    <div
                      className="custom-html-content space-y-4"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </ScrollArea>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </div>
  );
};

export default BlogsCard;
