import React, { useState, useEffect } from "react";
import { ScrollArea } from "../../components/ui/scroll-area";
import PDF_ICON from "../../assets/FilePdf.png";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import { Link } from "react-router-dom";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { Lock } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import {
  modExp,
  CryptoJSAesJson,
} from "../../utils/util-functions/encryptionUtils";
import CryptoJS from "crypto-js";

const ClassroomTabNotes = ({ subjectId, defaultSubjectId, isPurchased }) => {
  const [notesData, setNotesData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [encryptionData, setEncryptionData] = useState(null);

  const setPubPvtKey = () => {
    const prime = 23;
    const base = 5;

    const clientPrivateKey = Math.floor(Math.random() * 100);
    const clientPublicKey = modExp(base, clientPrivateKey, prime);

    const cryptoData = {
      clientPrivateKey: clientPrivateKey,
      clientPublicKey: clientPublicKey,
      prime: prime,
    };

    setEncryptionData(cryptoData);
  };

  const fetchSubjectNotes = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env
          .REACT_APP_RANK_PLUS_COURSE_DETAILS_LIVE_CLASS_SUBJECT_NOTES +
          (subjectId ? subjectId : defaultSubjectId) +
          "&limit=150&page=" +
          currentPage
          +`&publicKey=${encryptionData.clientPublicKey}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchSubjectNotes(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setNotesData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("Error fetchSubjectNotes", err);
    }
  };

  useEffect(() => {
    fetchSubjectNotes();
  }, [encryptionData]);

  useEffect(() => setPubPvtKey(), [currentPage, subjectId, defaultSubjectId]);

  function handleClick(encodedUrl) {
    const serverPublicKey = notesData?.publicKey;
    const clientSharedSecret = modExp(
      serverPublicKey,
      encryptionData?.clientPrivateKey,
      encryptionData?.prime
    );
    try {
      const lectureUrl = CryptoJS.AES.decrypt(
        encodedUrl,
        clientSharedSecret.toString(),
        {
          format: CryptoJSAesJson,
        }
      )
        .toString(CryptoJS.enc.Utf8)
        .replace(/['"]/g, "");

      window.open(lectureUrl, "_blank");
    } catch (err) {
      toast("Something went wrong 😖 Please refresh the page again");
    }
  }

  return (
    <div>
      <ScrollArea className="h-[600px] w-[535px] rounded-md border px-4">
        {notesData?.notes.map((ele, i) => (
          <div
            key={i}
            className="bg-[#F8FCFD] border border-[#DFE3EC] rounded-lg p-4 my-3 flex items-center justify-between shadow-md"
          >
            {/* left section */}
            <div className="flex items-center gap-2">
              <div className="bg-white p-2 rounded-full">
                <img src={PDF_ICON} alt="pdf icon" loading = "lazy"/>
              </div>
              <div className="w-[17rem] font-semibold">{ele?.title}</div>
            </div>
            {/* Right Section */}
            {!notesData?.is_locked && (
              <div
                className="bg-[#03B3C3] p-2 rounded-full cursor-pointer"
                onClick={() => handleClick(ele?.note_pdf)}
              >
                <img src={DOWNLOAD_ICON} alt="download-icon" loading = "lazy"/>
              </div>
            )}
            {notesData?.is_locked && (
              <div
                className="bg-[#5A277C] p-2 rounded-full cursor-pointer"
                onClick={() =>
                  toast("To access this, you need to purchase the course")
                }
              >
                <Lock className="text-white" />
              </div>
            )}
          </div>
        ))}
      </ScrollArea>
    </div>
  );
};

export default ClassroomTabNotes;
