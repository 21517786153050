import React from "react";
import LiveClassesTab from "../../CourseDetail/LiveClassesTab";

const CoursesLiveClassTab = ({ courseId, isPurchased }) => {
  return (
    <div className="mx-[1rem]">
      <LiveClassesTab courseId={courseId} isPurchased={isPurchased} />
    </div>
  );
};

export default CoursesLiveClassTab;
