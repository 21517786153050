import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import {
  ITEM_COURSE,
  ITEM_TEST_PACKAGE,
  ITEM_BOOK,
} from "../../constants/text_constants";

const HeroBannerCarousel = ({ webBanners }) => {
  const navigate = useNavigate();

  function handleClick(category, id) {
    if (category === ITEM_COURSE) {
      navigate(UNPROTECTED_ROUTES?.courseDetails + `/${id}`);
    }
    if (category === ITEM_TEST_PACKAGE) {
      navigate(UNPROTECTED_ROUTES?.testDetails + `/${id}`);
    }
    if (category === ITEM_BOOK) {
      navigate(UNPROTECTED_ROUTES?.bookDetails + `/${id}`);
    }
  }

  return (
    <div>
      <Carousel
        autoPlay
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
      >
        {webBanners?.map((ele, i) => (
          <div
          key = {i}
            style={{
              backgroundImage: `url('${ele?.banner_url}')`,
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "800",
            }}
            className="lg:h-[20rem] md:h-[15rem] h-[10rem] flex justify-between items-center px-[10rem]"
            onClick={() =>
              handleClick(ele?.redirect_category, ele?.redirect_id)
            }
          >
            {/* <div
              className="text-2xl text-white bg-[#5A277C] mt-[10rem] px-4 py-1 rounded-xl border-2 border-white border-dashed cursor-pointer"
              
            >
              {ele?.code}
            </div>
            <div>{""}</div> */}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HeroBannerCarousel;
