import { useState, useEffect } from "react";
import {PAGE_LIMIT} from "../constants/text_constants";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";

const useEnrolledPackages = (currentPage, setTotalPages) => {
  const [enrolledData, setEnrolledData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchEnrolledPackages = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_ENROLLED_PACKAGES + currentPage,
        requestOptions
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchEnrolledPackages(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setEnrolledData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      localStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchEnrolledPackages", err);
    }
  };

  useEffect(() => {
    fetchEnrolledPackages();
  }, [currentPage]);

  return enrolledData;
};

export default useEnrolledPackages;
