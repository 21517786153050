import React from "react";

const FooterBottomCol3 = ({ data }) => {
  return (
    <div>
      <div className="font-bold text-[#F9F9F9] flex lg:justify-center justify-start lg:mt-0 mt-8">
        {data?.title}
      </div>
      <div className="mt-4">
        {data?.links.map((ele, i) => (
          <div key={i} className="flex gap-2 my-2">
            <div>
              <img src={ele?.src} alt={ele?.id} loading = "lazy"/>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-[#9591AA]">{ele?.title}</div>
              <div className="text-[#1ECBDB]">{ele?.info}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FooterBottomCol3;
