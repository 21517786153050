import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import InValidLottie from "../Lottie-Components/InValidLottie";
import { setMyBooksCache } from "../../configs/cache";
import { PAGE_LIMIT } from "../../constants/text_constants";
import CourseCard from "../Card-Component/CourseCard";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import IntegratedPagination from "../Pagination/IntegratedPagination";

const MyBooksList = () => {
  const userAuthenticated = localStorage.getItem("authenticated") === "1";
  const [currentPage, setCurrentPage] = useState(0);
  const [myBooks, setMyBooks] = useState(null);
  const authToken = localStorage.getItem("token");
  const cache = useSelector((store) => store.cache.myBooksCache);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchMyBooks = async (retries = 6) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_MY_BOOKS_DATA +
          PAGE_LIMIT +
          `/${currentPage}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchMyBooks(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setMyBooks(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      dispatch(setMyBooksCache([json?.data, currentPage]));
      localStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchMyBooks", err);
    }
  };

  useEffect(() => {
    if (userAuthenticated) {
      if (cache[currentPage]) {
        setMyBooks(cache[currentPage]);
        setTotalPages(Math.ceil(cache[currentPage]?.total / PAGE_LIMIT));
      } else {
        fetchMyBooks();
      }
    }
  }, [currentPage, cache]);

  // user has not signed in
  if (!userAuthenticated) {
    return <InValidLottie text={"You Need to sign in to see your Books"} />;
  }

  // loading animation
  if (!myBooks) {
    return <LoadingLottie />;
  }

  if (myBooks?.books.length === 0) {
    return <InValidLottie text={"You have not purchased any Books"} />;
  }

  return (
    <div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem]">
        {myBooks?.books?.map((ele, i) => (
          <CourseCard
            key={i}
            name={ele?.name}
            offer_price={ele?.offer_price}
            offer_discount={ele?.offer_discount}
            price={ele?.price}
            course_banner={ele?.thumbnail}
            title_1={"Edition"}
            value_1={ele?.edition}
            title_4={"Validity"}
            value_4={ele?.validity}
            rating={ele?.rating}
            faculty_name={ele?.faculty_name}
            thumbnail={ele?.thumbnail}
            description={ele?.description}
            totalRating={ele?.total_rating}
            id={ele?.id}
            exploreMoreLink={UNPROTECTED_ROUTES?.bookDetails}
            showPrice={false}
            showBuyNow={false}
            showExploreMore={false}
            showView={true}
          />
        ))}
      </div>
      <div className="mt-[5rem]">
        <IntegratedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default MyBooksList;
