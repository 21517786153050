import React from "react";

const SectionHeading = ({ heading, headingColor, subHeading, width = "lg:w-[50rem] w-[20rem]" }) => {
  return (
    <div
      className="lg:text-4xl md:text-2xl flex flex-col items-center my-6 text-lg"
      style={{
        textAlign: "center",
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        color: headingColor ? headingColor : "white",
      }}
    >
      {heading}
      <div
        className="h-0.5 w-20 mt-2"
        style={{ backgroundColor: headingColor ? headingColor : "white" }}
      ></div>
      {subHeading && <div className = {"lg:text-lg text-xs my-4 font-semibold " + width }>{subHeading}</div>}
    </div>
  );
};

export default SectionHeading;
