import React from "react";
import Lottie from "lottie-react";
import AnimationData from "../../assets/PeopleLottie.json";

const InValidLottie = ({ text }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="lg:w-[40rem] md:w-[30rem] w-[20rem]">
        <Lottie animationData={AnimationData} />
      </div>
      <div
        className="text-[#130C40] lg:text-3xl md:text-xl text-lg font-bold mt-10 md:w-[25rem] w-[18rem] text-center"
        style={{ fontFamily: "DM Sans" }}
      >
        {text}
      </div>
    </div>
  );
};

export default InValidLottie;
