import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";

const useCountriesData = () => {
  const [countriesData, setCountriesData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchCountriesData = async (retries = 6) => {
    try {
      const response = await fetch(process.env.REACT_APP_RANK_PLUS_COUNTRIES);
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchCountriesData(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setCountriesData(json?.data?.countries);
    } catch (err) {
      console.error("Error fetchCountriesData", err);
    }
  };

  useEffect(() => {
    fetchCountriesData();
  }, []);

  return countriesData;
};

export default useCountriesData;
