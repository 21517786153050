import React from "react";
import DescriptionTop from "./DescriptionTop";
import DescriptionSyllabus from "./DescriptionSyllabus";
import DescriptionTab from "../../TestDetails/DescriptionTab";

const TestDescription = ({ headerDetails, testId }) => {
  return (
    <div className="mx-[1rem] -mb-[5rem]">
      <div className="bg-white border border-gray-200 shadow-xl p-3 rounded-xl">
        <DescriptionTop {...headerDetails?.details?.[0]} />
        <DescriptionSyllabus {...headerDetails?.details?.[0]} />
      </div>
      <div className="mx-[1rem] mt-[2rem]">
        <DescriptionTab testId={testId} />
      </div>
    </div>
  );
};

export default TestDescription;
