import React from "react";
import { Trash2 } from "lucide-react";

const CartItem = ({ name, price, thumbnail, id, removeFromCart, show }) => {
  return (
    <div className="my-5 flex justify-between">
      <div className="flex gap-5">
        <div>
          <img
            src={thumbnail}
            alt={name}
            className="max-w-[12rem] max-h-[10rem] rounded-lg shadow-lg"
            loading = "lazy"
          />
        </div>
        <div>
          <div className="mt-3 font-semibold text-xl text-gray-600">{name}</div>

          {show && <div className="mt-5 cursor-pointer" onClick = {() => removeFromCart(id)}>
            <Trash2 />
          </div>}
        </div>
      </div>
      <div className="mt-5 font-semibold text-xl">
        <span className="mr-3">. . .</span> ₹{price}
      </div>
    </div>
  );
};

export default CartItem;
