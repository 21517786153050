import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setStateCache } from "../configs/cache";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";

const useStatesData = (countryId) => {
  const [statesData, setStatesData] = useState(null);
  const cache = useSelector((store) => store.cache.stateCache);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchStatesData = async (retries = 6) => {
    const response = await fetch(
      process.env.REACT_APP_RANK_PLUS_STATES + countryId
    );
    if (!response.ok) {
      if (response.status >= 400 && response.status < 500) {
        userLogout(navigate, dispatch, true);
      }
      if (response.status >= 500 && response.status < 600 && retries > 0) {
        console.warn(`Retrying... attempts left: ${retries}`);
        setTimeout(() => fetchStatesData(retries - 1), 10000);
        return;
      } else {
        throw new Error(`HTTP error: ${response.status}`);
      }
    }
    const json = await response.json();
    setStatesData(json?.data?.states);
    dispatch(setStateCache([json?.data?.states, countryId]));
  };

  useEffect(() => {
    if (cache.countryId) {
      return cache.countryId;
    } else {
      fetchStatesData();
    }
  }, [countryId]);

  return statesData;
};

export default useStatesData;
