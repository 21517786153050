import React from "react";
import PageNotFoundLottie from "../Lottie-Components/PageNotFoundLottie";
import Navbar from "../Navbar/NavbarComponent";
import Footer from "../Footer/Footer";

const ErrorPage = () => {
  return (
    <div>
      <Navbar />
      <PageNotFoundLottie />
      <Footer />
    </div>
  );
};

export default ErrorPage;
