import React from "react";
import COURSES_ICON from "../../assets/COURSES_ICON.webp";
import TEST_SERIES_ICON from "../../assets/TEST_SERIES_ICON.webp";
import BOOKS_ICON from "../../assets/BOOKS_ICON.webp";
import LIBRARY_ICON from "../../assets/LIBRARY_ICON.webp";
import { Link, useLocation } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import LOGOUT_ICON from "../../assets/LOGOUT_ICON.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import COURSES_ICON_FILLED from "../../assets/COURSES_ICON_FILLED.webp";
import TEST_SERIES_ICON_FILLED from "../../assets/TEST_SERIES_ICON_FILLED.webp";
import BOOKS_ICON_FILLED from "../../assets/BOOKS_ICON_FILLED.webp";
import LIBRARY_ICON_FILLED from "../../assets/LIBRARY_ICON_FILLED.webp";

const CollapsedSIdebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  function handleLogout() {
    userLogout(navigate, dispatch);
  }

  return (
    <div
      className="sticky top-[6rem] w-12 mt-2 h-screen lg:block md:hidden hidden mx-[0.6rem]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex flex-col gap-[20rem]">
        <div className="flex flex-col items-center gap-[1.5rem]">
          <Link to={UNPROTECTED_ROUTES?.courses}>
            <div className="flex flex-col items-center gap-1">
              {location.pathname === UNPROTECTED_ROUTES?.courses ? (
                <img
                  src={COURSES_ICON_FILLED}
                  alt="COURSES_ICON"
                  className="w-[2.3rem]"
                />
              ) : (
                <img
                  src={COURSES_ICON}
                  alt="COURSES_ICON"
                  className="w-[2.3rem]"
                />
              )}
              <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">Courses</p>
            </div>
          </Link>
          <Link to={UNPROTECTED_ROUTES?.testSeries}>
            <div className="flex flex-col items-center gap-1">
              {location.pathname === UNPROTECTED_ROUTES?.testSeries ? (
                <img
                  src={TEST_SERIES_ICON_FILLED}
                  alt="TEST_SERIES_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
              ) : (
                <img
                  src={TEST_SERIES_ICON}
                  alt="TEST_SERIES_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
              )}
              <p className="text-sm text-center bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">Test Series</p>
            </div>
          </Link>
          <Link to={UNPROTECTED_ROUTES?.books}>
            <div className="flex flex-col items-center gap-1">
              {location.pathname === UNPROTECTED_ROUTES?.books ? (
                <img
                  src={BOOKS_ICON_FILLED}
                  alt="BOOKS_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
              ) : (
                <img
                  src={BOOKS_ICON}
                  alt="BOOKS_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
              )}

              <p className="text-sm text-center bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">Books</p>
            </div>
          </Link>
          <Link to={UNPROTECTED_ROUTES?.freeLibrary}>
            <div className="flex flex-col items-center gap-1">
              {location.pathname === UNPROTECTED_ROUTES?.freeLibrary ||
              location.pathname === UNPROTECTED_ROUTES?.freeLibraryNotes ||
              location.pathname === UNPROTECTED_ROUTES?.freeLibraryTests ||
              location.pathname === UNPROTECTED_ROUTES?.freeLibraryVideos ? (
                <img
                  src={LIBRARY_ICON_FILLED}
                  alt="LIBRARY_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
              ) : (
                <img
                  src={LIBRARY_ICON}
                  alt="LIBRARY_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
              )}

              <p className="text-sm text-center bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">Library</p>
            </div>
          </Link>
        </div>
        {localStorage.getItem("authenticated") === "1" && (
          <div onClick={handleLogout}>
            <img
              src={LOGOUT_ICON}
              alt="LOGOUT_ICON"
              className="w-[3rem] cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CollapsedSIdebar;
