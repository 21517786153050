import React, {useState, useEffect} from "react";
import REVOLUTION_LEARNING_BG from "../../assets/revolution_learning_bg.png";
import { LANDING_PAGE_REVOLUTION_LEARNING_YT_VIDEO } from "../../constants/video_constants";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_REVOLUTION } from "../../constants/text_constants";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import { TOP_FOOTER } from "../../constants/text_constants";
import { Link } from "react-router-dom";

const RevolutionLearning = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, rgba(34, 211, 238, 0.8), rgba(147, 51, 234, 0.8)), url(${REVOLUTION_LEARNING_BG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        fontFamily: "DM Sans",
      }}
      className="rounded-xl flex flex-col items-center my-5 lg:h-[33rem] md:h-[43rem] h-[38rem] lg:-mx-0 -mx-[1rem]"
    >
      <div className="flex justify-center items-center">
        <SectionHeading heading={LANDING_PAGE_REVOLUTION?.heading} />
      </div>
      <div className="flex lg:flex-row flex-col items-center justify-center bg-white lg:w-[70rem] md:w-[44rem] w-[20rem] p-5 rounded-lg lg:gap-5 gap-3 lg:mt-5 mt-1">
        {/* for portrait mode */}
        {innerWidth <= 450 && (
          <div className="p-2 shadow-lg">
            <LANDING_PAGE_REVOLUTION_LEARNING_YT_VIDEO
              width={250}
              height={180}
            />
          </div>
        )}
        {/* for landscape mode */}
        {innerWidth > 450 && innerWidth < 800 && (
          <div className="p-2 shadow-lg">
            <LANDING_PAGE_REVOLUTION_LEARNING_YT_VIDEO
              width={650}
              height={300}
            />
          </div>
        )}
        {/* for desktop */}
        {innerWidth >= 800 && (
          <div className="p-2 shadow-lg">
            <LANDING_PAGE_REVOLUTION_LEARNING_YT_VIDEO
              width={"560"}
              height={"315"}
            />
          </div>
        )}
        <div className="md:text-sm text-xs flex flex-col gap-5">
          <div>{LANDING_PAGE_REVOLUTION?.desc1}</div>
          <div>{LANDING_PAGE_REVOLUTION?.desc2}</div>
          <Link to={TOP_FOOTER?.playStoreLink} target="_blank">
            <div className="flex items-center lg:justify-start justify-center">
              <button className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all">
                <div className="drop-shadow-md flex items-center gap-2">
                  {LANDING_PAGE_REVOLUTION?.buttonText}
                  <img alt="download-icon" src={DOWNLOAD_ICON} loading="lazy" />
                </div>
              </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RevolutionLearning;
