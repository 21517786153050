import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { NAVBAR_COURSES_DROPDOWN_DROPDOWN } from "../../constants/text_constants";
import { ChevronDown } from "lucide-react";

const CoursesDropdown = () => {
  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex hover:text-[#1ECBDB]">
          Courses <ChevronDown />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {NAVBAR_COURSES_DROPDOWN_DROPDOWN.map((ele, i) => (
            <DropdownMenuItem key={i}>{ele}</DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default CoursesDropdown;
