import React from "react";
import Herocards from "./Herocards";
import RevolutionLearning from "./RevolutionLearning";
import MetricsSection from "./MetricsSection";
import FeaturesSection from "./FeaturesSection";
import useLandingPageData from "../../utils/use-LandingPageData";
import CoursesAvailableSection from "./CoursesAvailableSection";
import StudentTestimonials from "./StudentTestimonials";
import StudentFeedback from "./StudentFeedback";
import HeroBanner from "./HeroBanner";
import PreperationSection from "./PreperationSection";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {LP_DATA} from "../../temp/LP_DATA";

const LandingPage = () => {
  const landingPageData = useLandingPageData();
  // const landingPageData = LP_DATA;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);

  return (
    <div>
      <HeroBanner webBanners = {landingPageData?.data?.web_banners}/>
      <div className="lg:px-[5rem] px-[2rem] overflow-x-hidden overflow-y-hidden">
        <Herocards />
        <CoursesAvailableSection data={landingPageData?.data?.courses} />
        <PreperationSection
          tests={landingPageData?.data?.test_packages}
          books={landingPageData?.data?.books}
        />
        <RevolutionLearning />
        <MetricsSection />
        <StudentFeedback feedback={landingPageData?.data?.selected_students} />
        <FeaturesSection />
        <StudentTestimonials
          testimonials={landingPageData?.data?.testimonials}
        />
      </div>
    </div>
  );
};

export default LandingPage;
