import React from "react";
import SelectField from "../../StudentDetails/SelectField";

const CourseSubjectsDropdown = ({
  courseSubjects,
  setSubjectId,
  defaultSubjectId,
  subjectId,
}) => {
  return (
    <div>
      <SelectField
        text={"Subject List"}
        value={subjectId}
        setValue={setSubjectId}
        data={courseSubjects?.subjects}
        labelText={"Subject"}
        width={"w-full"}
      />
    </div>
  );
};

export default CourseSubjectsDropdown;
