import React, { useState } from "react";
import UserIconAvatar from "../UserIcon/UserIcon";
import { Paperclip } from "lucide-react";

const UserInput = ({
  setFile,
  placeholder,
  inputWidth,
  btnText,
  callback,
  setDoubtText,
  doubtText,
  editCommentData = null,
  allowFileUpload = true,
}) => {
  const handleFileChange = (e) => {
    const fileSelected = e.target.files[0];
    if (fileSelected) {
      setFile(fileSelected);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      callback(); // Call the callback function when Enter/Return is pressed
    }
  };

  return (
    <div className="flex justify-between items-center md:w-full w-[12rem]">
      {/* Left Section */}
      <div className="flex md:gap-2 gap-1 items-center">
        <div>
          <UserIconAvatar userName={""} />
        </div>
        <div>
          <input
            type="text"
            placeholder={placeholder}
            onChange={(e) => setDoubtText(e.target.value)}
            onKeyDown={handleKeyDown} // Attach the keydown event
            className={"py-1 px-2 outline-none " + inputWidth}
            value={doubtText}
          />
        </div>
      </div>
      {/* Right Section */}
      <div className="flex items-center md:gap-2 gap-1">
        {allowFileUpload && (
          <div>
            <input
              type="file"
              id="file"
              className="hidden"
              accept=".png, .jpeg, .jpg"
              onChange={handleFileChange}
            />
            <label htmlFor="file" className="cursor-pointer">
              <Paperclip className="w-5 h-5" />
            </label>
          </div>
        )}
        <div>
          <button
            className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-xl"
            onClick={callback}
          >
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInput;