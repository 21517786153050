import React, { useState, useEffect } from "react";
import ASK_DOUBT_ICON from "../../assets/ask_doubt.png";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import UserComment from "./UserComment";
import UserInput from "./UserInput";
import { ScrollArea } from "../../components/ui/scroll-area";
import { ChevronLeft, ChevronRight, X } from "lucide-react";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";

const AskDoubt = ({ id }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [file, setFile] = useState(null);
  const [doubtText, setDoubtText] = useState("");
  const [doubts, setDoubts] = useState(null);
  const [editCommentData, setEditCommentData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchDoubts = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_VIDEO_COMMENTS +
          `lecture_id=${id}&limit=10&page=${currentPage}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchDoubts(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setDoubts(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("Error fetchDoubts", err);
    }
  };

  const submitComment = async (retries = 6) => {
    try {
      const formData = new FormData();
      formData.append("lecture_id", id);
      formData.append("comment", doubtText);
      if (file) {
        formData.append("attachment", file);
      }
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      };

      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_SUBMIT_COMMENT,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => submitComment(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setFile(null);
      fetchDoubts();
    } catch (err) {
      console.error("Error submitComment", err);
    }
  };

  const deleteComment = async (id, retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_DELETE_COMMENT + id,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => deleteComment(id, retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      if (json?.status) {
        toast("Your Comment has been Deleted");
        fetchDoubts();
      }
    } catch (err) {
      console.error("Error deleteComment", err);
    }
  };

  const submitReply = async (
    comment_id,
    reply_text,
    type,
    lecture_id = id,
    retries = 6
  ) => {
    try {
      const formData = new FormData();
      formData.append("lecture_id", lecture_id);
      formData.append("comment", reply_text);
      if (type === "edit") {
        formData.append("id", comment_id);
        if (file) {
          formData.append("attachment", file);
        }
      }
      if (type === "reply") {
        formData.append("comment_id", comment_id);
      }

      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      };

      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_SUBMIT_COMMENT,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(
            () =>
              submitReply(
                comment_id,
                reply_text,
                type,
                lecture_id,
                retries - 1
              ),
            10000
          );
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      if (file) setFile(null);
      fetchDoubts();
    } catch (err) {
      console.error("Error submitComment", err);
    }
  };

  useEffect(() => {
    fetchDoubts();
  }, [currentPage]);

  function handleDoubtSubmit() {
    submitComment();
    setDoubtText("");
  }

  function handleEditSubmit() {
    submitReply(editCommentData?.id, doubtText, "edit", id);
    setDoubtText("");
    setEditCommentData(null);
  }

  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          <Button
            variant="outline"
            className="flex gap-1 bg-[#F8FCFD] border border-[#DFE3EC] items-center py-1 px-2 rounded-3xl shadow-sm cursor-pointer hover:-translate-y-1 hover:shadow-2xl transition-all"
          >
            <div>
              <img src={ASK_DOUBT_ICON} alt="star" className="w-8 h-7" loading = "lazy"/>
            </div>
            <div className="font-semibold text-[#595763]">Ask Doubts</div>
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[42rem]">
          <DialogHeader>
            <DialogTitle>Ask Doubts</DialogTitle>
            <DialogDescription>
              {/* Insert Here */}
              <div className="flex gap-1 items-center">
                {currentPage > 0 ? (
                  <div className="cursor-pointer hover:-translate-x-1 transition">
                    <ChevronLeft
                      onClick={() =>
                        setCurrentPage((currentPage) => currentPage - 1)
                      }
                    />
                  </div>
                ) : (
                  <ChevronLeft className="text-gray-300" />
                )}
                <div>
                  <ScrollArea className="w-[36rem] h-[36rem] bg-[#F8FCFD] border border-[#DFE3EC] rounded-lg p-2 mt-2">
                    {doubts?.lectures?.map((ele, i) => (
                      <UserComment
                        {...ele}
                        key={i}
                        showButtons={true}
                        showAttachment={true}
                        deleteComment={deleteComment}
                        submitReply={submitReply}
                        setEditCommentData={setEditCommentData}
                        setDoubtText={setDoubtText}
                      />
                    ))}
                  </ScrollArea>
                </div>
                {currentPage < totalPages - 1 ? (
                  <div className="cursor-pointer hover:translate-x-1 transition">
                    <ChevronRight
                      onClick={() =>
                        setCurrentPage((currentPage) => currentPage + 1)
                      }
                    />
                  </div>
                ) : (
                  <ChevronRight className="text-gray-300" />
                )}
              </div>

              <div className="mt-2 w-[36rem] ml-8">
                {editCommentData === null && (
                  <UserInput
                    setFile={setFile}
                    placeholder={"Ask Doubt"}
                    inputWidth={"w-[25rem]"}
                    btnText={"Submit"}
                    callback={handleDoubtSubmit}
                    setDoubtText={setDoubtText}
                    doubtText={doubtText}
                  />
                )}
                {editCommentData !== null && (
                  <UserInput
                    setFile={setFile}
                    placeholder={`Edit Your Comment '${editCommentData?.comment}'`}
                    inputWidth={"w-[25rem]"}
                    btnText={"Edit"}
                    callback={handleEditSubmit}
                    setDoubtText={setDoubtText}
                    doubtText={doubtText}
                    editCommentData={editCommentData}
                  />
                )}
              </div>
              {file && (
                <div className="flex gap-2 items-center mt-2 ml-[5.5rem]">
                  <div className="text-sm text-gray-400">
                    Selected File: {file?.name}
                  </div>
                  <button onClick={() => setFile(null)}>
                    <X className="text-sm" />
                  </button>
                </div>
              )}
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AskDoubt;
