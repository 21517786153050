import React from "react";
import { FREE_LIBRARY_TAB_HEADINGS } from "../../constants/text_constants";
import { useNavigate } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const TabHeadings = ({ index, text, total }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex justify-between border-b border-gray-200 pb-2 text-[#3F3D4B] lg:mt-10 mt-6"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex lg:gap-5 gap-3 cursor-pointer lg:text-lg text-xs">
        <div
          className={
            index === 0
              ? "text-[#03B3C3] underline lg:underline-offset-[0.9rem] underline-offset-[0.75rem] font-semibold"
              : ""
          }
          onClick={() => navigate(UNPROTECTED_ROUTES?.freeLibraryVideos)}
        >
          {FREE_LIBRARY_TAB_HEADINGS[0]}
        </div>
        <div
          className={
            index === 1
              ? "text-[#03B3C3] underline lg:underline-offset-[0.9rem] underline-offset-[0.75rem] font-semibold"
              : ""
          }
          onClick={() => navigate(UNPROTECTED_ROUTES?.freeLibraryNotes)}
        >
          {FREE_LIBRARY_TAB_HEADINGS[1]}
        </div>
        <div
          className={
            index === 2
              ? "text-[#03B3C3] underline lg:underline-offset-[0.9rem] underline-offset-[0.75rem] font-semibold"
              : ""
          }
          onClick={() => navigate(UNPROTECTED_ROUTES?.freeLibraryTests)}
        >
          {FREE_LIBRARY_TAB_HEADINGS[2]}
        </div>
      </div>
      <div className = "lg:text-lg text-xs">{text} : {total}</div>
    </div>
  );
};

export default TabHeadings;
