import React, { useState } from "react";
import CoursesListHeader from "./CoursesListHeader";
import AllCoursesList from "./AllCoursesList";
import MyCoursesList from "./MyCoursesList";

const CoursesList = () => {
  const [showAllCourses, setShowAllCourses] = useState(true);

  return (
    <div>
      <CoursesListHeader
        setShowAllCourses={setShowAllCourses}
        showAllCourses={showAllCourses}
      />
      <div className="mt-[2rem]">
        {showAllCourses && <AllCoursesList />}
        {!showAllCourses && <MyCoursesList />}
      </div>
    </div>
  );
};

export default CoursesList;
