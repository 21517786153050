import React from "react";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_FEATURES } from "../../constants/text_constants";
import FeaturesScrollCards from "./FeaturesScrollCards";

const FeaturesSection = () => {
  return (
    <div className="flex flex-col items-center my-[3rem]">
      <SectionHeading
        heading={LANDING_PAGE_FEATURES?.heading}
        headingColor={"#130C40"}
        subHeading={LANDING_PAGE_FEATURES?.subHeading}
        width = {"lg:w-[70rem] md:w-[30rem] w-[23rem]"}
      />
      <FeaturesScrollCards
        data={LANDING_PAGE_FEATURES?.features}
      />
      
    </div>
  );
};

export default FeaturesSection;
