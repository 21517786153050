import React from "react";
import Lottie from "lottie-react";
import AnimationData from "../../assets/404Lottie.json";
import { PAGE_NOT_FOUND_TEXT } from "../../constants/text_constants";
import { useNavigate } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const PageNotFoundLottie = () => {
  const navigate = useNavigate();

  function handleClick() {
    navigate(UNPROTECTED_ROUTES?.home);
  }

  return (
    <div className="flex flex-col justify-center items-center -mb-[5rem]">
      <div className="w-[30rem]">
        <Lottie animationData={AnimationData} />
      </div>
      <div style={{ fontFamily: "DM Sans" }}>
        <div className="text-[#5A277C] text-3xl font-bold mt-6 w-[30rem] text-center">
          {PAGE_NOT_FOUND_TEXT[0]}
        </div>
        <div className="text-[#5A277C] text-lg font-bold mt-2 w-[30rem] text-center">
          {PAGE_NOT_FOUND_TEXT[1]}
        </div>
        <div className="text-[#5A277C] text-lg font-bold mt-2 w-[30rem] text-center">
          Lets get you back
          <span
            className="text-cyan-400 underline underline-offset-4 mx-1 cursor-pointer"
            onClick={handleClick}
          >
            {" "}
            home{" "}
          </span>
          safely
        </div>
      </div>
    </div>
  );
};

export default PageNotFoundLottie;
