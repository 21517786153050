import React from "react";
import { Input } from "../../components/ui/input";

const FullNameInputField = ({ id, type, text, placeHolder, setValue, width = "w-[19rem]" }) => {
  return (
    <div className = {width}>
      <label
        htmlFor={id}
        style={{
          fontFamily: "DM Sans",
          color: "#3F3D4B",
          fontWeight: "600",
          fontStyle: "normal",
          lineHeight: "normal",
        }}
      >
        {text}
      </label>
      <Input
        type={type}
        placeholder={placeHolder}
        id={id}
        className="mt-2"
        onChange={(e) => setValue(e.target.value)}
        required
      />
    </div>
  );
};

export default FullNameInputField;
