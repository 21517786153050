import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";

const useTestPackageHeaders = (testId) => {
  const [testPackageHeaders, setTestPackageHeaders] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchTestHeadersData = async (retries = 6) => {
    try {
      let response;
      const authenticated = localStorage.getItem("authenticated") === "1";
      if (authenticated) {
        const authToken = localStorage.getItem("token");
        const requestOptions = {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_TEST_PACKAGE_DETAILS_HEADER.replace(
            /TEST_ID/,
            testId
          ),
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_TEST_PACKAGE_DETAILS_HEADER.replace(
            /TEST_ID/,
            testId
          )
        );
      }

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchTestHeadersData(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setTestPackageHeaders(json?.data);
    } catch (err) {
      console.log("Error fetchTestHeadersData", err);
    }
  };

  useEffect(() => {
    fetchTestHeadersData();
  }, [testId]);

  return testPackageHeaders;
};

export default useTestPackageHeaders;
