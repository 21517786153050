
import React from "react";
import SCROLL_TO_TOP_ICON from "../../assets/up-arrow.png";

const ScrollToTopFloating = () => {
  return (
    <div className="fixed bottom-[7rem] lg:right-8 right-3 z-50">
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        className="bg-white rounded-full p-0 shadow-lg hover:bg-gray-100 transition-colors duration-300"
        aria-label="Scroll to top"
      >
        <img src={SCROLL_TO_TOP_ICON} alt="Scroll to top" className="w-[3.5rem]" />
      </button>
    </div>
  );
};

export default ScrollToTopFloating;

