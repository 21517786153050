import React from "react";
import { Card, CardContent } from "../../components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel";
import { cn } from "../../lib/utils";
import { CircleUserRound } from "lucide-react";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import Autoplay from "embla-carousel-autoplay";
import QUOTES from "../../assets/Quotes.png";

const StudentTestimonialsCarousel = ({ data }) => {
  return (
    <div>
      <Carousel
        className={cn("lg:w-[70rem] md:w-[40rem] w-[18rem]")}
        plugins={[
          Autoplay({
            delay: 4000,
          }),
        ]}
      >
        <CarouselPrevious />
        <CarouselContent>
          {data.map((ele, index) => (
            <CarouselItem
              key={index}
              className="pl-4 md:basis-1/2 lg:basis-1/3"
            >
              <div className="p-1">
                <Card className="shadow-lg">
                  <CardContent
                    className="flex flex-col aspect-square items-center justify-around p-6"
                    style={{
                      fontFamily: "DM Sans",
                      backgroundImage: `url('${QUOTES}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right 20px top 20px",
                    }}
                  >
                    <div>
                      <div className="font-bold mb-3">{ele?.title}</div>
                      <div>{stripText(stripHtml(ele?.description))}</div>
                      <div className="flex gap-2 mt-4 item-center">
                        {ele?.thumbnail ? (
                          <div>
                            <img
                              alt={ele?.course_name}
                              src={ele?.thumbnail}
                              className="w-10 h-10 rounded-full mt-1"
                              loading = "lazy"
                            />
                          </div>
                        ) : (
                          <div>
                            <CircleUserRound className="w-10 h-10 mt-1" />
                          </div>
                        )}
                        <div className="flex flex-col ml-0.5">
                          <div className="text-[#5A277C] font-bold">
                            {ele?.student_name}
                          </div>
                          <div className="text-sm">{ele?.course_name}</div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselNext />
      </Carousel>
    </div>
  );
};

export default StudentTestimonialsCarousel;
