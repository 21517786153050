import React, { useState, useEffect } from "react";
import PageBanner from "../PageBanner/PageBanner";
import { FREE_LIBRARY_TESTS_BANNER } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import TabHeadings from "./TabHeadings";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { setFreeLibraryTestsCache } from "../../configs/cache";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import { Card, CardContent } from "../../components/ui/card";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import Pagination from "../Pagination/Pagination";

const FreeLibraryTests = () => {
  const [libraryTestsData, setLibraryTestsData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const cache = useSelector((store) => store.cache.freeLibraryTestsCache);
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);

  const fetchFreeLibraryTests = async (retries = 6) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_FREE_LIBRARY_TESTS + currentPage
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchFreeLibraryTests(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      setLibraryTestsData(json?.data);
      dispatch(setFreeLibraryTestsCache([json?.data, currentPage]));
    } catch (err) {
      console.error("Error fetchFreeLibraryTests", err);
    }
  };

  useEffect(() => {
    if (cache[currentPage]) {
      setLibraryTestsData(cache[currentPage]);
      setTotalPages(Math.ceil(cache[currentPage]?.total / 12));
    } else {
      fetchFreeLibraryTests();
    }
  }, [currentPage, cache]);

  return (
    <div>
      <PageBanner
        title={FREE_LIBRARY_TESTS_BANNER?.title1}
        subtitle={FREE_LIBRARY_TESTS_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className="lg:mx-[10rem] md:mx-[3rem] mx-[1rem]">
        <TabHeadings
          index={2}
          text={"Total Tests"}
          total={libraryTestsData?.total}
        />
      </div>
      <div>
        {(!libraryTestsData || libraryTestsData?.tests?.length === 0) && (
          <LoadingLottie />
        )}
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mx-[10rem] md:mx-[3rem] mx-[1rem] gap-2 mt-10">
        {libraryTestsData?.tests.map((ele, i) => (
          <div
            key={i}
            onClick={() =>
              toast(`Please log in to RanKplus Mobile App to access the Test`)
            }
          >
            <Card>
              <CardContent style={{ fontFamily: "DM Sans" }} className="p-2">
                <div className="flex gap-4 items-center">
                  <div>
                    <img
                      src={ele?.thumbnail}
                      alt={ele?.name}
                      className="lg:w-[5rem] w-[4rem] rounded-lg"
                      loading = "lazy"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className = "lg:text-xl text-sm font-bold">{ele?.name}</div>
                    <div className="text-[#1ECBDB] font-semibold lg:text-xl text-sm">
                      {ele?.dpp_test_count} Tests
                    </div>
                    <div>
                      <button
                        className="border-2 border-[#5A277C] lg:px-2 lg:py-1 px-1 py-1 rounded-xl hover:bg-[#5A277C] hover:text-white"
                        onClick={() =>
                          toast(
                            `Please log in to RanKplus Mobile App to access the Test`
                          )
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      <div className="mt-[5rem]">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default FreeLibraryTests;
