import React, { useState, useEffect } from "react";
import {
  BOOK_DETAILS_DESCRIPTION_HEADING,
  BOOK_DETAILS_DESCRIPTION_FAQ_HEADING,
} from "../../constants/text_constants";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useSelector } from "react-redux";

const BookDescriptionTab = ({ bookId, description }) => {
  const [bookFAQ, setBookFAQ] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSideBarOpen = useSelector((store) => store.sidebar.isSideBarOpen);

  const fetchBookFAQs = async (retries = 6) => {
    try {
      const requestOptions = {
        method: "POST",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_FAQ +
          `limit=20&page=0&type=book&id=${bookId}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchBookFAQs(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setBookFAQ(json?.data?.faqs);
    } catch (err) {
      console.log("Error fetchBookFAQs", err);
    }
  };

  useEffect(() => {
    fetchBookFAQs();
  }, [bookId]);

  return (
    <div>
      <div className="text-2xl font-semibold my-3">
        {BOOK_DETAILS_DESCRIPTION_HEADING}
      </div>
      <div
        className={"space-y-2 md:text-[1rem] text-sm " + (isSideBarOpen ? "w-[48rem]" : "w-[52rem]") }
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="text-2xl font-bold mt-10 mb-5">
        {BOOK_DETAILS_DESCRIPTION_FAQ_HEADING}
      </div>
      <div>
        {bookFAQ?.map((ele, i) => (
          <div key={i}>
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger className="md:text-[1rem] text-sm text-left">
                  {ele?.question}
                </AccordionTrigger>
                <AccordionContent className="md:text-[1rem] text-sm text-left">
                  {ele?.answer}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookDescriptionTab;
