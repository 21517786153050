import React, { useState, useEffect } from "react";
import COURSES_ICON from "../../../assets/COURSES_ICON.webp";
import { UNPROTECTED_ROUTES } from "../../../routes/routes";
import { Link, useLocation } from "react-router-dom";
import userLogout from "../../../utils/userLogout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAllCoursesCache, setMyCoursesCache } from "../../../configs/cache";
import { Dot, ChevronDown, ChevronUp } from "lucide-react";
import COURSES_ICON_FILLED from "../../../assets/COURSES_ICON_FILLED.webp";

const Courses = () => {
  const [allCoursesData, setAllCoursesData] = useState([]);
  const [myCoursesData, setMyCoursesData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AllCoursesCache = useSelector((store) => store.cache.allCoursesCache);
  const MyCoursesCache = useSelector((store) => store.cache.myCoursesCache);
  const [showAllCourses, setShowAllCourses] = useState(false);
  const [showMyCourses, setShowMyCourses] = useState(false);
  const userAuthenticated = localStorage.getItem("authenticated") === "1";
  const location = useLocation();

  const fetchAllCourses = async (retries = 6) => {
    try {
      let response;
      const userAuthenticated = localStorage.getItem("authenticated") === "1";
      if (userAuthenticated) {
        const authToken = localStorage.getItem("token");
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_COURSES_DATA + "0",
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_COURSES_DATA + "0"
        );
      }

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchAllCourses(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setAllCoursesData(json?.data);
      dispatch(setAllCoursesCache([json?.data, 0]));
    } catch (err) {
      console.error("Error fetchAllCourses", err);
    }
  };

  const fetchMyCourses = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_MY_COURSES_DATA + "5" + `/0`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchMyCourses(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setMyCoursesData(json?.data);
      dispatch(setMyCoursesCache([json?.data, 0]));
      localStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchMyCourses", err);
    }
  };

  useEffect(() => {
    if (AllCoursesCache[0]) {
      setAllCoursesData(AllCoursesCache[0]);
    } else {
      fetchAllCourses();
    }
  }, []);

  useEffect(() => {
    if (userAuthenticated) {
      if (MyCoursesCache[0]) {
        setMyCoursesData(MyCoursesCache[0]);
      } else {
        fetchMyCourses();
      }
    }
  }, []);

  return (
    <div>
      {/* Title Heading */}
      <div>
        <Link to={UNPROTECTED_ROUTES?.courses}>
          <div className="flex items-center gap-1">
            {location.pathname === UNPROTECTED_ROUTES?.courses ? (
              <img src={COURSES_ICON_FILLED} alt="COURSES_ICON" className="w-[2rem]" />
            ) : (
              <img src={COURSES_ICON} alt="COURSES_ICON" className="w-[2rem]" />
            )}
            <p className="text-lg underline underline-offset-4 bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">Courses</p>
          </div>
        </Link>
      </div>
      {/* Subtitle Heading - 1 */}
      <div
        className="my-2 flex items-center justify-between cursor-pointer hover:text-[#1ECBDB]"
        onClick={() => setShowAllCourses(!showAllCourses)}
      >
        <div className="ml-[1rem] bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">All Courses</div>
        <div className="mr-1">
          {!showAllCourses ? <ChevronDown /> : <ChevronUp />}
        </div>
      </div>

      {/* Subtitle Heading - 1 elements*/}
      {showAllCourses &&
        allCoursesData?.courses?.length > 0 &&
        allCoursesData?.courses?.map((ele, i) => (
          <div key={i} className="flex ml-3">
            <Dot />
            <Link to={UNPROTECTED_ROUTES?.courseDetails + "/" + ele?.id}>
              <div className="hover:text-[#1ECBDB] cursor-pointer">
                {ele?.name}
              </div>
            </Link>
          </div>
        ))}

      {/* Subtitle Heading - 2 */}
      {userAuthenticated && (
        <div
          className="my-2 flex items-center justify-between cursor-pointer hover:text-[#1ECBDB]"
          onClick={() => setShowMyCourses(!showMyCourses)}
        >
          <div className="ml-[1rem] bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">My Courses</div>
          <div className="mr-1">
            {!showMyCourses ? <ChevronDown /> : <ChevronUp />}
          </div>
        </div>
      )}

      {/* Subtitle Heading - 2 elements*/}
      {userAuthenticated &&
        showMyCourses &&
        myCoursesData?.courses?.length > 0 &&
        myCoursesData?.courses?.map((ele, i) => (
          <div key={i} className="flex ml-3">
            <Dot />
            <Link to={UNPROTECTED_ROUTES?.courseDetails + "/" + ele?.id}>
              <div className="hover:text-[#1ECBDB] cursor-pointer">
                {ele?.name}
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default Courses;
