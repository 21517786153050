import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../../components/ui/dropdown-menu";
import { BellRing, ChevronsRight } from "lucide-react";
import useNotifications from "../../utils/use-Notifications";
import { timeAgo } from "../../utils/util-functions/timeAgo";
import { ScrollArea } from "../../components/ui/scroll-area";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { Link } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../routes/routes";

const NotificationsDropdown = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isOpen, setIsOpen] = useState(false); // Track dropdown open state
  const notificationsData = useNotifications(currentPage, setTotalPages);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div>
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger
          className="flex hover:text-[#1ECBDB]"
          onMouseEnter={handleOpen} // Open dropdown on mouse enter
        >
          <BellRing />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="mr-[5.5rem]"
          onMouseLeave={handleClose} // Close dropdown on mouse leave
        >
          <div className="flex justify-center">
            <div>
              <DropdownMenuItem>
                <span className="font-bold mr-1">All Notifications</span> 🔔
              </DropdownMenuItem>
            </div>
          </div>
          <DropdownMenuSeparator />
          <ScrollArea className="w-[16rem] h-[25rem] rounded-md pr-2">
            {notificationsData?.notifications?.length > 0 &&
              notificationsData?.notifications?.map((ele, i) => (
                <div
                  className="my-2 p-2 border-b border-gray-200 flex flex-col items-center"
                  key={i}
                >
                  <div className="text-xs text-gray-500 italic self-start mb-1">
                    {timeAgo(ele?.created_at)}
                  </div>
                  <div className="text-sm font-bold self-start">
                    {ele?.title}
                  </div>
                  {ele?.attachment !== "" && (
                    <img
                      src={ele?.attachment}
                      alt={ele?.title}
                      className="max-w-[15rem] rounded-lg my-1"
                      loading="lazy"
                    />
                  )}
                  <div className="text-xs text-left mt-1">
                    {stripHtml(ele?.message)}
                  </div>
                </div>
              ))}
          </ScrollArea>
          <Link to={PROTECTED_ROUTES?.notifications}>
            <DropdownMenuItem>
              <div className="font-bold mr-1 flex items-center">
                Show More <ChevronsRight />
              </div>
            </DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default NotificationsDropdown;