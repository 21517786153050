import React from "react";
import { UserRoundMinus, CircleUser } from "lucide-react";
import { useSelector } from "react-redux";
import PhoneIcon from "../../assets/Phone.png";
import EnvelopeIcon from "../../assets/Envelope.png";
import EditUserDetails from "./EditUserDetails";
import WalletIcon from "../../assets/Wallet.png";
import useWalletPoints from "../../utils/use-WalletPoints";
import UserIconAvatar from "../UserIcon/UserIcon";

const UserDetails = () => {
  const user = useSelector((store) => store.user);
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const walletHistory = useWalletPoints();

  return (
    <div className="bg-[#F8FCFD] flex flex-col justify-center items-center relative">
      <div className="p-2 rounded-full bg-white absolute -top-[4rem]">
        <UserIconAvatar userName = "" size = "w-32 h-32"/>
      </div>
      <div className="mt-[6rem] lg:flex lg:justify-around lg:items-center lg:gap-[5rem] grid md:grid-cols-3 grid-cols-2 gap-y-3 mb-10 lg:text-[1rem] text-sm"> 
        <div className = "flex flex-col justify-center items-center">
          <div className="flex items-center justify-center gap-2 text-[#82808D]">
            <div>
              <CircleUser/>
            </div>
            <div> Name:</div>
          </div>
          <div className="mt-2">{userDetails?.userName} 👋🏻</div>
        </div>
        <div className = "flex flex-col justify-center items-center">
          <div className=" flex items-center justify-center gap-2 text-[#82808D]">
            <div>
              <img alt="phone-icon" src={PhoneIcon} loading = "lazy"/>
            </div>
            <div> Phone:</div>
          </div>
          <div className="mt-2">+91-{userDetails?.userMobile}</div>
        </div>
        <div className = "flex flex-col justify-center items-center">
          <div className="flex items-center justify-center gap-2 text-[#82808D]">
            <div>
              <img alt="envelop-icon" src={EnvelopeIcon} loading = "lazy"/>
            </div>
            <div> Email:</div>
          </div>
          <div className="mt-2">{userDetails?.userEmail}</div>
        </div>
        <div className = "flex flex-col justify-center items-center">
          <div className="flex items-center justify-center gap-2 text-[#82808D]">
            <div>
              <img alt="wallet-icon" src={WalletIcon} loading = "lazy"/>
            </div>
            <div>My Wallet Points:</div>
          </div>
          <div className="mt-2 text-[#1ECBDB] font-semibold">
            ₹ {walletHistory?.wallet_balance}
          </div>
        </div>
        <div className = "lg:ml-0 md:ml-[2rem] ml-[10rem]">
          <EditUserDetails />
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
