import React from "react";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { Link } from "react-router-dom";
import StarRating from "../Star-Component/StarRating";

const TestCard = ({
  name,
  test_count,
  offer_price,
  offer_discount,
  price,
  rating,
  banner_url,
  id,
}) => {
  return (
    <Link to={UNPROTECTED_ROUTES?.testDetails + "/" + id}>
      <div
        className="flex lg:flex-row flex-col border border-gray-200 p-2 rounded-lg gap-3 bg-white shadow-lg my-3"
        style={{ fontFamily: "DM Sans" }}
      >
        {/* left - img */}
        <div className="flex flex-col items-center justify-center">
          <img
            alt={name}
            src={banner_url}
            className="lg:w-[10rem] lg:h-[6rem] w-[14rem] rounded-lg"
            loading="lazy"
          />
        </div>
        {/* right - desc */}
        <div className="flex flex-col justify-around lg:text-[1rem] text-sm lg:ml-0 ml-2">
          {/* Test Name */}
          <div className="font-bold text-xl">{name}</div>
          {/* rating */}
          <div>
            <StarRating rating={Math.floor(rating)} />
          </div>
          {/* test count */}
          <div className="mb-1">
            <span className="font-bold">{test_count} </span>
            Tests included
          </div>

          {/* Price and Button */}
          <div className="flex justify-between items-center gap-2 -ml-2">
            <div className="flex items-center gap-2">
              {/* Discount percentage */}
              <div>
                {offer_discount !== "0" && offer_discount !== null ? (
                  <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                    {offer_discount}% off
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="flex flex-col">
                {/* original price */}
                {offer_discount !== "0" && offer_discount !== null && (
                  <div className="line-through text-gray-500">₹{price}</div>
                )}
                {/* offer_price */}
                <div className="text-[#5A277C] font-bold">
                  ₹
                  {offer_discount !== "0" && offer_discount !== null
                    ? offer_price
                    : price}
                </div>
              </div>
            </div>
            <div>
              <Link to={UNPROTECTED_ROUTES?.testDetails + "/" + id}>
                <button
                  className="text-[#03B3C3] px-2 py-1 rounded-xl cursor-pointer"
                  style={{
                    backgroundColor: "rgba(30, 203, 219, 0.15)",
                    fontWeight: 700,
                  }}
                >
                  Explore More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TestCard;
