import React, { useState, useEffect } from "react";
import useCourseHeaders from "../../utils/use-CourseHeaders";
import { useParams } from "react-router-dom";
import DescriptionBanner from "../PageBanner/DescriptionBanner";
import TabHeadings from "./TabHeadings";
import CourseDescriptionTab from "./CourseDescriptionTab";
import LiveClassesTab from "./LiveClassesTab";
import ClassroomTab from "./ClassroomTab";
import DPPTab from "./DPPTab";
import TestSeriesTab from "./TestSeriesTab";
import AnnouncementTab from "./AnnouncementTab";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import DetailsResponsive from "../DetailResponsive/DetailsResponsive";
import { ITEM_COURSE } from "../../constants/text_constants";

const CourseDetails = () => {
  const { courseId } = useParams();
  const courseHeaders = useCourseHeaders(courseId);
  const [index, setIndex] = useState(0);
  const isPurchased = courseHeaders?.details[0]?.is_purchased;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (innerWidth <= 450) {
    return (
      <DetailsResponsive
        headerDetails={courseHeaders}
        item={ITEM_COURSE}
        isPurchased={isPurchased}
        itemId={courseId}
      />
    );
  }

  return (
    <div className="mb-[1rem] overflow-hidden">
      <DescriptionBanner
        courseHeaders={courseHeaders}
        isPurchased={isPurchased}
      />
      <TabHeadings index={index} setIndex={setIndex} />
       <div
        className="mx-[5rem] my-[2rem] w-[52rem]"
        style={{ fontFamily: "DM Sans" }}
      >
        {index === 0 && <CourseDescriptionTab courseId={courseId} />}
        {index === 1 && (
          <LiveClassesTab courseId={courseId} isPurchased={isPurchased} />
        )}
        {index === 2 && (
          <ClassroomTab courseId={courseId} isPurchased={isPurchased} />
        )}
        {index === 3 && (
          <TestSeriesTab
            testPackageId={courseHeaders?.details[0]?.test_package_id}
            isPurchased={isPurchased}
            showMetaDescription = {true}
          />
        )}
        {index === 4 && (
          <DPPTab
            testPackageId={courseHeaders?.details[0]?.test_package_id}
            isPurchased={isPurchased}
            showSideCTA={true}
            showMetaDescription = {true}
          />
        )}
        {index === 5 && <AnnouncementTab courseId={courseId} />}
      </div> 
    </div>
  );
};

export default CourseDetails;
