import React from "react";
import IN_FLAG from "../../assets/IN.png";

const NumberField = ({ setMobileNo, showEdit, setShowOtp, setOtp, showOtp=false }) => {
  function handleChange(e) {
    setMobileNo(e.target.value);
  }

  console.log("showotp", showOtp)

  function handleEditChange() {
    setOtp("");
    setShowOtp(false);
  }

  return (
    <div className="lg:my-5 my-4">
      <label className="block lg:text-left text-center text-l font-medium text-gray-700 mb-2">
        Enter Mobile No.
      </label>
      <div className="flex mt-1 items-center border rounded-lg border-gray-300 bg-white sm:w-[27.5rem] w-[18rem] lg:ml-0 ml-[2rem]">
        {/* Country Code with Flag */}
        <span className="inline-flex items-center pl-3 bg-white rounded-l-lg border-r border-gray-300">
          <img src={IN_FLAG} alt="India Flag" className="h-5 w-5 mr-2" loading = "lazy" />
          <span className="text-gray-500 mr-4">+91</span>
        </span>

        {/* Mobile Number Input */}
        <input
          type="text"
          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full p-2 rounded-r-lg sm:text-sm focus:outline-none"
          placeholder="12345 67890"
          pattern="[0-9]{10}"
          maxLength="10"
          minLength="10"
          required
          onChange={(e) => handleChange(e)}
          readOnly={showEdit && showOtp}
        />
        {showEdit && (
          <div
            className="text-[#03B3C3] font-bold border-l border-gray-300 pl-2 mr-2 cursor-pointer"
            onClick={handleEditChange}
          >
            EDIT
          </div>
        )}
      </div>
    </div>
  );
};

export default NumberField;
