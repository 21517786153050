import React, { useState, useEffect } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../../components/ui/dropdown-menu";
import { NAVBAR_USER_AUTHENTICATED_DROPDOWN } from "../../constants/text_constants";
import LOGOUT from "../../assets/SignOut.png";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import UserIconAvatar from "../UserIcon/UserIcon";
import { MessageCircleQuestion } from "lucide-react";
import { PROTECTED_ROUTES } from "../../routes/routes";

const UserDropdown = ({ userName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Track dropdown open state

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  function handleLogout() {
    userLogout(navigate, dispatch);
  }

  return (
    <div>
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger
          className="flex hover:text-[#1ECBDB]"
          onMouseEnter={handleOpen} // Open dropdown on mouse enter
        >
          <UserIconAvatar userName={userName} />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="mr-[2rem]"
          onMouseLeave={handleClose} // Close dropdown on mouse leave
        >
          <div className="flex justify-center">
            <div>
              <DropdownMenuItem>
                <span className="font-bold mr-1">{userName}</span> 👋🏻
              </DropdownMenuItem>
            </div>
          </div>
          <DropdownMenuSeparator />

          {NAVBAR_USER_AUTHENTICATED_DROPDOWN.map((ele, i) => (
            <DropdownMenuItem key={i}>
              <Link to={ele?.link}>
                <div className="flex justify-between items-center gap-1 hover:text-[#1ECBDB]">
                  <div className="w-[1.3rem] h-[1.3rem]">
                    <img alt={ele?.title} src={ele?.imgSrc} loading="lazy" />
                  </div>
                  <div>{ele?.title}</div>
                </div>
              </Link>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem>
            <div
              className="flex justify-between items-center gap-1 hover:text-[#1ECBDB] -my-1"
              onClick={() => navigate(PROTECTED_ROUTES?.support)}
            >
              <MessageCircleQuestion className="w-[1.3rem] text-gray-500" />

              <div>Support</div>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <div
              className="flex justify-between items-center gap-1 hover:text-[#1ECBDB]"
              onClick={handleLogout}
            >
              <div className="w-[1.3rem] h-[1.3rem]">
                <img alt="logout" src={LOGOUT} loading="lazy" />
              </div>
              <div>Logout</div>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default UserDropdown;
