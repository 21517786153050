import React, { useState, useEffect } from "react";
import { UNPROTECTED_ROUTES } from "../../../routes/routes";
import TEST_SERIES_ICON from "../../../assets/TEST_SERIES_ICON.webp";
import { Link, useLocation } from "react-router-dom";
import userLogout from "../../../utils/userLogout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dot, ChevronDown, ChevronUp } from "lucide-react";
import { setAllTestsCache, setMyTestsCache } from "../../../configs/cache";
import TEST_SERIES_ICON_FILLED from "../../../assets/TEST_SERIES_ICON_FILLED.webp";
import { toggleSideBar } from "../../../configs/sidebarSlice";

const Tests = ({
  showAllTests,
  setShowAllTests,
  showMyTests,
  setShowMyTests,
  setShowMyCourses,
  setShowAllCourses,
  setShowAllBooks,
  setShowMyBooks,
}) => {
  const [allTestsData, setAllTestsData] = useState([]);
  const [myTestsData, setMyTestsData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AllTestsCache = useSelector((store) => store.cache.allTestsCache);
  const myTestsCache = useSelector((store) => store.cache.myTestsCache);
  const userAuthenticated = sessionStorage.getItem("authenticated") === "1";
  const location = useLocation();

  const fetchAllTests = async (retries = 6) => {
    try {
      let response;
      const userAuthenticated = sessionStorage.getItem("authenticated") === "1";
      if (userAuthenticated) {
        const authToken = sessionStorage.getItem("token");
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_TESTS_DATA + "0",
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_TESTS_DATA + "0"
        );
      }

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchAllTests(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setAllTestsData(json?.data);
      dispatch(setAllTestsCache([json?.data, 0]));
    } catch (err) {
      console.error("Error fetchAllTests", err);
    }
  };

  useEffect(() => {
    if (AllTestsCache[0]) {
      setAllTestsData(AllTestsCache[0]);
    } else {
      fetchAllTests();
    }
  }, []);

  const fetchMyTests = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_MY_TESTS_DATA + "5" + `/0`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchMyTests(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setMyTestsData(json?.data);
      dispatch(setMyTestsCache([json?.data, 0]));
      sessionStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchMyTests", err);
    }
  };

  useEffect(() => {
    if (userAuthenticated) {
      if (myTestsCache[0]) {
        setMyTestsData(myTestsCache[0]);
      } else {
        fetchMyTests();
      }
    }
  }, []);

  return (
    <div>
      {/* Title Heading */}
      <div>
        {/* <Link to={UNPROTECTED_ROUTES?.testSeriesAll}> */}
        <Link to="#" onClick={() => { dispatch(toggleSideBar()); navigate(UNPROTECTED_ROUTES?.testSeriesAll) }} >
          {location.pathname === UNPROTECTED_ROUTES?.testSeries ||
          location.pathname === UNPROTECTED_ROUTES?.testSeriesAll || 
          location.pathname === UNPROTECTED_ROUTES?.testSeriesMy ? (
            <div className="flex items-center gap-1 bg-gradient-to-r from-cyan-100 to-purple-100 p-2 rounded-xl">
              <img
                src={TEST_SERIES_ICON_FILLED}
                alt="TEST_SERIES_ICON"
                className="w-[1.5rem] h-[1.7rem]"
              />
              <p className="text-lg underline underline-offset-4 bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] font-bold ml-1">
                Test Series
              </p>
            </div>
          ) : (
            <div className="flex items-center gap-1 p-2">
              <img
                src={TEST_SERIES_ICON}
                alt="TEST_SERIES_ICON"
                className="w-[1.5rem] h-[1.7rem]"
              />
              <p className="text-lg underline underline-offset-4 bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] ml-1">
                Test Series
              </p>
            </div>
          )}
        </Link>
      </div>

      {/* Subtitle Heading - 1 */}
      <div
        className="my-2 flex items-center justify-between cursor-pointer hover:text-[#1ECBDB]"
        onClick={() => {
          setShowAllTests(!showAllTests);
          setShowMyTests(false);
          setShowAllCourses(false);
          setShowMyCourses(false);
          setShowAllBooks(false);
          setShowMyBooks(false);
        }}
      >
        <div className="ml-[1rem] bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">
          All Tests
        </div>
        <div className="mr-1">
          {!showAllTests ? <ChevronDown /> : <ChevronUp />}
        </div>
      </div>

      {/* Subtitle Heading - 1 elements*/}
      {showAllTests &&
        allTestsData?.test_packages?.length > 0 &&
        allTestsData?.test_packages?.map((ele, i) => (
          <div key={i} className="flex ml-3">
            <Dot />
            <Link to="#" onClick={() => { dispatch(toggleSideBar()); navigate(UNPROTECTED_ROUTES?.testDetails + "/" + ele?.id + UNPROTECTED_ROUTES?.description) }}>
              <div className="hover:text-[#1ECBDB] cursor-pointer">
                {ele?.name}
              </div>
            </Link>
          </div>
        ))}

      {/* Subtitle Heading - 2 */}
      {userAuthenticated && (
        <div
          className="my-2 flex items-center justify-between cursor-pointer hover:text-[#1ECBDB]"
          onClick={() => {
            setShowMyTests(!showMyTests);
            setShowAllTests(false);
            setShowAllCourses(false);
            setShowMyCourses(false);
            setShowAllBooks(false);
            setShowMyBooks(false);
          }}
        >
          <div className="ml-[1rem] bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">
            My Tests
          </div>
          <div className="mr-1">
            {!showMyTests ? <ChevronDown /> : <ChevronUp />}
          </div>
        </div>
      )}

      {/* Subtitle Heading - 2 elements*/}
      {userAuthenticated &&
        showMyTests &&
        myTestsData?.test_packages?.length > 0 &&
        myTestsData?.test_packages?.map((ele, i) => (
          <div key={i} className="flex ml-3">
            <Dot />
            <Link to="#" onClick={() => { dispatch(toggleSideBar()); navigate(UNPROTECTED_ROUTES?.testDetails + "/" + ele?.id + UNPROTECTED_ROUTES?.description) }}>
              <div className="hover:text-[#1ECBDB] cursor-pointer">
                {ele?.name}
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default Tests;
