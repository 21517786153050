import React from "react";
import CourseDescription from "./course/CourseDescription";
import TestDescription from "./test/TestDescription";
import BookDescription from "./book/BookDescription";
import {
  ITEM_COURSE,
  ITEM_TEST_PACKAGE,
  ITEM_BOOK,
} from "../../constants/text_constants";

const DescriptionTab = ({ item, headerDetails, itemId }) => {
  return (
    <div>
      {/* Hero Section */}
      {item === ITEM_COURSE && (
        <CourseDescription headerDetails={headerDetails} courseId={itemId} />
      )}
      {item === ITEM_TEST_PACKAGE && (
        <TestDescription headerDetails={headerDetails} testId={itemId} />
      )}
      {item === ITEM_BOOK && (
        <BookDescription headerDetails={headerDetails} bookId={itemId} />
      )}
    </div>
  );
};

export default DescriptionTab;
