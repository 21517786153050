import React from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import PdfComponent from "./PdfComponent";
import { ScrollArea } from "../../components/ui/scroll-area";

const PDFDialogButton = ({
  BtnStyle,
  BtnText,
  BtnTextStyle,
  pdfUrl,
  DownloadFileName,
  showIcon,
  DownloadIcon,
  DownloadIconStyle,
  ShowBtnText = true,
}) => {
  async function handleDownload() {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${DownloadFileName}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }

  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          <Button variant="outline" className={BtnStyle}>
            {showIcon && (
              <div>
                <img
                  src={DownloadIcon}
                  alt="Download Icon"
                  className={DownloadIconStyle}
                  loading="lazy"
                />
              </div>
            )}
            {ShowBtnText && <div className={BtnTextStyle}>{BtnText}</div>}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[36rem] max-w-[22rem]">
          <DialogHeader>
            <DialogTitle>PDF Download</DialogTitle>
            <DialogDescription>
              <ScrollArea className="lg:w-[33rem] w-[20rem] lg:h-[36rem] h-[22rem] rounded-md">
                <PdfComponent pdf={pdfUrl} />
              </ScrollArea>
              <div className="flex justify-center mt-4">
                <Button
                  className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg"
                  onClick={handleDownload}
                >
                  Download PDF
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PDFDialogButton;

