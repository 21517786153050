export function formatDate(dateStr) {
  const date = new Date(dateStr);

  // Array to map month index to month name
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = ("0" + date.getDate()).slice(-2);
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Formatting the date
  return `${day} ${month} ${year}`;
}
