import React from "react";
import ReactPlayer from "react-player";
import OnlyPlayer from "../app_components/VideoPlayer/OnlyPlayer";

export const LANDING_PAGE_REVOLUTION_LEARNING_YT_VIDEO = ({
  width,
  height,
}) => {
  return (
    // <div className="w-full">
      <OnlyPlayer width={width}
        height={height}
        videoUrl={'https://www.youtube.com/watch?v=VLOY90oFwpA?rel=0&autoplay=0'}
        staticType={true}
      />
    // </div>
    // <iframe
    //   width={width}
    //   height={height}
    //   src="https://www.youtube.com/embed/VLOY90oFwpA?si=hl-25xi6VdEacG-e?cc_load_policy=0&rel=0"
    //   title="YouTube video player"
    //   frameborder="0"
    //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    //   referrerpolicy="strict-origin-when-cross-origin"
    //   allowfullscreen
    // ></iframe>
  );
};
