import React, { useState, useEffect } from "react";
import { UNPROTECTED_ROUTES } from "../../../routes/routes";
import BOOKS_ICON from "../../../assets/BOOKS_ICON.webp";
import { Link, useLocation } from "react-router-dom";
import userLogout from "../../../utils/userLogout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dot, ChevronDown, ChevronUp } from "lucide-react";
import { setAllBooksCache, setMyBooksCache } from "../../../configs/cache";
import BOOKS_ICON_FILLED from "../../../assets/BOOKS_ICON_FILLED.webp";

const Books = () => {
  const [allBookssData, setAllBooksData] = useState([]);
  const [myBooksData, setMyBooksData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AllBooksCache = useSelector((store) => store.cache.allBooksCache);
  const MyBooksCache = useSelector((store) => store.cache.myBooksCache);
  const [showAllBooks, setShowAllBooks] = useState(false);
  const [showMyBooks, setShowMyBooks] = useState(false);
  const userAuthenticated = localStorage.getItem("authenticated") === "1";
  const location = useLocation();

  const fetchAllBooks = async (retries = 6) => {
    try {
      let response;
      if (userAuthenticated) {
        const authToken = localStorage.getItem("token");
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_BOOKS_DATA + "0",
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_BOOKS_DATA + "0"
        );
      }
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchAllBooks(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setAllBooksData(json?.data);
      dispatch(setAllBooksCache([json?.data, 0]));
      if (json?.data?.token) {
        localStorage.setItem("token", json?.data?.token);
      }
    } catch (err) {
      console.error("Error fetchAllBooks", err);
    }
  };

  useEffect(() => {
    if (AllBooksCache[0]) {
      setAllBooksData(AllBooksCache[0]);
    } else {
      fetchAllBooks();
    }
  }, []);

  const fetchMyBooks = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_MY_BOOKS_DATA + "5" + `/0`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchMyBooks(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setMyBooksData(json?.data);
      dispatch(setMyBooksCache([json?.data, 0]));
      localStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchMyBooks", err);
    }
  };

  useEffect(() => {
    if (userAuthenticated) {
      if (MyBooksCache[0]) {
        setMyBooksData(MyBooksCache[0]);
      } else {
        fetchMyBooks();
      }
    }
  }, []);

  return (
    <div>
      {/* Title Heading */}
      <div>
        <Link to={UNPROTECTED_ROUTES?.books}>
          <div className="flex items-center gap-1">
            {location.pathname === UNPROTECTED_ROUTES?.books ? (
              <img src={BOOKS_ICON_FILLED} alt="BOOKS_ICON" className="w-[1.7rem]" />
            ) : (
              <img src={BOOKS_ICON} alt="BOOKS_ICON" className="w-[1.7rem]" />
            )}
            <p className="text-lg underline underline-offset-4 bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">
              Books
            </p>
          </div>
        </Link>
      </div>

      {/* Subtitle Heading - 1 */}
      <div
        className="my-2 flex items-center justify-between cursor-pointer hover:text-[#1ECBDB]"
        onClick={() => setShowAllBooks(!showAllBooks)}
      >
        <div className="ml-[1rem] bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">All Books</div>
        <div className="mr-1">
          {!showAllBooks ? <ChevronDown /> : <ChevronUp />}
        </div>
      </div>

      {/* Subtitle Heading - 1 elements*/}
      {showAllBooks &&
        allBookssData?.books?.length > 0 &&
        allBookssData?.books?.map((ele, i) => (
          <div key={i} className="flex ml-3">
            <Dot />
            <Link to={UNPROTECTED_ROUTES?.bookDetails + "/" + ele?.id}>
              <div className="hover:text-[#1ECBDB] cursor-pointer">
                {ele?.name}
              </div>
            </Link>
          </div>
        ))}

      {/* Subtitle Heading - 2 */}
      {userAuthenticated && (
        <div
          className="my-2 flex items-center justify-between cursor-pointer hover:text-[#1ECBDB]"
          onClick={() => setShowMyBooks(!showMyBooks)}
        >
          <div className="ml-[1rem] bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">My Books</div>
          <div className="mr-1">
            {!showMyBooks ? <ChevronDown /> : <ChevronUp />}
          </div>
        </div>
      )}

      {/* Subtitle Heading - 2 elements*/}
      {userAuthenticated &&
        showMyBooks &&
        myBooksData?.books?.length > 0 &&
        myBooksData?.books?.map((ele, i) => (
          <div key={i} className="flex ml-3">
            <Dot />
            <Link to={UNPROTECTED_ROUTES?.bookDetails + "/" + ele?.id}>
              <div className="hover:text-[#1ECBDB] cursor-pointer">
                {ele?.name}
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default Books;
