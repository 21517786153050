import React from "react";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import PageBanner from "../PageBanner/PageBanner";
import { MoveRight, SquareArrowOutUpRight, Grip } from "lucide-react";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const SupportPage = () => {
  return (
    <div>
      <PageBanner
        title={"Contact Support"}
        subtitle={""}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div
        className="lg:mx-[10rem] mx-[2rem] lg:mt-[5rem] mt-[2rem] -mb-[5rem]"
        style={{ fontFamily: "DM Sans" }}
      >
        <div className="font-semibold lg:text-3xl text-xl underline underline-offset-8 mb-5">
          <Grip className="inline w-[2rem]" /> Welcome to the Rankplus Support
          Center!
        </div>
        <div className="lg:text-lg text-sm flex flex-col gap-3 lg:ml-3 ml-1">
          <div>
            {" "}
            <MoveRight className="inline" /> At{" "}
            <span className="text-[#1ECBDB]">Rankplus</span>, we’re dedicated to
            providing you with the best learning experience possible. Whether
            you’re a student, teacher, or parent, we understand that questions
            and issues can arise, and we’re here to help every step of the way.
            Our support team is committed to resolving your queries swiftly and
            effectively.
          </div>
          <div>
            <MoveRight className="inline mr-2" />
            From account setup and accessing courses to technical
            troubleshooting, we’ve got you covered. If you need guidance on
            using our app features, downloading resources, or managing your
            study schedules, explore our{" "}
            <Link to={UNPROTECTED_ROUTES?.blogs}>
              <span className="text-[#1ECBDB] underline underline-offset-4">
                Frequently Asked Questions{" "}
                <SquareArrowOutUpRight className="inline" />
              </span>{" "}
            </Link>
            section for quick answers. You can also access detailed guides and
            video tutorials in our Help Articles section.
          </div>
          <div>
            <MoveRight className="inline mr-2" />
            Still have questions? Feel free to reach out to us directly via
            <Link
              to={
                "https://wa.me/8235067766?text=Hello%2C%20I%20would%20like%20more%20information"
              }
              target="_blank"
            >
              <span className="text-[#1ECBDB] underline underline-offset-4 mx-1">
                Whatsapp
                <SquareArrowOutUpRight className="inline" />
              </span>
            </Link>
            , and our dedicated team will respond promptly. At Rankplus, we
            believe that every learner deserves uninterrupted learning, and our
            team is here to ensure your journey is smooth and hassle-free.
            <div className="mt-5">
              Thank you for choosing Rankplus - Your trusted partner in learning
              excellence! 😇
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
