import React, { useState, useEffect } from "react";
import { ScrollArea } from "../../components/ui/scroll-area";
import CALENDER_ICON from "../../assets/Calendar.png";
import CLOCK_ICON from "../../assets/Clock.png";
import { formatDate } from "../../utils/util-functions/formatDate";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import LOCK_ICON from "../../assets/Lock.png";
import LOCK_OPEN_ICON from "../../assets/LockSimpleOpen.png";
import EYE_ICON from "../../assets/Eye.png";
import {
  LOCKED_TOAST_TEXT,
  VIDEO_TYPE_YOUTUBE,
} from "../../constants/text_constants";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../routes/routes";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";

const ClassroomTabLectures = ({ subjectId, defaultSubjectId, isPurchased }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [lecturesData, setLectureData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchSubjectLecture = async (retries = 6) => {
    try {
      const authToken = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await fetch(
        process.env
          .REACT_APP_RANK_PLUS_COURSE_DETAILS_LIVE_CLASS_SUBJECT_LECTURES +
          (subjectId !== null ? subjectId : defaultSubjectId) +
          "&limit=150&page=" +
          currentPage,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchSubjectLecture(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setLectureData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / 100));
    } catch (err) {
      console.error("Error fetchSubjectLecture : ", err);
    }
  };

  useEffect(() => {
    fetchSubjectLecture();
  }, [subjectId, defaultSubjectId, currentPage]);

  if (!lecturesData) {
    return <div>.</div>;
  }

  function handleClick(video_type, id, subject_id) {
    const isLocked = lecturesData?.is_locked;
    if (isPurchased === "0") {
      if (!isLocked) {
        if (video_type === VIDEO_TYPE_YOUTUBE) {
          navigate(PROTECTED_ROUTES?.classroom + `/${id}/${subject_id}`);
        }
      } else {
        toast(LOCKED_TOAST_TEXT);
      }
    } else {
      if (!isLocked) {
        if (video_type === VIDEO_TYPE_YOUTUBE) {
          navigate(PROTECTED_ROUTES?.classroom + `/${id}/${subject_id}`);
        }
      }
    }
  }

  return (
    <div>
      <ScrollArea className="lg:h-[600px] lg:w-[535px] w-full h-full rounded-md border px-4">
        {lecturesData?.lectures.map((ele, i) => (
          <div
            key={i}
            className="my-4 bg-[#F8FCFD] rounded-xl border border-[#DFE3EC] p-2 flex lg:flex-row flex-col lg:gap-2 gap-4 justify-between lg:items-start items-center shadow-md cursor-pointer"
            onClick={() =>
              handleClick(ele?.video_type, ele?.id, ele?.subject_id)
            }
          >
            <div>
              <img
                src={ele?.thumbnail}
                alt={ele?.title}
                className="lg:w-[10rem] lg:h-[6rem] w-full rounded-lg shadow-2xl"
                loading="lazy"
              />
            </div>
            <div className = "flex">
              <div className="flex flex-col gap-1">
                <div className="flex gap-2 text-sm">
                  <div className="flex items-center gap-1">
                    <img
                      src={CALENDER_ICON}
                      alt="calender-icon"
                      loading="lazy"
                    />
                    <div>{formatDate(ele?.created_at)}</div>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={CLOCK_ICON} alt="clock-icon" loading="lazy" />
                    <div>{ele?.video_length}</div>
                  </div>
                </div>
                <div className="w-[14rem]">
                  <div className="font-bold">{ele?.title}</div>
                  <div className="text-sm">
                    {stripText(stripHtml(ele?.description), 60)}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center gap-4">
                {lecturesData?.is_locked ? (
                  <div className="bg-[#03B3C3] p-2 rounded-full">
                    <img src={LOCK_ICON} alt="lock-icon" loading="lazy" />
                  </div>
                ) : (
                  <div className="bg-[#03B3C3] p-2 rounded-full">
                    <img src={LOCK_OPEN_ICON} alt="lock-icon" loading="lazy" />
                  </div>
                )}
                <div
                  className="bg-[#5A277C] p-2 rounded-full"
                  onClick={() =>
                    handleClick(ele?.video_type, ele?.id, ele?.subject_id)
                  }
                >
                  <img src={EYE_ICON} alt="eye-icon" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </ScrollArea>
    </div>
  );
};

export default ClassroomTabLectures;
