import {
    modExp,
    CryptoJSAesJson,
  } from "./encryptionUtils";
  import CryptoJS from "crypto-js";
  import { toast } from "sonner";

function DecodePdfUrl(data, encodedUrl, encryptionData) {
    const serverPublicKey = data?.publicKey;
    const clientSharedSecret = modExp(
      serverPublicKey,
      encryptionData?.clientPrivateKey,
      encryptionData?.prime
    );
    try {
      const decodedUrl = CryptoJS.AES.decrypt(
        encodedUrl,
        clientSharedSecret.toString(),
        {
          format: CryptoJSAesJson,
        }
      )
        .toString(CryptoJS.enc.Utf8)
        .replace(/['"]/g, "");
      return decodedUrl;
    } catch (err) {
      toast("Something went wrong 😖 Please Refresh the Page again");
    }
  }

  export default DecodePdfUrl;